import { cloneDeep, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  ProductFilters,
  defaultFilters,
  getFinancialCalculations,
  getProjectCosts,
  profitByYearPure,
  useAddBatteryInverterChange,
} from "src/hooks";

import { DEFAULT_HEATPUMP_KWH, ROOF_PITCH_FACTORS } from "src/data";
import { convertProjectDBToState } from "src/db-converters";
import { ProductWithPrice } from "src/db-types";
import { ProjectResponseDB } from "src/db-types/project";
import { filterProducts, getAverageCapacity, getAverageMaxCapacity } from "src/helpers";
import { getInverterBuyInfo, getProductBuyInfo } from "src/helpers/product/getProductBuyInfo";
import { PredictionModel } from "src/prediction-model/PredictionModel";
import {
  defaultModelInput,
  defaultModelState,
} from "src/prediction-model/utility/default-model-values";
import { getNumber } from "src/utils";

import { FilteredProductsMap } from "src/redux/products";
import { ProjectState } from "src/redux/project";
import { defaultHeatpumpConfiguration } from "src/redux/project/default-values";
import { defaultProjectState } from "src/redux/project/initialState";
import * as ProjectHandlers from "src/redux/project/reducers/handlers";
import { ProductSelectors } from "src/redux/selectors";

interface IUseProjectReturn {
  project: ProjectState;
  filteredProducts: FilteredProductsMap;
  after20Years: number;
  solarPanelDetails: {
    systemCapacity: number;
    panelCapacity: number;
  };
  actions: {
    addBattery: () => void;
    addHeatpump: () => void;
    addWallbox: () => void;
    deleteBattery: () => void;
    deleteHeatpump: () => void;
    deleteWallbox: () => void;
    updateSolarPanelPref: (filters: Partial<ProductFilters>) => void;
    updateBatteryPref: (filters: Partial<ProductFilters>) => void;
    updateWallboxPref: (filters: Partial<ProductFilters>) => void;
    updateHeatpumpPref: (filters: Partial<ProductFilters>) => void;
    updateInverterPref: (filters: Partial<ProductFilters>) => void;
  };
}
export const useProject = (projectRawData: ProjectResponseDB): IUseProjectReturn => {
  const solarPanelProducts = useSelector(ProductSelectors.getSolarPanels);
  const inverterProducts = useSelector(ProductSelectors.getInverters);
  const wallboxProducts = useSelector(ProductSelectors.getWallboxes);
  const heatpumpProducts = useSelector(ProductSelectors.getHeatpumps);
  const batteryProducts = useSelector(ProductSelectors.getBatteries);

  const [solarPanelDetails, setSolarPanelDetails] = useState({
    systemCapacity: 0,
    panelCapacity: 0,
  });
  const [filteredProducts, setFilteredProducts] = useState<FilteredProductsMap>({
    batteries: [],
    wallboxes: [],
    heatpumps: [],
    inverters: [],
    solarPanels: [],
    additionalSoftware: [],
    additionalHardware: []
  });
  const [after20Years, setAfter20Years] = useState(0);
  const [project, setProject] = useState({
    ...defaultProjectState,
    ...convertProjectDBToState(projectRawData),
  });
  const { showContactSolarhubToast, showHybridChangeToast } = useAddBatteryInverterChange();
  const predModel = useMemo(() => new PredictionModel({ ...defaultModelInput }), []);
  const previousInputs = useRef({ ...defaultModelInput });
  const timeout = useRef<any>(null);
  const [modelOutput, setModelOutput] = useState(cloneDeep(defaultModelState));

  useEffect(() => {
    const quantity = project.components.solarPanel.quantity;
    const capacity = solarPanelDetails.panelCapacity;

    const roofPitchFactor =
      ROOF_PITCH_FACTORS[Math.floor(project.energyProductionParams.roofPitch)].factor;

    const rawProduction = quantity * capacity * roofPitchFactor;
    const reductionByShade =
      (rawProduction * project.energyProductionParams.productionReducedDueToShade) / 100;

    const realProduction = rawProduction - reductionByShade;

    // if (realProduction == 0) production = proj.productionByBatterySimulation;
    // else production = realProduction + getNumber(proj.increseCapacityFromAdminPanle);
    const production =
      realProduction + project.energyProductionParams.increasedCapacityFromAdminPanel;

    setProject((p) =>
      ProjectHandlers.updateEnergy(p, {
        type: "",
        payload: {
          solarPanelProduction: production,
        },
      }),
    );
  }, [
    project.components.solarPanel.quantity,
    project.energyProductionParams.increasedCapacityFromAdminPanel,
    project.energyProductionParams.productionReducedDueToShade,
    project.energyProductionParams.roofPitch,
    solarPanelDetails.panelCapacity,
  ]);

  useEffect(() => {
    const evConsumption = project.components.cars.reduce(
      (acc, car) => acc + car.configuration.evYearlyConsumption,
      0,
    );
    const distance = (evConsumption * 10000) / 1689.557645922011;

    const modelInput = {
      noOfPeople: project.household.people,
      householdConsumption: project.household.consumption,
      pv: project.energy.solarPanelProduction / 999.6088673569609,
      battery: project.components.battery.quantity
        ? getAverageMaxCapacity(filteredProducts.batteries) / 1000
        : 0,
      evDistance: distance,
      haveHeatingRod: project.components.waterHeating,
      haveHeatpump: !!project.components.heatpump.quantity,
      heatpumpConsumption: project.components.heatpump.quantity
        ? project.components.heatpump.configuration.consumptionHeatpump
        : 0,
      heatpumpRequirement: project.components.heatpump.quantity
        ? project.components.heatpump.configuration.electricityCostHeatpump
        : 0,
    };

    if (isEqual(previousInputs.current, modelInput)) {
      return;
    }

    if (timeout.current) clearTimeout(timeout.current);
    predModel.setInputsAndPredict(modelInput);
    previousInputs.current = modelInput;

    setModelOutput(cloneDeep(predModel.state));
  }, [
    filteredProducts.batteries,
    predModel,
    project.components.battery.quantity,
    project.components.cars,
    project.components.heatpump.configuration.consumptionHeatpump,
    project.components.heatpump.configuration.electricityCostHeatpump,
    project.components.heatpump.quantity,
    project.components.waterHeating,
    project.energy.solarPanelProduction,
    project.household.consumption,
    project.household.people,
  ]);

  useEffect(() => {
    const data = profitByYearPure({
      appliedProjectCost: project.financialDetails.costs.appliedProjectCost,
      carBuyingCost: project.financialDetails.costs.componentCosts.carBuyingCost,
      electricityCostSavings: project.financialDetails.costs.savings.electricityCostSavings,
      estimatedYearlyPriceIncrease: project.financialDetails.estimatedYearlyPriceIncrease,
      evGridCost: project.financialDetails.costs.gridCosts.evGridCost,
      expectedYears: 20,
      feedInSavings: project.financialDetails.costs.savings.feedInSavings,
      gasSavings: project.financialDetails.costs.savings.gasSavings,
      heatpumpGridCost: project.financialDetails.costs.gridCosts.heatpumpGridCost,
      futureOilGasCostIncrease: project.financialDetails.costs.savings.futureOilGasCostIncrease,
      mobilitySavings: project.financialDetails.costs.savings.mobilitySavings,
    });
    setAfter20Years(data.profit);
  }, [
    project.financialDetails.costs.appliedProjectCost,
    project.financialDetails.costs.componentCosts.carBuyingCost,
    project.financialDetails.costs.gridCosts.evGridCost,
    project.financialDetails.costs.gridCosts.heatpumpGridCost,
    project.financialDetails.costs.savings.electricityCostSavings,
    project.financialDetails.costs.savings.feedInSavings,
    project.financialDetails.costs.savings.futureOilGasCostIncrease,
    project.financialDetails.costs.savings.gasSavings,
    project.financialDetails.costs.savings.mobilitySavings,
    project.financialDetails.estimatedYearlyPriceIncrease,
  ]);

  /** financiial calculations */
  useEffect(() => {
    const details = getFinancialCalculations({
      cars: project.components.cars,
      feedInTariff: project.financialDetails.feedInTariff,
      model: modelOutput,
      oilGasCost: project.components.heatpump.configuration.oilGasCost,
      pricePerKwh: project.financialDetails.pricePerKwh,
    });
    setProject((p) =>
      ProjectHandlers.updateFinancialDetails(p, {
        payload: details,
        type: "",
      }),
    );
  }, [
    modelOutput,
    project.components.cars,
    project.components.heatpump.configuration.oilGasCost,
    project.financialDetails.feedInTariff,
    project.financialDetails.pricePerKwh,
  ]);

  /**
   * System capacity and panel capacity
   */
  useEffect(() => {
    const quantity = project.components.solarPanel.quantity;
    const capacity = getAverageCapacity(filteredProducts.solarPanels);

    const systemCapacity = (capacity * quantity) / 1000;

    setSolarPanelDetails({
      systemCapacity,
      panelCapacity: capacity,
    });
  }, [filteredProducts.solarPanels, project.components.solarPanel.quantity]);

  /**
   * Battery Preferences
   */
  useEffect(() => {
    if (!project.components.battery.quantity) {
      setFilteredProducts((p) => ({ ...p, batteries: [] }));
      return;
    }

    const projectType = project.type;

    const filtered = filterProducts({
      filters: project.components.battery.preferences,
      products: batteryProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType }),
      };
    });

    setFilteredProducts((p) => ({ ...p, batteries: pricedProducts }));
  }, [
    batteryProducts,
    project.components.battery.preferences,
    project.components.battery.quantity,
    project.type,
  ]);

  /**
   * Heatpump preferences
   */
  useEffect(() => {
    if (!project.components.heatpump.quantity || project.components.heatpump.isExcludeCost) {
      setFilteredProducts((p) => ({ ...p, heatpumps: [] }));
      return;
    }

    const filtered = filterProducts({
      filters: project.components.heatpump.preferences,
      products: heatpumpProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType: project.type }),
      };
    });

    setFilteredProducts((p) => ({ ...p, heatpumps: pricedProducts }));
  }, [
    heatpumpProducts,
    project.components.heatpump.preferences,
    project.components.heatpump.quantity,
    project.type,
  ]);

  /**
   * Solarpanel preferences
   */
  useEffect(() => {
    if (!project.components.solarPanel.quantity) {
      setFilteredProducts((p) => ({ ...p, solarPanels: [] }));
      return;
    }

    const filtered = filterProducts({
      filters: project.components.solarPanel.preferences,
      products: solarPanelProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({
          product: prod,
          projectType: project.type,
          quantity: project.components.solarPanel.quantity,
        }),
      };
    });

    setFilteredProducts((p) => ({ ...p, solarPanels: pricedProducts }));
  }, [
    project.components.solarPanel.preferences,
    project.components.solarPanel.quantity,
    project.type,
    solarPanelProducts,
  ]);

  /**
   * Inverter preferences
   */
  useEffect(() => {
    if (!project.components.inverter.quantity) {
      setFilteredProducts((p) => ({ ...p, inverters: [] }));
      return;
    }

    const filtered = filterProducts({
      filters: project.components.inverter.preferences,
      products: inverterProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getInverterBuyInfo({
          product: prod,
          projectType: project.type,
          solarPanelCount: project.components.solarPanel.quantity,
        }),
      };
    });

    setFilteredProducts((p) => ({ ...p, inverters: pricedProducts }));
  }, [
    inverterProducts,
    project.components.inverter.preferences,
    project.components.inverter.quantity,
    project.components.solarPanel.quantity,
    project.type,
  ]);

  /**
   * Wallbox preferences
   */
  useEffect(() => {
    if (!project.components.wallbox.quantity || project.components.wallbox.isExcludeCost) {
      setFilteredProducts((p) => ({ ...p, wallboxes: [] }));
      return;
    }

    const filtered = filterProducts({
      filters: project.components.wallbox.preferences,
      products: wallboxProducts,
    });

    const pricedProducts: ProductWithPrice[] = filtered.map((prod) => {
      return {
        ...prod,
        buyInfo: getProductBuyInfo({ product: prod, projectType: project.type }),
      };
    });

    setFilteredProducts((p) => ({ ...p, wallboxes: pricedProducts }));
  }, [
    project.components.wallbox.preferences,
    project.components.wallbox.quantity,
    project.type,
    wallboxProducts,
  ]);

  /** Get project costs */
  useEffect(() => {
    const costs = getProjectCosts({
      cars: project.components.cars,
      filteredProducts,
      increaseRateByBank: project.financialDetails.increaseRateByBank,
      isFinancing: project.financialDetails.isFinancing,
      projectType: project.type,
      solarPanel: project.components.solarPanel,
      heatpump: project.components.heatpump,
      inverter: project.components.inverter,
      wallbox: project.components.wallbox,
      battery: project.components.battery,
      solarPanelProduction: project.energy.solarPanelProduction,
      webConstants: project.webConstants,
    });

    setProject((p) => ProjectHandlers.updateFinancialDetails(p, { type: "", payload: costs }));
  }, [
    filteredProducts,
    project.components.cars,
    project.components.solarPanel,
    project.energy.solarPanelProduction,
    project.financialDetails.increaseRateByBank,
    project.financialDetails.isFinancing,
    project.type,
    project.webConstants,
  ]);

  /** synchronize inverter */
  useEffect(() => {
    const capacity = project.components.solarPanel.quantity * solarPanelDetails.panelCapacity;
    setProject((p) =>
      ProjectHandlers.updateInverter(p, {
        payload: { preferences: { applicableMaxCapacity: capacity } },
        type: "",
      }),
    );
  }, [project.components.solarPanel.quantity, solarPanelDetails.panelCapacity]);

  const noHybridInverterAvailable = useCallback(
    (capacity: number) => {
      if (inverterProducts.length === 0) return false;

      return (
        filterProducts({
          filters: {
            ...cloneDeep(defaultFilters),
            applicableMaxCapacity: capacity,
            hybrid: true,
          },
          products: inverterProducts,
        }).length === 0
      );
    },
    [inverterProducts],
  );

  const convertInverterToHybrid = useCallback(() => {
    const inverterPref = project.components.inverter.preferences;

    const isInverterHybrid = inverterPref.hybrid;
    if (isInverterHybrid) {
      return true;
    }

    const requiredCapacity = getNumber(inverterPref.applicableMaxCapacity);
    if (noHybridInverterAvailable(requiredCapacity)) {
      showContactSolarhubToast();
      return false;
    }

    const defaultFiltersInverter: Partial<ProductFilters> = cloneDeep(defaultFilters);
    delete defaultFiltersInverter.applicableMaxCapacity;

    setProject((p) =>
      ProjectHandlers.updateInverter(p, {
        payload: {
          preferences: {
            ...cloneDeep(defaultFiltersInverter),
            hybrid: true,
          },
        },
        type: "",
      }),
    );
    showHybridChangeToast();

    return true;
  }, [
    noHybridInverterAvailable,
    project.components.inverter.preferences,
    showContactSolarhubToast,
    showHybridChangeToast,
  ]);

  const addBattery = useCallback(() => {
    if (convertInverterToHybrid()) {
      setProject((p) =>
        ProjectHandlers.updateBattery(p, {
          payload: {
            extendedWarranty: false,
            item: null,
            quantity: 1,
            isExcludeCost: false,
            preferences: {
              ...defaultFilters,
              applicableMaxCapacity: 3000,
            },
          },
          type: "",
        }),
      );
    }
  }, [convertInverterToHybrid]);

  const updateSolarPanelPref = useCallback((filters: Partial<ProductFilters>) => {
    setProject((p) =>
      ProjectHandlers.updateSolarPanel(p, {
        payload: {
          preferences: {
            ...filters,
          },
        },
        type: "",
      }),
    );
  }, []);

  const updateBatteryPref = useCallback((filters: Partial<ProductFilters>) => {
    setProject((p) =>
      ProjectHandlers.updateBattery(p, {
        payload: {
          preferences: {
            ...filters,
          },
        },
        type: "",
      }),
    );
  }, []);

  const updateHeatpumpPref = useCallback((filters: Partial<ProductFilters>) => {
    setProject((p) =>
      ProjectHandlers.updateHeatpump(p, {
        payload: {
          preferences: {
            ...filters,
          },
        },
        type: "",
      }),
    );
  }, []);

  const updateInverterPref = useCallback((filters: Partial<ProductFilters>) => {
    setProject((p) =>
      ProjectHandlers.updateInverter(p, {
        payload: {
          preferences: {
            ...filters,
          },
        },
        type: "",
      }),
    );
  }, []);

  const updateWallboxPref = useCallback((filters: Partial<ProductFilters>) => {
    setProject((p) =>
      ProjectHandlers.updateWallbox(p, {
        payload: {
          preferences: {
            ...filters,
          },
        },
        type: "",
      }),
    );
  }, []);

  const addHeatpump = useCallback(() => {
    setProject((p) =>
      ProjectHandlers.addHeatpump(p, {
        type: "",
        payload: {
          item: null,
          extendedWarranty: false,
          isExcludeCost: false,
          quantity: 1,
          preferences: {
            ...cloneDeep(defaultFilters),
            applicableMaxCapacity: DEFAULT_HEATPUMP_KWH,
          },
          configuration: {
            ...cloneDeep(defaultHeatpumpConfiguration),
          },
        },
      }),
    );
  }, []);

  const addWallbox = useCallback(() => {
    setProject((p) =>
      ProjectHandlers.addWallbox(p, {
        type: "",
        payload: {
          item: null,
          extendedWarranty: false,
          isExcludeCost: false,
          quantity: 1,
          preferences: {
            ...cloneDeep(defaultFilters),
          },
        },
      }),
    );
  }, []);

  const deleteBattery = useCallback(() => {
    setProject((p) => ProjectHandlers.deleteBattery(p, { type: "", payload: null }));
  }, []);

  const deleteHeatpump = useCallback(() => {
    setProject((p) => ProjectHandlers.deleteHeatpump(p, { type: "", payload: null }));
  }, []);

  const deleteWallbox = useCallback(() => {
    setProject((p) => ProjectHandlers.deleteWallbox(p, { type: "", payload: null }));
  }, []);

  return {
    project,
    after20Years,
    filteredProducts,
    solarPanelDetails,
    actions: {
      addBattery,
      addHeatpump,
      addWallbox,
      deleteBattery,
      deleteHeatpump,
      deleteWallbox,
      updateSolarPanelPref,
      updateBatteryPref,
      updateWallboxPref,
      updateHeatpumpPref,
      updateInverterPref,
    },
  };
};
