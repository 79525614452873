import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  energyHeading: {
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: 0.15,
    color: "#2d4764",
  },
  secound_box: {
    padding: 20,
    borderRadius: 10,
    background: "#ffffff",
    boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.1)",
    margin: "auto",
    marginBottom: 20,
    width: "95%",
  },
  home_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  img: {
    paddingLeft: 11,
    width: 350,
    paddingTop: 35,
  },
  info_text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  info_text_point_upper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  info_text_point: {
    display: "flex",
    paddingBottom: 10,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  info_text_sub: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "absolute",
    justifyContent: "flex-start",
    right: 68,
    width: 200,
    top: 20,
  },
  upper: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 18,
    color: "#4d7e6d",
    borderBottom: 1,
    borderColor: "#4d7e6d",
  },
  lower: {
    fontFamily: "Nunito",
    fontSize: 12,
    color: "rgba(45, 71, 100, 1)",
  },
  make_in_same_line: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  upper_yellow: {
    fontFamily: "Nunito",
    fontWeight: 800,
    fontSize: 18,
    color: "#ffd75d",
  },
  upper_dark_yellow: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 16,
    color: "#f2994a",
    display: "flex",
    flexDirection: "row",
  },
  upper_green_yellow: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 18,
    color: "#287e97",
    display: "flex",
    flexDirection: "row",
  },
  upper_red_dark: {
    color: "#b5268d",
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 18,
    display: "flex",
    flexDirection: "row",
  },
  flex_row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  info_text2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  info_text22: {
    marginTop: 21,
  },
  info_text3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    marginTop: 18,
    marginBottom: 11,
  },
  info_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "absolute",
    right: 45,
    top: 95,
  },
  relative_wrapper: {
    position: "relative",
  },
  upper_red: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 14,
    color: "#df6569",
  },
  upper_green: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 14,
    color: "#4d7e6d",
  },
});

export default styles;
