import { keyframes } from "styled-components";
import styled from "styled-components/macro";

export const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2.5rem;
  position: relative;
  height: 74px;

  @media (max-width: 500px) {
    column-gap: 1.5rem;
    height: auto;
    flex-direction: column;
  }
  padding: 0.5rem;
  border-bottom: 1px solid #dfdfdf;
  .first {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .main-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .partner-installer {
    gap: 10px;
    color: #2d4764;
    @media (max-width: 500px) {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1.5px solid #dfdfdf;
      padding-top: 11px;
    }
    p {
      color: #2d4764;
      font-weight: 700;
      cursor: pointer;
    }
    display: none;
  }

  .hideOnMobile {
    @media (max-width: 500px) {
      display: none;
    }
    display: block;
  }

  @media (max-width: 700px) {
    .first {
      width: 125px;
      left: 13px;
    }
  }
  .secound {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    margin: auto;
  }
  .offerflow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;

    p {
      font-family: Nunito;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.1px;
      text-align: left;
      color: #2d4764;
    }
  }
  .third {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 2rem;
  }

  .button {
    border-radius: 5px;
    font-weight: bold;
    padding: 8px 20px;
    border: 1px solid #000000;
    background-color: transparent;
    color: #2d4764;

    .avtart {
      background-color: #00a2ae;
      vertical-align: middle;
    }
  }
  .hideOnMobile {
    @media (max-width: 799px) {
      display: none;
    }
  }

  .hideOnDesktop {
    @media (min-width: 800px) {
      display: none;
    }
  }
  .newwork {
    @media (max-width: 600px) {
      gap: 14px;
    }
  }

  .back-to-project-button {
    @media (max-width: 1100px) {
      .text {
        display: none;
      }
    }
  }
`;

export const EmptySpace = styled.div`
  flex-grow: 1;
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

export const WebinarLink = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: "#2D4764";
  cursor: "pointer";
  :hover {
    color: #2d4764;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const MobileBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(64 64 64 / 52%);
  z-index: 1000;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const rightToLeft = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

export const MobileMenu = styled.div`
  position: fixed;
  right: 0;
  top: 0;

  overflow-y: auto;

  width: min(75%, 300px);
  height: 100%;

  background: #ffffff;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 36px 0px 0px 0px;

  z-index: 1001;

  animation: ${rightToLeft} 150ms linear;
`;

export const Divider = styled.div`
  border: 1px solid #dfdfdf;
  margin-block: 1rem;
  width: 100%;
`;

export const Links = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: fit-content;
  padding-inline: 10px;
`;

export const CloseButtonPos = styled.div`
  margin: 5px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 1rem;
  padding-top: 0.5rem;
`;

export const LogoTextWrap = styled.div`
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
`;

export const SecondaryButton = styled.div`
  padding: 0.8rem;
  margin-block: 0.5rem;
  margin-inline: 1rem;
  text-transform: uppercase;
  border-radius: 20px;
  border: 1px solid var(--blue);
  color: var(--blue);
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
`;

export const PrimaryButton = styled.div`
  padding: 0.8rem;
  margin-block: 0.5rem;
  margin-inline: 1rem;
  text-transform: uppercase;
  border-radius: 20px;
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--blue);
  text-align: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
`;
