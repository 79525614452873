import React, { useCallback, useRef } from "react";
import html2canvas from "html2canvas";
import {
    ElectricitySupplyChart,
} from "src/pages/ApplicationPage/components/ApplicationStep1/components/ImageConfiguration/ConsumptionCharts/components";

import "./chart.css"

const Chart = ({ setScreenshot }) => {
  const chartRef = useRef(null);
  const captureScreenshot = useCallback(async () => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const canvas = await html2canvas(chart, { useCORS: true });

    const image = canvas.toDataURL("image/png");

    setScreenshot(image);
  }, [setScreenshot, chartRef.current]);

  return (
    <div style={{ display: "flex", width: 400, backgroundColor: "white", borderRadius: 8.82658}} ref={chartRef}>
        <ElectricitySupplyChart captureScreenshot={captureScreenshot} />
    </div>
  );
};

export default Chart;
