import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Text, View } from "@react-pdf/renderer";

import PageHeading from "../../components/pageHeading/pageHeading";
import commanStyles from "../../comanStyles/commanStyles";
import styles from "./ProductDetailsStyles";
import Product from "../../components/ProductList/Product";
import Footer from "../../components/Footer/Footer";

import { getCheapestProduct } from "src/helpers";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { useIsComponentAdded } from "src/hooks";

const ProductDetails = () => {
  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const { isSolarPanelAdded, isInvertedAdded, isBatteryAdded } =
    useIsComponentAdded();
  const cheapestSolarPanel = useMemo(() => {
    return getCheapestProduct(filtered.solarPanels);
  }, [filtered]);

  const cheapestinverters = useMemo(() => {
    return getCheapestProduct(filtered.inverters);
  }, [filtered]);

  const cheapestbatteries = useMemo(() => {
    return getCheapestProduct(filtered.batteries);
  }, [filtered]);

  const SolarPanel = useSelector(ProjectSelectors.getSolarPanel);
  const batteries = useSelector(ProjectSelectors.getBattery);
  const inverters = useSelector(ProjectSelectors.getInverter);
  const unfilteredProductsReduxSolarPanel = useSelector(ProductSelectors.getSolarPanels);
  const unfilteredProductsReduxInverters = useSelector(ProductSelectors.getInverters);
  const unfilteredProductsReduxBatteries = useSelector(ProductSelectors.getBatteries);

  console.log({SolarPanel, inverters})

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <PageHeading heading="Dies ist eine erste Konfigurationsoption" titleWidth={330} />
      <View style={styles.mainContainer}>
        <Text style={commanStyles.static_text_small}>
          Die tatsächlichen Komponenten werden nach Verfügbarkeit vom Installateur ausgewählt
        </Text>
        <View
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          {isSolarPanelAdded && cheapestSolarPanel && (
            <Product
              project={cheapestSolarPanel}
              name={"PV Modul"}
              isB2B={false}
              backColor="#FFD75D"
              isEnglish={true}
              preferences={SolarPanel.preferences}
              flag={true}
              unfilteredProductsRedux={unfilteredProductsReduxSolarPanel}
            />
          )}
          {isInvertedAdded && cheapestinverters && (
            <Product
              project={cheapestinverters}
              name={"Wechselrichter"}
              isB2B={false}
              left={31}
              backColor="#4D7E6D"
              isEnglish={true}
              flag={true}
              preferences={inverters.preferences}
              unfilteredProductsRedux={unfilteredProductsReduxInverters}
            />
          )}
          {isBatteryAdded && cheapestbatteries && (
            <Product
              project={cheapestbatteries}
              name={"Batterie"}
              isB2B={false}
              backColor="#287E97"
              isEnglish={true}
              flag={true}
              preferences={batteries.preferences}
              unfilteredProductsRedux={unfilteredProductsReduxBatteries}
            />
          )}
        </View>
      </View>
      <Footer pageNumber={5} />
    </View>
  );
};

export default ProductDetails;
