import { StyleSheet } from "@react-pdf/renderer";

export const projectStyles = StyleSheet.create({
  titleContainer: {
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  reportTitle: {
    position: "absolute",
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    backgroundColor: "#FCE8A9",
    color: "#666666",
    // borderRadius: "0px 0px 0px 10px",
    padding: "6",
    right: "0px",
  },
  reportTitle1: {
    position: "absolute",
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18",
    color: "#2D4764",
    right: "213",
    marginTop: 83,
  },
  logo: {
    position: "absolute",
    width: 146,
    height: 49,
    left: "5",
    top: "5",
    marginTop: 5,
  },
  mainContainer1: {
    width: 299,
    height: 604,
    borderRadius: 22,
    border: "1 solid grey",
    marginTop: 135,
    marginRight: 14,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flexstart",
    padding: 10,
    paddingRight: 30,
  },
  mainContainer2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "flexstart",
  },
  reportTitle2: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14",
    color: "#2D4764",
  },
  rectangle: {
    width: 150,
    height: 4,
    backgroundColor: "#FFD75D",
    borderRadius: "4",
    marginTop: 6,
  },
  reportTitle3: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
    marginRight: 36,
    marginBottom: 8,
  },
  reportTitleLine: {
    width: 95,
    height: 1,
    backgroundColor: "#E2E2E2",
    marginTop: 7,
  },
  reportTitleLine1: {
    width: 75,
    height: 1,
    backgroundColor: "#E2E2E2",
    marginTop: 7,
  },
  contain1: {
    marginTop: 32,
  },
  contain11: {
    marginTop: 32,
  },
  contain2: {
    display: "flex",
    flexDirection: "row",
  },
  contain22: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 22,
  },
  contain3: {
    display: "flex",
    flexDirection: "row",
    width: "252",
    justifyContent: "space-between",
    marginLeft: "12",
    marginTop: 8,
  },
  contain33: {
    display: "flex",
    flexDirection: "row",
    width: "272",
    justifyContent: "space-between",
    marginLeft: "12",
    marginTop: 8,
    paddingLeft: 22,
  },
  reportTitle4: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#2D4764",
    marginRight: 12,
  },
  reportTitle5: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#2D4764",
  },
  reportTitle6: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#247D38",
  },
  contain4: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 241,
    border: "1 dash #CDCDCD",
    borderRadius: 22,
    marginLeft: 16,
    marginTop: 18,
    paddingTop: 8,
    paddingBottom: 12,
  },
  contain5: {
    display: "flex",
    flexDirection: "row",
    width: "200",
    justifyContent: "space-between",
    marginTop: 8,
  },
  contain7: {
    display: "flex",
    flexDirection: "row",
    width: "200",
    justifyContent: "space-between",
    marginTop: 8,
    marginBottom: 8,
  },
  contain8: {
    display: "flex",
    flexDirection: "row",
    width: "200",
    justifyContent: "space-between",
    marginTop: 8,
    paddingBottom: 4,
    borderBottom: "1 solid #E2E2E2",
  },
  contain6: {
    border: "1 dash #CDCDCD",
    borderRadius: 16,
    width: 40,
    height: 16,
    paddingLeft: 10,
    paddingRight: 6,
    paddingTop: 1,
  },

  reportTitle31: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
    marginTop: 24,
  },
  reportTitle32: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
    marginTop: 8,
  },
  subContainer11: {
    marginTop: 14,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 190,
    borderBottom: "0.1 dashed #E2E2E2",
  },
  subContainer12: {
    width: 9,
    height: 9,
    backgroundColor: "#FFCE31",
    borderRadius: "50",
    marginRight: 15,
  },
  reportTitle321: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16",
    color: "#2D4764",
    marginRight: 10,
  },
  reportTitle322: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#2D4764",
    width: 70,
    marginRight: 30,
  },
  reportTitle3221: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#2D4764",
    width: 70,
    marginRight: 10,
  },
  subContainer122: {
    width: 16.87,
    height: 38.61,
    backgroundColor: "#FFCE31",
  },
  subContainer121: {
    width: 9,
    height: 9,
    backgroundColor: "#DF6569",
    borderRadius: "50",
    marginRight: 15,
  },
  subContainer1221: {
    width: 16.87,
    height: 72,
    backgroundColor: "#DF6569",
  },
  subContainer111: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 190,
  },
  reportTitle311: {
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14",
    color: "#2D4764",
    marginTop: 20,
  },
  subContainer91: {
    width: 228,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E8FCE8",
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  subContainer9111: {
    width: 228,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFCCCB",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  subContainer9115: {
    width: 228,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFCCCB",
  },
  subContainer92: {
    width: 9,
    height: 9,
    backgroundColor: "#3FB10A",
    borderRadius: "50",
    marginRight: 8,
  },

  subContainer93: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    color: "#2D4764",
  },
  subContainer94: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
  },
  subContainer95: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 222,
    padding: 5,
    paddingBottom: 5,
  },
  subContainer96: {
    flexDirection: "row",
    alignItems: "center",
  },
  subContainer921: {
    width: 9,
    height: 9,
    backgroundColor: "#F2994A",
    borderRadius: "50",
    marginRight: 8,
  },
  subContainer922: {
    width: 9,
    height: 9,
    backgroundColor: "#4D7E6D",
    borderRadius: "50",
    marginRight: 8,
  },
  subContainer923: {
    width: 9,
    height: 9,
    backgroundColor: "#4D7E6D",
    borderRadius: "50",
    marginRight: 8,
  },
  subContainer925: {
    width: 9,
    height: 9,
    backgroundColor: "#287E97",
    marginRight: 8,
    borderRadius: "50",
  },
  subContainer924: {
    width: 9,
    height: 9,
    backgroundColor: "#287E97",
    borderRadius: "50",
    marginRight: 8,
  },
  subContainer9112: {
    width: 216,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  subContainer9241: {
    width: 9,
    height: 9,
    backgroundColor: "#FFCE31",
    borderRadius: "50",
    marginRight: 8,
  },
  subContainer931: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
  },
  subContainer941: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14",
    color: "#FFD75D",
  },
  subContainer9113: {
    width: 216,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
    marginLeft: 4,
  },
  subContainer932: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
  },
  subContainer942: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12",
    color: "#2D4764",
  },
  reportNameLeft9: {
    position: "absolute",
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10",
    color: "#000000",
    left: 46,
    top: 810,
  },
  reportNameLeft10: {
    position: "absolute",
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10",
    color: "#000000",
    left: 170,
    top: 822,
  },
});

