import React, { useEffect, useMemo, useRef } from "react";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import useOutsideAlerter from "src/hooks/useOutsideAlerter";
import { CloseButton } from "src/shared/components";
import { isValidURL } from "src/utils";

import { ImageComponent, NextArrow, PrevArrow } from "./components";
import * as S from "./ImageSlider.styles";

import { getAddress } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

interface SlideProps {
  url: string;
}
const Slide: React.FC<SlideProps> = ({ url }: SlideProps) => {
  const imgRef = useRef<any>(null);
  const quickPinchZoomRef = useRef<any>(null);

  const onUpdate = ({ x, y, scale }: any): void => {
    const { current: img } = imgRef;

    if (img?.complete) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
      img.style.opacity = 1;
    } else if (img) {
      const value = make3dTransformValue({ x: 0, y: 0, scale: 1 });
      img.style.setProperty("transform", value);
      img.style.opacity = 0;
    }
  };

  const resetZoom = (): void => {
    const { current: img } = imgRef;
    const { current: quickPinchZoomElement } = quickPinchZoomRef;

    const { width, height } = img;

    const centerX = width / 2;
    const centerY = height / 2;

    quickPinchZoomElement.alignCenter({ x: centerX, y: centerY, scale: 1 });
  };

  // The image will keep it's zoom when it is swiped, we want to reset the zoom when we swipe back
  // to an image therefore we implement this here.
  useEffect(
    () =>
      function cleanup() {
        resetZoom();
      },
  );

  return (
    <QuickPinchZoom
      onUpdate={onUpdate}
      // onUpdate={() => {}}
      ref={quickPinchZoomRef}
      draggableUnZoomed={false}
      shouldInterceptWheel={() => false}
    >
      <img className="zoom-image product-image" src={url} alt="zoomed-item" ref={imgRef} />
    </QuickPinchZoom>
  );
};

interface ViewImageSliderProps {
  setOpen: (open: boolean) => void;
  currImageInd: number;
}
const ViewImageSlider: React.FC<ViewImageSliderProps> = (props: ViewImageSliderProps) => {
  const { setOpen, currImageInd } = props;

  const contentRef = useRef(null);

  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(getAddress);

  useOutsideAlerter(contentRef, setOpen);

  const sliderSettings = useMemo(() => {
    return {
      accessibility: true,
      speed: 600,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      nextArrow: <NextArrow onClick={() => {}} />,
      prevArrow: <PrevArrow onClick={() => {}} />,
    };
  }, []);

  const imgURLs = useMemo(() => {
    const filteredImages = quoteProfile?.imgURLs.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });

    const leftArr = filteredImages.slice(0, currImageInd) ?? [];
    const rightArr = filteredImages.slice(currImageInd) ?? [];
    return rightArr.concat(leftArr);
  }, [currImageInd, address, quoteProfile.imgURLs]);

  return (
    <S.ImageSlider>
      <div className="close-button-div">
        <CloseButton
          onClick={() => {
            setOpen(false);
          }}
        />
      </div>
      <div className="content" ref={contentRef}>
        <Slider {...sliderSettings}>
          {imgURLs.map((url: any, ind: number) => {
            return <Slide url={url} key={url} />;
            return <ImageComponent key={url} url={url} />;
          })}
        </Slider>
      </div>
    </S.ImageSlider>
  );
};

export default ViewImageSlider;
