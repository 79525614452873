const chartColors = Object.freeze({
  PROFIT: "#FFCE31",
  FEED_IN: "#3FB10A",
  SAVINGS: "#F2994A",
  MOBILITY_SAVINGS: "#2cc7a5",
  GAS_SAVINGS: "#9426b5",

  LOSS: "#DF6569",
  MOBILITY_GRID: "#268db5",
  HEAT_GRID: "#B5262B",
  BUILDING_COST: "#2D4764",
  FINANCING_COST: "#7AB4FF",
  CARS: "#64462d",
});

export default chartColors;
