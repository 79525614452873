import {
  Image,
  StyleSheet,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import solarhubLogo from "../../../assets/bigScreenImages/SolarhubSvg.svg";
import headingBackground from "../../../assets/bigScreenImages/headingBackground.png";
import colors from "../../Utils/colors";
import { useSelector } from "react-redux";
import { UserSelectors } from "src/redux/selectors";

const PageHeading = ({ heading, titleWidth = 265 }) => {
  const styles = StyleSheet.create({
    container: {
      display: "flex",
      padding: 30,
      paddingTop: 15,
      paddingBottom: 15,
      paddingRight: 0,
      backgroundColor: "white",
      position: "relative",
    },
    heading_box: {
      position: "absolute",
      bottom: 10,
      left: 18,
      borderRadius: 15,
      height: 17,
      zIndex: -1,
      width: titleWidth,
    },
    heading: {
      fontFamily: "Nunito",
      fontWeight: 700,
      fontSize: 20,
      color: colors.primaryBlue,
    },
  });
  const installer = useSelector(UserSelectors.getInstaller);

  const SolarHubLogo = () => (
    <Svg
      width="160"
      height="60"
      viewBox="0 0 380 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M69.2813 94.7321C84.3374 94.7321 96.5429 82.4621 96.5429 67.3263C96.5429 52.1906 84.3374 39.9206 69.2813 39.9206C54.2251 39.9206 42.0197 52.1906 42.0197 67.3263C42.0197 82.4621 54.2251 94.7321 69.2813 94.7321Z"
        fill="url(#paint0_linear_1228_1550)"
      />
      <Path
        d="M64.2918 25.8703C71.3981 25.8703 77.1589 20.0791 77.1589 12.9352C77.1589 5.79127 71.3981 0 64.2918 0C57.1854 0 51.4246 5.79127 51.4246 12.9352C51.4246 20.0791 57.1854 25.8703 64.2918 25.8703Z"
        fill="#DBA726"
      />
      <Path
        d="M35.5873 114.547C39.1405 114.547 42.0209 111.651 42.0209 108.079C42.0209 104.507 39.1405 101.611 35.5873 101.611C32.0342 101.611 29.1538 104.507 29.1538 108.079C29.1538 111.651 32.0342 114.547 35.5873 114.547Z"
        fill="#DBA726"
      />
      <Path
        d="M98.7799 129C106.72 129 113.157 122.529 113.157 114.547C113.157 106.564 106.72 100.093 98.7799 100.093C90.8395 100.093 84.4025 106.564 84.4025 114.547C84.4025 122.529 90.8395 129 98.7799 129Z"
        fill="#DBA726"
      />
      <Path
        d="M18.6248 93.9278C16.3483 93.9278 14.1429 93.7364 12.0087 93.3534C9.87443 92.9704 7.88247 92.3959 6.03279 91.6299C4.23054 90.8161 2.59429 89.8347 1.12403 88.6857C0.649758 88.3506 0.317765 87.9676 0.128054 87.5367C-0.0142283 87.058 -0.0379421 86.6271 0.0569132 86.2441C0.199196 85.8133 0.41262 85.4542 0.697186 85.167C1.02918 84.8798 1.4086 84.7361 1.83545 84.7361C2.2623 84.6883 2.71286 84.8319 3.18714 85.167C5.36881 86.7947 7.66905 87.9915 10.0879 88.7575C12.5541 89.5235 15.3997 89.9065 18.6248 89.9065C23.1304 89.9065 26.4978 89.0208 28.7269 87.2495C31.0034 85.4782 32.1417 83.1563 32.1417 80.2839C32.1417 77.8902 31.3117 76.0231 29.6517 74.6827C28.0392 73.3422 25.3595 72.289 21.6128 71.523L14.4275 70.015C9.92186 69.1054 6.57821 67.5735 4.39654 65.4192C2.21487 63.2649 1.12403 60.3924 1.12403 56.8019C1.12403 54.5998 1.55088 52.613 2.40458 50.8417C3.25828 49.0225 4.46768 47.4666 6.03279 46.174C7.59791 44.8814 9.44758 43.9 11.5818 43.2298C13.7635 42.5117 16.1586 42.1526 18.7671 42.1526C21.8499 42.1526 24.6718 42.5835 27.2329 43.4452C29.8415 44.3069 32.2128 45.6713 34.3471 47.5384C34.7739 47.8735 35.0585 48.2804 35.2008 48.7592C35.3431 49.19 35.3431 49.6209 35.2008 50.0518C35.0585 50.4348 34.8451 50.7459 34.5605 50.9853C34.2759 51.2247 33.9202 51.3683 33.4934 51.4162C33.0665 51.4162 32.616 51.2247 32.1417 50.8417C30.1497 49.214 28.0629 48.0411 25.8813 47.323C23.747 46.557 21.3756 46.174 18.7671 46.174C16.1112 46.174 13.7872 46.6049 11.7952 47.4666C9.85072 48.3283 8.33303 49.5491 7.2422 51.1289C6.15136 52.7087 5.60595 54.5758 5.60595 56.7301C5.60595 59.2195 6.36479 61.2063 7.88247 62.6904C9.40015 64.1745 11.8427 65.2516 15.21 65.9218L22.4665 67.4298C27.2567 68.4352 30.8137 69.9432 33.1377 71.9539C35.4616 73.9167 36.6236 76.6216 36.6236 80.0685C36.6236 82.127 36.1968 84.018 35.3431 85.7415C34.5368 87.417 33.3511 88.8772 31.786 90.1219C30.2209 91.3666 28.3238 92.3241 26.0947 92.9943C23.913 93.6167 21.4231 93.9278 18.6248 93.9278Z"
        fill="#112242"
      />
      <Path
        d="M102.165 93.3534C101.501 93.3534 100.955 93.1619 100.529 92.7789C100.149 92.348 99.9595 91.7735 99.9595 91.0554V44.666C99.9595 43.9 100.149 43.3255 100.529 42.9425C100.908 42.5596 101.43 42.3681 102.094 42.3681C102.805 42.3681 103.351 42.5596 103.73 42.9425C104.109 43.3255 104.299 43.9 104.299 44.666V89.4038H128.772C129.483 89.4038 130.005 89.5714 130.337 89.9065C130.716 90.2416 130.906 90.7203 130.906 91.3427C130.906 92.0129 130.716 92.5156 130.337 92.8507C130.005 93.1858 129.483 93.3534 128.772 93.3534H102.165Z"
        fill="#112242"
      />
      <Path
        d="M134.585 93.7842C133.969 93.7842 133.471 93.6406 133.091 93.3534C132.759 93.114 132.57 92.7549 132.522 92.2762C132.522 91.7975 132.641 91.2709 132.878 90.6964L152.869 44.3069C153.201 43.5888 153.604 43.0862 154.078 42.7989C154.552 42.4638 155.027 42.2962 155.501 42.2962C156.07 42.2962 156.568 42.4638 156.995 42.7989C157.469 43.0862 157.849 43.5888 158.133 44.3069L178.124 90.6964C178.408 91.2709 178.527 91.7975 178.48 92.2762C178.48 92.7549 178.314 93.114 177.982 93.3534C177.697 93.6406 177.246 93.7842 176.63 93.7842C175.966 93.7842 175.421 93.6167 174.994 93.2815C174.614 92.8986 174.282 92.3959 173.998 91.7735L168.52 78.7759L170.867 79.853H140.063L142.482 78.7759L137.004 91.7735C136.72 92.4916 136.364 93.0182 135.937 93.3534C135.558 93.6406 135.107 93.7842 134.585 93.7842ZM155.43 48.1847L143.336 76.9088L141.7 76.0471H169.231L167.737 76.9088L155.572 48.1847H155.43Z"
        fill="#112242"
      />
      <Path
        d="M189.839 93.7842C189.175 93.7842 188.654 93.5927 188.274 93.2097C187.895 92.7789 187.705 92.2044 187.705 91.4863V45.025C187.705 44.2591 187.895 43.6846 188.274 43.3016C188.701 42.9186 189.247 42.7271 189.911 42.7271H206.629C211.941 42.7271 216.02 43.9718 218.865 46.4612C221.711 48.9507 223.134 52.4933 223.134 57.0892C223.134 60.0573 222.493 62.6186 221.213 64.7729C219.932 66.8793 218.035 68.4831 215.522 69.5842C213.055 70.6852 210.091 71.2358 206.629 71.2358L207.411 70.3023H208.407C210.21 70.3023 211.775 70.781 213.103 71.7385C214.478 72.6959 215.711 74.2518 216.802 76.4061L224.343 90.5528C224.675 91.0794 224.817 91.606 224.77 92.1326C224.77 92.6113 224.604 93.0182 224.272 93.3534C223.94 93.6406 223.513 93.7842 222.991 93.7842C222.375 93.7842 221.853 93.6406 221.426 93.3534C221.047 93.0661 220.715 92.6592 220.43 92.1326L212.462 77.1242C211.229 74.8263 209.854 73.3183 208.336 72.6002C206.819 71.8342 204.779 71.4512 202.218 71.4512H192.045V91.4863C192.045 92.2044 191.855 92.7789 191.476 93.2097C191.144 93.5927 190.598 93.7842 189.839 93.7842ZM192.045 67.7171H206.06C210.281 67.7171 213.435 66.8314 215.522 65.0601C217.656 63.2409 218.723 60.5839 218.723 57.0892C218.723 53.6423 217.656 51.0332 215.522 49.2619C213.435 47.4427 210.281 46.5331 206.06 46.5331H192.045V67.7171Z"
        fill="#112242"
      />
      <Path
        d="M237.727 93.856C236.541 93.856 235.617 93.5209 234.953 92.8507C234.336 92.1805 234.028 91.2469 234.028 90.0501V46.0304C234.028 44.7857 234.336 43.8521 234.953 43.2298C235.617 42.5596 236.541 42.2244 237.727 42.2244C238.913 42.2244 239.814 42.5596 240.43 43.2298C241.094 43.8521 241.426 44.7857 241.426 46.0304V64.5574H269.527V46.0304C269.527 44.7857 269.836 43.8521 270.452 43.2298C271.116 42.5596 272.041 42.2244 273.227 42.2244C274.412 42.2244 275.313 42.5596 275.93 43.2298C276.547 43.8521 276.855 44.7857 276.855 46.0304V90.0501C276.855 91.2469 276.547 92.1805 275.93 92.8507C275.313 93.5209 274.412 93.856 273.227 93.856C272.041 93.856 271.116 93.5209 270.452 92.8507C269.836 92.1805 269.527 91.2469 269.527 90.0501V70.8049H241.426V90.0501C241.426 91.2469 241.118 92.1805 240.502 92.8507C239.885 93.5209 238.96 93.856 237.727 93.856Z"
        fill="#112242"
      />
      <Path
        d="M308.84 93.9997C305.473 93.9997 302.509 93.5688 299.947 92.7071C297.386 91.7975 295.228 90.457 293.474 88.6857C291.766 86.9144 290.462 84.7122 289.561 82.0791C288.66 79.4461 288.209 76.4061 288.209 72.9592V45.9586C288.209 44.7139 288.517 43.7803 289.134 43.158C289.798 42.5356 290.723 42.2244 291.909 42.2244C293.094 42.2244 293.995 42.5356 294.612 43.158C295.276 43.7803 295.608 44.7139 295.608 45.9586V72.8156C295.608 77.7466 296.722 81.4329 298.952 83.8744C301.228 86.316 304.524 87.5367 308.84 87.5367C313.203 87.5367 316.5 86.316 318.729 83.8744C320.958 81.4329 322.072 77.7466 322.072 72.8156V45.9586C322.072 44.7139 322.381 43.7803 322.997 43.158C323.661 42.5356 324.562 42.2244 325.701 42.2244C326.886 42.2244 327.788 42.5356 328.404 43.158C329.021 43.7803 329.329 44.7139 329.329 45.9586V72.9592C329.329 77.5551 328.546 81.4329 326.981 84.5925C325.464 87.7043 323.187 90.0501 320.152 91.6299C317.116 93.2097 313.346 93.9997 308.84 93.9997Z"
        fill="#112242"
      />
      <Path
        d="M344.724 93.3534C343.443 93.3534 342.447 93.0182 341.736 92.348C341.072 91.6299 340.74 90.6246 340.74 89.332V46.7485C340.74 45.4559 341.072 44.4745 341.736 43.8043C342.447 43.0862 343.443 42.7271 344.724 42.7271H361.655C365.118 42.7271 368.058 43.2537 370.477 44.3069C372.943 45.3602 374.817 46.8682 376.097 48.831C377.425 50.7938 378.089 53.1635 378.089 55.9402C378.089 59.0041 377.188 61.5893 375.386 63.6957C373.583 65.8022 371.141 67.2144 368.058 67.9325V66.8554C371.568 67.3341 374.319 68.6746 376.311 70.8767C378.303 73.0311 379.299 75.8556 379.299 79.3504C379.299 83.8505 377.828 87.3213 374.888 89.7628C371.947 92.1565 367.821 93.3534 362.509 93.3534H344.724ZM347.854 87.6085H361.655C365.213 87.6085 367.845 86.9144 369.552 85.526C371.26 84.0898 372.113 81.9355 372.113 79.0631C372.113 76.1907 371.26 74.0603 369.552 72.672C367.845 71.2837 365.213 70.5895 361.655 70.5895H347.854V87.6085ZM347.854 64.8447H360.517C363.932 64.8447 366.517 64.1505 368.272 62.7622C370.026 61.3739 370.904 59.3392 370.904 56.6583C370.904 53.9774 370.026 51.9428 368.272 50.5544C366.517 49.1661 363.932 48.4719 360.517 48.4719H347.854V64.8447Z"
        fill="#112242"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_1228_1550"
          x1="235.058"
          y1="79.4995"
          x2="195.55"
          y2="-33.2944"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FBB03B" />
          <Stop offset="0.75" stopColor="#F7931E" />
        </LinearGradient>
      </Defs>
    </Svg>
  );

  return (
    <View style={styles.container}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "space-between",
          marginRight: 10,
        }}
      >
        <SolarHubLogo />
        {installer?.logoUrl.length && (
          <Image
            src={`${installer?.logoUrl[0]}?query=${Math.random() * 20000}`}
            alt="Intaller partner"
            style={{ height: 50, width: 165, objectFit: "contain"  }}
          />
        )}
      </View>
      {heading && <Text style={styles.heading}>{heading}</Text>}
      {heading && <Image src={headingBackground} style={styles.heading_box}></Image>}
    </View>
  );
};

export default PageHeading;
