import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30
  },
  description: {
    paddingTop: 15
  },
  bigImage: {
    width: "100%",
    backgroundColor: "#F9D05566",
    borderRadius: 15,
    padding: 10
  },
  table: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  makeItCenter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%"
  },
  table_left: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start"
  },
  table_right: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start"
  },
  paddingLeft: {
    paddingLeft: 20
  },
  black_description_small: {
    fontFamily: "Nunito",
    fontWeight: 100,
    opacity: 0.9,
    fontSize: 10,
    color: colors.black,
  }
})

export default styles;