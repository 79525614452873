const colors = {
    primaryBlue: "#2D4764",
    primaryYellow: "#FFD75D",
    black: "#000000",
    ligthYellow: "#F9D05566",
    grey: "#666666",
    greyLight: "#CCCCCC",
    red: "#DF6569",
    neviBlue: "#287E97",
    yellowSecound: "#FFE79E"
}

export default colors;