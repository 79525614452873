import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30,
    position: "relative"
  },
  img: {
    width: 50,
    height: 50,
  },
  flexRowTop: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10
  },
  black_description_small: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 18,
    paddingTop: 10,
    color: colors.primaryBlue,
  },
  monthlyChartScreenShot: {
    backgroundColor: "#FFFFFF",
    borderRadius: "7",
    border: "1 solid #DFDFDF",
    padding: 20
  },
  monthlyChartScreenShot2: {
    backgroundColor: "#FFFFFF",
    borderRadius: "7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1 solid #DFDFDF",
    height: "100%",
    padding: 10
  }
})

export default styles;