export const hoverBarsKey = {
  PROFIT_HOVER: "PROFIT_HOVER",
  LOSS_HOVER: "LOSS_HOVER",
  FEED_IN_HOVER: "FEED_IN_HOVER",
  SAVINGS_HOVER: "SAVINGS_HOVER",
  MOBILITY_SAVINGS_HOVER: "MOBILITY_SAVINGS_HOVER",
  HEAT_SAVINGS_HOVER: "HEAT_SAVINGS_HOVER",
  MOBILITY_GRID_HOVER: "MOBILITY_GRID_HOVER",
  HEAT_GRID_HOVER: "HEAT_GRID_HOVER",
  CARS_HOVER: "CARS_HOVER",
  BUILDING_COST_HOVER: "BUILDING_COST_HOVER",
  FINANCING_COST_HOVER: "FINANCING_COST_HOVER",
};

const actionTypes = {
  SET_HOVER: "SET_HOVER",
  SHOW_CALCULATIONS: "SHOW_CALCULATIONS",
  SHOW_TOOLTIPS: "SHOW_TOOLTIPS",
};

export const actionSetHover = (hoverKey?: string) => {
  return {
    type: actionTypes.SET_HOVER,
    payload: hoverKey ?? "",
  };
};

export const actionSetTooltip = (status: boolean) => {
  return {
    type: actionTypes.SHOW_TOOLTIPS,
    payload: status,
  };
};

export const actionShowCalculations = (show = false) => {
  return {
    type: actionTypes.SHOW_CALCULATIONS,
    payload: show,
  };
};

const setHoverActionHandler = (state: any, hoverKey: any) => {
  return {
    ...state,
    hoveredBar: hoverKey,
  };
};

const showCalculationActionHandler = (state: any, show: boolean) => {
  return {
    ...state,
    showCalculation: show,
  };
};

const setTooltipHandler = (state: any, show: boolean) => {
  return {
    ...state,
    tooltip: show,
  };
};

export const InitialState = {
  hoveredBar: "",
  showCalculation: false,
  tooltip: false,
};

export const hoverReducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.SET_HOVER: {
      return setHoverActionHandler(state, action.payload);
    }
    case actionTypes.SHOW_CALCULATIONS: {
      return showCalculationActionHandler(state, action.payload);
    }
    case actionTypes.SHOW_TOOLTIPS: {
      return setTooltipHandler(state, action.payload);
    }
    default:
      return state;
  }
};
