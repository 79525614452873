import { useSelector } from "react-redux";
import { Image, Text, View } from "@react-pdf/renderer";

import Footer from "../../components/Footer/Footer";
import solarhubLogo from "../../../assets/logos/SolarhubLogo.svg";
import dots from "../../../assets/logos/dots.png";
import HomePhoto from "../../../assets/bigScreenImages/HomePhoto.png";

import { getFormattedDate } from "../../Utils/helperMethods";
import { getProject } from "../../../redux/project/selectors";
import { getUser } from "../../../redux/user/selectors";
import styles from "./HomePageStyles";
import { UserSelectors } from "src/redux/selectors";
// import { useLocation } from "react-router";
// import { useLocation } from "react-router-dom";

const HomePage = () => {
  // const location = useLocation();
  const project = useSelector(getProject);
  const user = useSelector(getUser);
  const installer = useSelector(UserSelectors.getInstaller);
  const hasPartner = window.location.href.includes("partner");
  // console.log("hasPartner", hasPartner);
  // alert(hasPartner);
  // Call the function to get the formatted date
  var date = getFormattedDate();

  return (
    <View style={styles.container}>
      <View style={styles.containerHeader}>
        <View style={styles.containerHeaderLeftPart}>
          <Image src={solarhubLogo} style={styles.solarhubLogo}></Image>
          <View style={styles.containerHeaderLeftPartTextContainer}>
            <View style={styles.flotingBox} />
            <Text style={styles.textContainerText}>Starte jetzt in dein</Text>
            <View style={styles.secoundFlotingBox} />
            <Text style={styles.textContainerText2}>klimaneutrales Leben.</Text>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.address}>
            <Text style={styles.addressHeading}>
              {user?.firstName} {user?.lastName}
            </Text>
            <Text style={styles.addressText}>{`${project.quote.mapboxAddress?.number} ${project.quote.mapboxAddress?.locality},`}</Text>
            <Text
              style={styles.addressText2}
            >{`${project.quote.mapboxAddress.postcode}, ${project.quote.mapboxAddress.city}.`}</Text>
          </View>
          <Image style={styles.dotImage} src={dots}></Image>
        </View>
      </View>
      <Image src={HomePhoto} style={styles.homeImage} />
      <View style={styles.flexBetween}>
        <View style={styles.same_css}>
          <Text style={styles.addressText}>Angebotsdatum: {date}</Text>
          <Text style={styles.addressText}>
            Projektnummer: #{`${project?.shortId}-${project?.version}`}
          </Text>
        </View>
        <View style={styles.same_css_two}>
          {hasPartner ? (
            <Text style={[styles.addressHeading, styles.textWrapper]}>{installer?.address?.replace(", Germany","")}</Text>
          ) : (
            <>
              <Text style={styles.addressHeading}>Daniel Watz</Text>
              <Text style={styles.addressHeading}>SolarHub GmbH</Text>
              <Text style={styles.addressHeading}>Bäumleacker 9</Text>
              <Text style={styles.static_text}>79117 Freiburg im Breisgau</Text>
            </>
          )}
          <Text style={styles.static_text}>
            Tel: {hasPartner ? installer?.telephone : "+49 761 87003418"}
          </Text>
          <Text style={styles.static_text}>
            {hasPartner ? installer?.email : "experte@solarhub24.de"}
          </Text>
        </View>
      </View>
      <Footer />
    </View>
  );
};

export default HomePage;
