import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";

import German from "../../../assets/German.jpg";
import Tick from "../../../assets/Tick.png";
import CheckBox from "../../../assets/Checkbox.png";
import { formatNumberByLang } from "../../../hooks/useNumberFormat";
import { makeImgUrl, costDetails1 } from "../../Utils/helperMethods";

import { getFinancialDetails } from "../../../redux/project/selectors";
import { useTranslation } from "react-i18next";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { getApplicaitonFilters } from "src/redux/application/selector";
import { useValidPreferenceMap } from "src/hooks";

const styles = StyleSheet.create({
  titleContainer: {
    position: "relative",
  },
  reportTitle: {
    position: "absolute",
    // fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "10",
    backgroundColor: "white",
    color: "#666666",
    // borderRadius: "0px 0px 0px 10px",
    padding: "6",
    right: "0px",
  },
  banner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 625,
    height: 173,
  },
  bannerContainer: {
    position: "absolute",
    left: "16",
    top: "16",
    width: 211,
    height: 143,
    backgroundColor: "#FFD75D",
    borderRadius: 10,
  },
  logo: {
    position: "absolute",
    width: 126,
    height: 43,
    left: "20",
    top: "4",
  },
  bannerTitle: {
    fontStyle: "normal",
    fontSize: "14",
    color: "#2D4764",
    left: "20",
    top: "50",
  },
  bannerTitle1: {
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
    left: "20",
    top: "60",
  },
  bannerTitle2: {
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
    left: "20",
    top: "70",
  },
  MainTitle: {
    fontStyle: "normal",
    fontSize: "16",
    color: "#2D4764",
    left: "249",
    top: "184",
  },
  SubContainer: {
    position: "absolute",
    left: 111,
    top: 221,
    width: 362,
    height: 155,
  },
  panel: {
    position: "absolute",
    width: 221,
    height: 155,
    left: "20",
    top: "4",
  },
  SubContainerText: {
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
    left: "269",
    top: "48",
    marginTop: 6,
  },
  MainTitle1: {
    fontStyle: "normal",
    fontSize: "16",
    color: "#2D4764",
    left: "193",
    top: "407",
  },
  SubContainer1: {
    width: 532,
    height: 160,
    backgroundColor: "#FFFFFF",
    borderRadius: "7",
    border: "1 solid #DFDFDF",
    marginTop: 8,
  },
  SubContainer11: {
    width: 532,
    height: 160,
    backgroundColor: "#FFFFFF",
    borderRadius: "22",
    border: "1 solid grey",
    marginTop: 8,
  },
  solar: {
    position: "absolute",
    width: 116,
    height: 89,
    left: "18",
    top: "53",
  },
  german: {
    position: "absolute",
    width: 19,
    height: 12,
    left: "35",
    top: 103,
    zIndex: 20,
  },
  productTitle: {
    position: "absolute",
    left: 50,
    top: 6,
    fontStyle: "normal",
    fontSize: "14",
    color: "#2D4764",
    marginTop: 4,
  },
  productTitle12: {
    position: "absolute",
    width: 100,
    height: 3,
    borderRadius: 10,
    left: "29",
    top: 29,
  },
  productTitle1: {
    position: "absolute",
    left: "28",
    top: 32,
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
  },
  productTitle22: {
    position: "absolute",
    left: "48",
    top: 142,
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
  },
  productTitleBar: {
    position: "absolute",
    left: "18",
    top: 48,
    width: 115,
    height: 4,
    backgroundColor: "#FFD75D",
    borderRadius: 4,
  },
  productTitle2: {
    position: "absolute",
    left: 185,
    width: 250,
    display: "flex",
    flexWrap: "wrap",
    top: 30,
    fontStyle: "normal",
    fontSize: "14",
    color: "#2D4764",
  },
  productTitle30: {
    position: "absolute",
    left: 185,
    width: 250,
    display: "flex",
    flexWrap: "wrap",
    top: 8,
    fontStyle: "normal",
    fontSize: "12",
    color: "#2D4764",
  },
  SubContainer2: {
    position: "absolute",
    left: 185,
    top: 52,
  },
  SubContainer3: {
    display: "flex",
    flexDirection: "row",
    marginTop: 6,
  },
  tick: {
    width: 11,
    height: 11,
    marginRight: 6,
  },
  productTitle3: {
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
    width: 210,
  },
  SubContainer4: {
    position: "absolute",
    left: 455,
    top: 50,
  },
  productTitle4: {
    fontStyle: "normal",
    fontSize: "10",
    color: "#2D4764",
    marginTop: 4,
  },
});

const Product = ({ project, name, isB2B, isEnglish, flag, backColor, left, preferences: propsPreferences, unfilteredProductsRedux }) => {
  const [showFlag, setShowFlag] = React.useState(false);
  const { t } = useTranslation();
  const [showPerformanceClass, setPerformanceClass] = React.useState(false);
  const {
    costs: {
      componentCosts: { solarPanelCost },
    },
  } = useSelector(getFinancialDetails);

  const defaultFilters = useSelector(getApplicaitonFilters);

  const unfilteredProducts = useMemo(() => {
    return unfilteredProductsRedux;
  }, [unfilteredProductsRedux]);

  const unfilteredPossibility = useValidPreferenceMap({
    preferences: defaultFilters,
    products: unfilteredProducts,
  });

  React.useEffect(() => {
    if ("properties" in project) {
      for (let i = 0; i < project.properties.length; i++) {
        if (project.properties[i].title === "Made in Germany") {
          setShowFlag(true);
        }
        if (project.properties[i].title === "Performance Class") {
          setPerformanceClass(true);
        }
      }
    }
  }, [project]);

  if (flag) {
    return (
      <View style={styles.SubContainer1}>
        <Image
          style={styles.solar}
          src={
            project.imgUrls.length && `${project.imgUrls[0]}?${Math.random() * 10000}${new Date()}`
          }
        />
        {showFlag && <Image style={styles.german} src={German} />}
        <Text style={{ ...styles.productTitle, left: left ? left : 50 }}>{name}</Text>
        <View style={{ ...styles.productTitle12, backgroundColor: backColor }} />
        {showPerformanceClass && <Text style={styles.productTitle1}>Performance Class</Text>}
        {showPerformanceClass && <Text style={styles.productTitleBar} />}
        <Text style={styles.productTitle30}>Ihre gewählten Präferenzen</Text>
        <View style={styles.SubContainer2}>

        {(Object.entries(unfilteredPossibility))
        .filter(([key, { canBeChosen }]) => {
          return canBeChosen && key !== "applicableMaxCapacity";
        })
        .map(([key]) => {
          if (key === "applicableMaxCapacity") return <div key={key}></div>;

          return (
              <View style={styles.SubContainer3} key={key}>
                {propsPreferences[key] ? (
                  <Image style={styles.tick} src={Tick} />
                ): (
                <Image style={styles.tick} src={CheckBox} />
                )}
                <Text style={styles.productTitle3}>{t(key)}</Text>
              </View>
          );
        })}
        </View>
        <View style={styles.SubContainer4}>
          <Text style={styles.productTitle4}>
            ~€ {formatNumberByLang(project.buyInfo.totalPrice.toFixed(0))}
          </Text>
          <Text style={styles.productTitle4}>{costDetails1(project, isEnglish, isB2B)} </Text>
        </View>
      </View>
    );
  } else {
    return (
      <View style={styles.SubContainer11}>
        <Image
          style={styles.solar}
          src={
            project.imgUrls.length && `${project.imgUrls[0]}?${Math.random() * 10000}${new Date()}`
          }
        />
        {showFlag && <Image style={styles.german} src={German} />}
        <Text style={styles.productTitle}>{name}</Text>
        {showPerformanceClass && <Text style={styles.productTitle1}>Performance Class</Text>}
        {showPerformanceClass && <Text style={styles.productTitleBar} />}
        <Text style={styles.productTitle2}>{project.name}</Text>
        <View style={styles.SubContainer2}>
        {(Object.entries(unfilteredPossibility))
        .filter(([key, { canBeChosen }]) => {
          return canBeChosen && key !== "applicableMaxCapacity";
        })
        .map(([key]) => {
          if (key === "applicableMaxCapacity") return <div key={key}></div>;

          return (
              <View style={styles.SubContainer3} key={key}>
                <Image style={styles.tick} src={Tick} />
                <Text style={styles.productTitle3}>{t(key)}</Text>
              </View>
          );
        })}
        </View>
        <View style={styles.SubContainer4}>
          <Text style={styles.productTitle4}>
            ~€ {formatNumberByLang(project.buyInfo.totalPrice.toFixed(0))}
          </Text>
          <Text style={styles.productTitle4}>{costDetails1(project, isEnglish, isB2B)} </Text>
        </View>
      </View>
    );
  }
};

export default Product;
