import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 99%;
  flex: 1;
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 10px;

  .image_section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    white-space: nowrap;
    position: relative;
    width: 100%;
    .sample {
      width: 97px;
      height: 29px;
      background: rgba(226, 226, 226, 0.6);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 19px;
      left: 19px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      color: #2d3748;
    }
    .solar-popup {
      width: 97px;
      height: 29px;
      background: rgba(226, 226, 226, 0.6);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 19px;
      left: 19px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      color: #2d3748;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 99%;
  }
  @media only screen and (min-width: 500px) {
    flex-shrink: 0;
    max-width: 400px;
  }
  @media only screen and (min-width: 768px) {
    top: 0;
    min-width: 350px;
  }

  .overlay {
    border: 1px solid red;
    width: 90vw;
    height: 90vh;
    position: fixed;
    top: 5vw;
    left: 5vw;
  }

  .view-image-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 100;
  }
`;
interface InputAll {
  ischangecolor?: Boolean;
}
export const ImageContainer = styled.div`
  cursor: pointer;
  min-height: 150px;
  padding-inline: 6px;
  position: relative;
  img {
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12));
    border-radius: 14px;
  }

  .slick-dots {
    bottom: 0;
    li {
      margin: 0px;
    }
    li button:before {
      color: var(--primary-color);
      opacity: 1;
    }
    li.slick-active {
      transform: scale(2);
    }
  }

  #prev-btn,
  #next-btn {
    position: absolute;
    border-top: 0;
    border-inline: 12px solid transparent;
    border-bottom: 12px solid var(--primary-color);
    z-index: 1;
  }
  #prev-btn::before,
  #next-btn::before {
    display: none;
  }
  #next-btn {
    right: 4px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }
  #prev-btn {
    left: 4px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
  }
  .no_images_found {
    text-align: center;
    font-family: "Nunito";
    font-size: 16px;
    color: #2d4764;
  }
`;
export const ImageContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 155px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 350px;
  circle {
    stroke: var(--primary-color) !important;
  }
`;

export const Icon = styled.div`
  position: absolute;
  top: 16px;
  right: 15px;
  cursor: pointer;
`;
export const AllSubMainInput = styled.input<InputAll>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  width: 54px;
  height: 27px;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 16px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => {
    if (props.ischangecolor) {
      return "#666666";
    } else {
      return "#304864";
    }
  }};
`;
