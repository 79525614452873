import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30
  },
  box: {
    backgroundColor: "white",
    borderRadius: 8.82658,
  },
  boxDetails: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingLeft: 5,
    borderBottom: 0.76,
    justifyContent :"space-around",
    borderColor: colors.greyLight,
  },
  boxDetailsText: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 13,
    justifyContent: "center",
    alignContent: "center",
    paddingBottom: 5,
    paddingTop: 5,
    color: colors.neviBlue
  },
  monthlyChartScreenShot: {
    backgroundColor: "#FFFFFF",
    borderRadius: "7",
    border: "1 solid #DFDFDF",
    height: "100%",
    padding: 5
  },
  static_text: {
    fontFamily: "Nunito",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    paddingTop: 2,
    paddingLeft: 20,
    fontSize: 14,
    color: colors.primaryBlue,
  },
  img: {
    position: "absolute",
    top: 2
  },
  flexRow: {
    display: "flex",
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: 0.737923,
    borderColor: colors.greyLight
  },
  lastContainer: {
    width: "90%",
    paddingVertical: 15,
    paddingHorizontal: 35,
    marginHorizontal: 30,
    borderRadius: 7.946,
    marginTop: 10,
    backgroundColor: colors.yellowSecound
  },
  static_text2: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
    borderBottom: 0.737923,
    paddingBottom: 10,
    borderColor: colors.greyLight
  },
  static_text3: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
  },
  static_text23: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 17,
    paddingLeft: 30,
    color: colors.neviBlue,
  },
  boxDetailsConsumption: {
    borderLeft: 0.737923,
    width: "50%",
    borderColor: colors.greyLight
  },
  makeItFlex: {
    display: "flex",
    flexDirection: "row",
    height: 200,
    position: "relative",
  },
  makeItFlex2: {
    color: colors.greyLight,
    display: "flex",
    flexDirection: "row",
    gap: 10,
    height: 190,
    position: "relative",
  },
  table_left_below: {
    width: "50%",
    display: "flex",
    height: "100%",
    justifyContent: "flex-start"
  },
  table_right_below: {
    width: "50%",
    display: "flex",
    borderRadius: 20,
    justifyContent: "flex-start",
    border: 1,
    height: "100%",
    borderColor: colors.greyLight,
    borderRadius: 5,
  },
  table_left: {
    width: "60%",
    display: "flex",
    justifyContent: "flex-start"
  },
  table_right: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start"
  },
  black_description_small: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 16,
    paddingTop: 10,
    color: colors.primaryBlue
  }
});

export default styles;
