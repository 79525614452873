import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ProjectSelectors } from "src/redux/selectors";

interface ComponentAddStatus {
  isWaterHeaterAdded: boolean;
  isWaterHeaterNotAdded: boolean;
  isSolarPanelAdded: boolean;
  isSolarPanelNotAdded: boolean;
  isInvertedAdded: boolean;
  isInvertedNotAdded: boolean;
  isBatteryAdded: boolean;
  isBatteryNotAdded: boolean;
  isWallboxAdded: boolean;
  isWallboxNotAdded: boolean;
  areCarsAdded: boolean;
  areCarsNotAdded: boolean;
  isHeatpumpAdded: boolean;
  isHeatpumpNotAdded: boolean;
  isAdditionalSoftwareAdded: boolean;
  isAdditionalSoftwareNotAdded: boolean;
  isAdditionalHardwareAdded: boolean;
  isAdditionalHardwareNotAdded: boolean;
}

const defaultStatus = {
  isWaterHeaterAdded: false,
  isWaterHeaterNotAdded: false,
  isSolarPanelAdded: false,
  isSolarPanelNotAdded: false,
  isInvertedAdded: false,
  isInvertedNotAdded: false,
  isBatteryAdded: false,
  isBatteryNotAdded: false,
  isWallboxAdded: false,
  isWallboxNotAdded: false,
  areCarsAdded: false,
  areCarsNotAdded: false,
  isHeatpumpAdded: false,
  isHeatpumpNotAdded: false,
  isAdditionalSoftwareAdded: false,
  isAdditionalSoftwareNotAdded: false,
  isAdditionalHardwareAdded: false,
  isAdditionalHardwareNotAdded: false,
};
export const useIsComponentAdded = (): ComponentAddStatus => {
  const [status, setStatus] = useState<ComponentAddStatus>({ ...defaultStatus });

  const {
    battery,
    solarPanel,
    inverter,
    heatpump,
    waterHeating,
    cars,
    wallbox,
    additionalSoftware,
    additionalHardware,
  } = useSelector(ProjectSelectors.getComponents);

  useEffect(() => {
    const nextStatus: ComponentAddStatus = { ...defaultStatus };

    nextStatus.isBatteryAdded = battery.quantity !== 0;
    nextStatus.isBatteryNotAdded = !nextStatus.isBatteryAdded;

    nextStatus.isSolarPanelAdded = solarPanel.quantity !== 0;
    nextStatus.isSolarPanelNotAdded = !nextStatus.isSolarPanelAdded;

    nextStatus.isInvertedAdded = inverter.quantity !== 0;
    nextStatus.isInvertedNotAdded = !nextStatus.isInvertedAdded;

    nextStatus.isHeatpumpAdded = heatpump.quantity !== 0;
    nextStatus.isHeatpumpNotAdded = !nextStatus.isHeatpumpAdded;

    nextStatus.isWaterHeaterAdded = waterHeating;
    nextStatus.isWaterHeaterNotAdded = !nextStatus.isWaterHeaterAdded;

    nextStatus.isWallboxAdded = wallbox.quantity !== 0;
    nextStatus.isWallboxNotAdded = !nextStatus.isWallboxAdded;

    nextStatus.areCarsAdded = cars.length > 0;
    nextStatus.areCarsNotAdded = !nextStatus.areCarsAdded;

    nextStatus.isAdditionalSoftwareAdded = additionalSoftware.quantity !== 0;
    nextStatus.isAdditionalSoftwareNotAdded = !nextStatus.isAdditionalSoftwareAdded;

    nextStatus.isAdditionalHardwareAdded = additionalHardware.quantity !== 0;
    nextStatus.isAdditionalHardwareNotAdded = !nextStatus.isAdditionalHardwareAdded;

    setStatus(nextStatus);
  }, [
    battery.quantity,
    cars.length,
    heatpump.quantity,
    inverter.quantity,
    solarPanel.quantity,
    wallbox.quantity,
    waterHeating,
    additionalSoftware.quantity,
    additionalHardware.quantity
  ]);

  return status;
};
