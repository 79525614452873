import { UserDB } from "src/db-types";

import { MainUserState } from "src/redux/user";

export const convertUserFromDB = (user: UserDB): MainUserState => {
  const newUser: MainUserState = {
    address: user.address,
    displayName: user.firstName + user.lastName,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    flag: user.flag,
    id: user._id,
    lastLog: user.lastLog,
    phoneNumber: user.mobile,
    // @TODO - GET THE PHOTO URL HERE
    photoURL: "",
    role: user.role,
    webinars: user.webinars,
    hubSpotContactId: user.hubSpotContactId,
    joinedWebinars: user.joinedWebinars,
    refCode: user.refCode || [],
    credits: user.credits || []
  };

  return newUser;
};
