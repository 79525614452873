import { Fragment } from "react";
import { Document, Font, Page } from "@react-pdf/renderer";

import HomePage from "./pages/home/HomePage";
import PersonalEnergy from "./pages/PersonalEnergy/PersonalEnergy";
import SystemInfo from "./pages/SystemInfo/SystemInfo";
import InvestMentOverview from "./pages/InvestMentOverview/InvestMentOverview";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import BuildingsDetails from "./pages/BuildingsDetails/BuildingsDetails";
import EnergyFlow from "./components/EnergyFlow/EnergyFlow";
import SystemRequirements from "./pages/SystemRequirements/SystemRequirements";
import font from "../assets/fonts/Nunito-SemiBold.ttf";
import { useIsComponentAdded } from "src/hooks";
import ProductDetailsSecoundPage from "./pages/ProductDetails/ProductDetailsSecoundPage";

Font.register({
  family: "Nunito",
  src: font,
});

const LoadPdf = ({ investmentChartSS, monthlyChartSS, supplyChartSS, electrictySupplyChart }) => {
  const { isHeatpumpAdded, isWallboxAdded } =
  useIsComponentAdded();
  return (
    <Fragment>
        <Document>
          <Page size="A4">
            <HomePage />
          </Page>
          <Page size={[595, 1100]}>
            <PersonalEnergy />
          </Page>
          <Page size={[595, 1100]}>
            <SystemInfo
              monthlyChartScreenShot={monthlyChartSS}
              electrictySupplyChart={electrictySupplyChart}
              supplyChartScreenShot={supplyChartSS}
            />
          </Page>
          <Page size={[595, 1100]}>
           <EnergyFlow />
          </Page>
          <Page size={[595, 1000]}>
            <InvestMentOverview investMentChartScreenShot={investmentChartSS} />
          </Page>
          <Page size="A4">
            <ProductDetails />
          </Page>
          {
            (isHeatpumpAdded || isWallboxAdded) && (
          <Page size="A4">
            <ProductDetailsSecoundPage />
          </Page>
            )
          }
          <Page size="A4">
            <BuildingsDetails />
          </Page>
          <Page size="A4">
            <SystemRequirements />
          </Page>
        </Document>
    </Fragment>
  );
};

export default LoadPdf;
