import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useNaivgateAbsolute } from "src/hooks";

import { InstallerDB } from "src/db-types";
import useGetSaveProjectFun from "src/hooks/useGetSaveProjectFun";
import { Mixpanel, trackingEvents } from "src/mix-panel";
import { AUTH_MODES, AuthPopupProps } from "src/popups";
import { TabBar } from "src/shared/components";
import { Tab } from "src/shared/components/TabBar/TabBar";
import { PrimaryButton } from "src/shared/StyledComponents";
import { isNoQuotePresent } from "src/utils";

import { FinanceDropdown, FinanceTypeLiteral, FinanceTypes } from "./components";
import MonthlyElectricityCost from "./components/MonthlyChart/MonthlyElectricityCost";
import { Rent } from "./components/Rent/Rent";
import ReturnOnInvest from "./components/ReturnOnInvest/ReturnOnInvest";
import { MainContainer, MainText2 } from "./Finance.styles";

import { AppActions } from "src/redux";
import { PopupActions, ProjectActions } from "src/redux/actionCreators";
import { POPUP_KEYS } from "src/redux/popups";
import { AppSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";

const URL_OPTIONS = {
  goToHubspot: "goToHubspot",
  goToEmail: "goToEmail",
  goToCalendy: "goToCalendy",
  goToRedirect: "goToRedirect",
};

const Finance: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNaivgateAbsolute();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const partner = searchParams.get("partner");
  const [dropDownType, setDropDownType] = useState<FinanceTypeLiteral>(
    FinanceTypes["return-on-investment"],
  );

  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const { isFinancing } = useSelector(ProjectSelectors.getFinancialDetails);
  const currentUser = useSelector(UserSelectors.getUser);
  const currentStep = useSelector(AppSelectors.getCurrentStep);
  const simplify = useSelector(UserSelectors.getSimplify);
  const { saveProject } = useGetSaveProjectFun();
  const [activeTab, setActiveTab] = useState(isFinancing ? "finance" : "buy");
  const installer: InstallerDB | null = useSelector(UserSelectors.getInstaller);

  const openHubspotPopup = useCallback(() => {
    if (installer?.whichOptionSelected === URL_OPTIONS.goToHubspot) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.hubspot_meeting));
    } else if (installer?.whichOptionSelected === URL_OPTIONS.goToRedirect) {
      window.open(installer?.appointmentURL, "_blank", "noreferrer");
    } else if (installer?.whichOptionSelected === URL_OPTIONS.goToEmail) {
      window.location.href = `mailto:${installer?.appointmentURL}`;
    }
  }, [dispatch, installer]);

  const TABS = useMemo(() => {
    let tabs: Tab[] = [
      {
        key: "buy",
        label: t("BUY"),
      },
      {
        key: "finance",
        label: t("FINANCE"),
      },
      {
        key: "rent",
        label: t("RENT"),
      },
    ];

    if (installer && !installer?.isRentingAvalible) {
      tabs = tabs.filter((e) => {
        return e.key !== "rent";
      });
    }

    return tabs;
  }, [t, installer]);

  useEffect(() => {
    if (activeTab !== "finance" && isFinancing === true) {
      dispatch(
        ProjectActions.updateFinancialDetails({
          isFinancing: false,
        }),
      );
    }
  }, [activeTab, isFinancing]);

  const checkout = useCallback(() => {
    if (isNoQuotePresent(quoteId)) {
      dispatch(PopupActions.openPopup(POPUP_KEYS.welcome_address));
      return;
    }

    // if (project?.quoteStatus === "notFound") {
    //   toast.error(
    //     t(
    //       "Please wait for sometime. We are creating the project for your address -Please check back after some time"
    //     )
    //   );
    //   return;
    // }

    // save(null, null, true);
    // @TODO save project

    if (currentUser) {
      installer ? openHubspotPopup() : navigate("/payment");
    } else {
      const authProps: AuthPopupProps = {
        authMode: AUTH_MODES.signup,
      };
      dispatch(PopupActions.openPopup(POPUP_KEYS.auth, authProps));
    }
  }, [currentUser, dispatch, navigate, quoteId]);

  const nextStepBtnClickHandler = useCallback(() => {
    saveProject();
    if (currentStep === 0) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_NEXT_STEP_STEP1, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP1, {});
    }
    if (currentStep === 1) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON__STEP2, {});
    }
    if (currentStep === 2) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP3, {});
    }
    if (currentStep === 3) {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ARROW_BUTTON, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_STEP4, {});
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_CHECKOUT_BUTTON, {});
      checkout();
    } else {
      dispatch(AppActions.setCurrentStep(currentStep + 1));
    }
  }, [checkout, currentStep, dispatch, saveProject]);

  const financeClickHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_CLICKS_ON_FINANCE_STEP1, {});
    dispatch(
      ProjectActions.updateFinancialDetails({
        isFinancing: true,
      }),
    );
  }, [dispatch]);

  const buyClickHandler = useCallback(() => {
    Mixpanel.track(trackingEvents.USER_CLICKS_ON_BUY_STEP1, {});
    dispatch(
      ProjectActions.updateFinancialDetails({
        isFinancing: false,
      }),
    );
  }, [dispatch]);

  const tabClickHandler = useCallback(
    (key: string): void => {
      if (key === "buy") buyClickHandler();
      else financeClickHandler();
      setActiveTab(key);

      // if (key === "rent") setDropDownType(FinanceTypes["monthly-returns"]);
    },
    [buyClickHandler, financeClickHandler],
  );

  return (
    <MainContainer>
      <TabBar tabs={TABS} clickHandler={tabClickHandler} />
      {activeTab === "rent" && (
        <Rent dropDownType={dropDownType} setDropDownType={setDropDownType} />
      )}
      {activeTab !== "rent" && (
        <FinanceDropdown setDropdownType={setDropDownType} dropDownType={dropDownType} />
      )}
      {activeTab !== "rent" &&
        (dropDownType === "return-on-investment" ? (
          <ReturnOnInvest isRenting={activeTab === "rent"} />
        ) : (
          <MonthlyElectricityCost isRenting={activeTab === "rent"} />
        ))}
      {!simplify && <MainText2
        onClick={() => {
          Mixpanel.track(trackingEvents.USER_CLICKS_ON_SHOW_PROCESS_STEP1, {});
          Mixpanel.track(trackingEvents.USER_CLICKS_ON_SHOW_PROJECT_PROCESS, {});
          // setOpen(true);
          // TODO : Project process dialog
        }}
      >
        {t("SHOW PROJECT PROCESS")}
      </MainText2>}
      {partner === null && (
        <PrimaryButton
          onClick={nextStepBtnClickHandler}
          style={{
            width: "230px",
          }}
        >
          {currentStep === 3 ? `${t("Checkout")}` : `${t("NEXT STEP")}`}
        </PrimaryButton>
      )}
    </MainContainer>
  );
};

export default Finance;
