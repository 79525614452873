import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import defaultProjectImage from "src/assets/images/defaultProjectImage.png";
import useImageLoading from "src/hooks/useImageLoading";
import SolarLayout from "src/shared/components/SolarLayout/SolarLayout";
import { isQuoteNotVerified, isQuotePending, isQuotePresent, isValidURL } from "src/utils";


import * as S from "../../../../styles";

import { ImageSlider } from "./components";

import { getAddress } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";
import { getSolarAPIData } from "src/redux/solarAPI/selector";
import { getInstaller } from "src/redux/user/selectors";

const IMG_SATTELITE_IND = 0;
interface ImageBoxProps {
  is3DView: boolean;
  setFluxMonth: (key: number) => void;
  fluxMonth: number;
  selectedOption: string;
}
const ImageBox: React.FC<ImageBoxProps> = ({fluxMonth,setFluxMonth, selectedOption}) => {
  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(getAddress);
  const coords = useSelector(ProjectSelectors.getAddressCoords);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const status = useSelector(ProjectSelectors.getQuoteStatus);
  const installerPartner = useSelector(getInstaller);
  const solarAPIData = useSelector(getSolarAPIData);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);

  const [isZoomedViewOpen, setIsZoomedViewOpen] = useState(false);
  const [currImageInd, setCurrImageInd] = useState(IMG_SATTELITE_IND);

  const imageRef = useRef<HTMLImageElement>(null);
  useImageLoading(imageRef);

  const installerImages = useMemo(() => {
    return (
      selectedInstallers[0]?.files?.filter(
        (img) => !img.includes(".obj") && !img.includes(".mtl"),
      ) || []
    );
  }, [selectedInstallers]);

  const filteredImages = useMemo(() => {
    if (installerImages.length > 0) return installerImages;
    return quoteProfile?.imgURLs.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address, quoteProfile?.imgURLs, installerImages]);

  useEffect(() => {
    setCurrImageInd(0);
  }, [quoteProfile]);

  const sliderSettings = useMemo(() => {
    return {
      accessibility: true,
      speed: 600,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      nextArrow: (
        <div
          id="next-btn"
          onClick={(e) => {
            console.log("Called");
            e.stopPropagation();
          }}
        />
      ),
      prevArrow: (
        <div
          id="prev-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
    };
  }, []);

  const isToShowSolarLayout = useMemo(
    () => isQuotePresent(quoteId) && isQuoteNotVerified(status),
    [isQuoteNotVerified, isQuotePresent, quoteId, status, isQuotePending],
  );

  return (
    <>
      <S.ImageContainer>
        {isToShowSolarLayout && installerImages.length === 0 && (
          <SolarLayout selectedOption={selectedOption} setFluxMonth={setFluxMonth} fluxMonth={fluxMonth} height={250} width={350} center={{ lat: coords[1], lng: coords[0] }} />
        )}
        <>
          {(!isToShowSolarLayout || installerImages.length > 0) && (
            <Slider {...sliderSettings}>
              {filteredImages?.length > 0 ? (
                filteredImages.map((url: any, ind: number) => {
                  return <img key={url} src={url} alt="" />;
                })
              ) : (
                <img src={defaultProjectImage} alt="house" />
              )}
            </Slider>
          )}
          {!solarAPIData && (
            <div className="view-image-button">
              <Fab
                size="small"
                aria-label="add"
                style={{ backgroundColor: "var(--primary-color)" }}
                onClick={() => setIsZoomedViewOpen(true)}
              >
                <Add style={{ color: "black" }} />
              </Fab>
            </div>
          )}
        </>
      </S.ImageContainer>
      {isZoomedViewOpen && (
        <ImageSlider setOpen={setIsZoomedViewOpen} currImageInd={currImageInd} />
      )}
    </>
  );
};

export default ImageBox;
