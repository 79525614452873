import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Mixpanel, trackingEvents } from "src/mix-panel";
import TabBar, { Tab } from "src/shared/components/TabBar/TabBar";

import { ConfigurationParam, ProductionParam } from "./components";
import { MainContainer } from "./Configuration.styles";

import { AppActions, ProjectActions } from "src/redux/actionCreators";
import { QuoteProfileName } from "src/redux/project";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

const TAB_KEYS: Record<string, QuoteProfileName> = {
  ECO: "eco",
  SMART: "smart",
  GREEN: "green",
};

const Configuration: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedProfile = useSelector(ProjectSelectors.getProfileName);
  const simplify = useSelector(UserSelectors.getSimplify);

  const tabs: Tab[] = useMemo(() => {
    if (!simplify) {
      return [
        {
          key: TAB_KEYS.ECO,
          label: t("ECO"),
        },
        {
          key: TAB_KEYS.SMART,
          label: t("SMART"),
        },
        {
          key: TAB_KEYS.GREEN,
          label: t("GREEN"),
        },
      ];
    } else {
      return [
        {
          key: TAB_KEYS.GREEN,
          label: t("GREEN"),
        },
      ];
    }
  }, [t, simplify]);

  const fireMixPanelEvent = useCallback((key: QuoteProfileName) => {
    if (key === "eco") {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ECO_STEP1, {});
    }
    if (key === "green") {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_GREEN_STEP1, {});
    }
    if (key === "smart") {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_SMART_STEP1, {});
    }
  }, []);

  const headerItemClickHandler = useCallback(
    (key: string) => {
      const profileName = key as QuoteProfileName;

      fireMixPanelEvent(profileName);

      dispatch(
        ProjectActions.updateOuterDetails({
          profile: profileName,
        }),
      );

      dispatch(
        AppActions.updateAppState({
          actions: {
            updateSolarQtyByProfile: true,
          },
        }),
      );
    },
    [dispatch, fireMixPanelEvent],
  );

  return (
    <div style={{ flex: 1.2, width: "100%" }}>
      <MainContainer>
        <TabBar
          tabs={tabs}
          defaultSelected={selectedProfile}
          clickHandler={headerItemClickHandler}
        />
        <ProductionParam />
      </MainContainer>
      <MainContainer>
        <ConfigurationParam />
      </MainContainer>
    </div>
  );
};

export default Configuration;
