import { useEffect } from "react";
import { useSelector } from "react-redux";

import { WEB_CONSTANTS_KEYS } from "src/data";
import { ProductDB } from "src/db-types";
import { getProductCost } from "src/helpers/product";
import { getAverageProductCost } from "src/helpers/product/getProductCostByFilter";
import { RecursivePartial, getNumber } from "src/utils";

import { ProjectActions } from "src/redux/actionCreators";
import { FilteredProductsMap } from "src/redux/products";
import {
  BaseComponent,
  CarComponent,
  ProjectFinancialDetails,
  ProjectType,
} from "src/redux/project";
import { FilterableBaseComponent } from "src/redux/project/types/components";
import { ProjectWebConstants, WebConstant } from "src/redux/project/types/project-constants";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";

import { useAppDispatch } from ".";

interface GetComponentCostProps {
  comp: BaseComponent;
  projectType: ProjectType;
}
export const getComponentCost = ({ comp, projectType }: GetComponentCostProps): number => {
  const priceKey: keyof ProductDB = projectType === "b2b" ? "priceB2b" : "priceB2c";
  let cost = 0;

  const hasNoItem = comp.quantity === 0;
  if (hasNoItem) return cost;

  if (comp.isExcludeCost) return 0;

  const productCost = comp.quantity * getNumber(comp.item?.[priceKey]);

  let extendedWarrantyCost = 0;
  const hasWarranty = comp.extendedWarranty;
  if (hasWarranty) {
    const defaultWarranty = productCost * 0.01;
    const customWarranty = comp.item?.extendedWarrantyCost;
    extendedWarrantyCost = getNumber(customWarranty ?? defaultWarranty);
  }

  cost = productCost + extendedWarrantyCost;
  return cost;
};

interface GetExtraCostForConstantProps {
  constant: WebConstant;
  projectType: ProjectType;
  solarPanelProduction: number;
  solarPanelQty: number;
  allComponentsCost: number;
}
const getExtraCostForConstant = ({
  constant,
  projectType,
  solarPanelProduction,
  allComponentsCost,
  solarPanelQty,
}: GetExtraCostForConstantProps): number => {
  const priceKey = projectType ? "priceB2b" : "priceB2c";

  switch (constant.type) {
    case WEB_CONSTANTS_KEYS.FIX_FOR_PROJECT:
      return getNumber(String(constant[priceKey] || "0"));

    case WEB_CONSTANTS_KEYS.DEPENDING_ON_KWH:
      return (getNumber(String(constant[priceKey] || "0")) * solarPanelProduction) / 1000;

    case WEB_CONSTANTS_KEYS.FIX_PER_PANEL:
      return getNumber(String(constant[priceKey] || "0")) * solarPanelQty;

    case WEB_CONSTANTS_KEYS.PERCENTAGE_OF_PROJECT_COST:
      return (getNumber(String(constant[priceKey] || "0")) * allComponentsCost) / 100;
    default:
      return 0;
  }
};

interface GetFinancingCostProps {
  totalCost: number;
  increaseRateByBank: number;
}
export const getFinancingCost = ({
  increaseRateByBank,
  totalCost,
}: GetFinancingCostProps): number => {
  const DURATION_YEARS = 12;
  const DURATION_MONTHS = DURATION_YEARS * 12;

  const interestRate = increaseRateByBank / 100;

  const A = 1 + interestRate / 12;
  const B = A ** DURATION_MONTHS * (A - 1);
  const C = A ** DURATION_MONTHS - 1;
  const monthlyPaymentCost = totalCost * (B / C);

  const totalFinancingCost = monthlyPaymentCost * DURATION_MONTHS;
  return totalFinancingCost;
};

const calculateTaxedPrice = (value: number, tax: number | undefined) => {
  return value * (1 + (tax || 0) / 100);
};

interface IGetProjectCosts {
  cars: CarComponent[];
  filteredProducts: FilteredProductsMap;
  webConstants: ProjectWebConstants;
  increaseRateByBank: number;
  isFinancing: boolean;
  projectType: ProjectType;
  solarPanelProduction: number;
  solarPanel: FilterableBaseComponent;
  heatpump: FilterableBaseComponent;
  inverter: FilterableBaseComponent;
  wallbox: FilterableBaseComponent;
  battery: FilterableBaseComponent;
}
export const getProjectCosts = (
  props: IGetProjectCosts,
): RecursivePartial<ProjectFinancialDetails> => {
  const {
    cars,
    filteredProducts,
    increaseRateByBank,
    isFinancing,
    webConstants,
    projectType,
    solarPanelProduction,
    solarPanel,
    battery,
    heatpump,
    wallbox,
    inverter,
  } = props;

  const carsCost = cars
    .filter((car) => !car.isLeasing)
    .filter((car) => car.item)
    .reduce(
      (acc, car) => acc + 25 * 12 * getProductCost({ product: car.item as ProductDB, projectType }),
      0,
    );

  const solarPanelCost = solarPanel?.item
    ? calculateTaxedPrice(solarPanel?.item?.priceB2c * solarPanel?.quantity, solarPanel?.item?.tax)
    : getAverageProductCost(filteredProducts.solarPanels);
  const heatpumpCost = heatpump?.item
    ? calculateTaxedPrice(
        (heatpump?.item?.priceB2c || 0) * heatpump?.quantity || 0,
        heatpump?.item?.tax,
      )
    : getAverageProductCost(filteredProducts.heatpumps);
  const inverterCost = inverter?.item
    ? calculateTaxedPrice(inverter?.item?.priceB2c * inverter?.quantity, inverter?.item?.tax)
    : getAverageProductCost(filteredProducts.inverters);
  const wallboxCost = wallbox?.item
    ? calculateTaxedPrice(wallbox?.item?.priceB2c * wallbox?.quantity, wallbox?.item?.tax)
    : getAverageProductCost(filteredProducts.wallboxes);
  const batteryCost = battery?.item
    ? calculateTaxedPrice(battery?.item?.priceB2c * battery?.quantity, battery?.item?.tax)
    : getAverageProductCost(filteredProducts.batteries);

  const componentsCost =
    carsCost + solarPanelCost + inverterCost + heatpumpCost + wallboxCost + batteryCost;
      console.log(componentsCost, "componentsCost");
  const allConstantsCost = webConstants.constants.reduce(
    (acc, constant) =>
      acc +
      getExtraCostForConstant({
        constant,
        projectType,
        allComponentsCost: componentsCost,
        solarPanelProduction,
        solarPanelQty: solarPanel.quantity,
      }),
    0,
  );
  const projectCost = componentsCost + allConstantsCost;
  console.log(projectCost, "projectCost");
  const finCost = getFinancingCost({ increaseRateByBank, totalCost: projectCost });

  const appliedCost = isFinancing ? finCost : projectCost;

  return {
    costs: {
      totalFinancingCost: finCost,
      appliedProjectCost: appliedCost,
      totalProjectCost: projectCost,
      componentCosts: {
        constantsCost: allConstantsCost,
        carBuyingCost: carsCost,
        heatpumpCost,
        inverterCost,
        solarPanelCost,
        wallboxCost,
        batteryCost,
        totalCost: componentsCost,
      },
    },
  };
};

export const useProjectCosts = (): void => {
  const dispatch = useAppDispatch();

  const { isFinancing, increaseRateByBank } = useSelector(ProjectSelectors.getFinancialDetails);
  const webConstants = useSelector(ProjectSelectors.getWebConstants);
  const projectType = useSelector(ProjectSelectors.getProjectType);

  const {
    battery,
    cars,
    heatpump,
    inverter,
    solarPanel,
    wallbox,
    additionalHardware,
    additionalSoftware,
  } = useSelector(ProjectSelectors.getComponents);
  const filteredProducts = useSelector(ProductSelectors.getAllFilteredProducts);
  const { solarPanelProduction } = useSelector(ProjectSelectors.getEnergy);

  useEffect(() => {
    const carsCost = cars
      .filter((car) => !car.isLeasing)
      .filter((car) => car.item)
      .reduce((acc, car) => acc + getNumber(car.item?.buyingCarPrice), 0);

    const solarPanelCost = solarPanel?.item
      ? calculateTaxedPrice(solarPanel?.item?.priceB2c * solarPanel?.quantity, solarPanel?.item?.tax)
      : getAverageProductCost(filteredProducts.solarPanels);

    const heatpumpCost = heatpump.isExcludeCost
      ? 0
      : heatpump?.item
      ? calculateTaxedPrice(heatpump?.item?.priceB2c * heatpump?.quantity, heatpump?.item?.tax)
      : getAverageProductCost(filteredProducts.heatpumps);

    const inverterCost = inverter?.item
      ? calculateTaxedPrice(inverter?.item?.priceB2c * inverter?.quantity, inverter?.item?.tax)
      : getAverageProductCost(filteredProducts.inverters);

    const wallboxCost = wallbox.isExcludeCost
      ? 0
      : wallbox?.item
      ? calculateTaxedPrice(wallbox?.item?.priceB2c * wallbox?.quantity, wallbox?.item?.tax)
      : getAverageProductCost(filteredProducts.wallboxes);

    const batteryCost = battery.isExcludeCost
      ? 0
      : battery?.item
      ? calculateTaxedPrice(battery?.item?.priceB2c * battery?.quantity, battery?.item?.tax)
      : getAverageProductCost(filteredProducts.batteries);

    const additionalSoftwareCost = additionalSoftware?.item
      ? calculateTaxedPrice(additionalSoftware?.item?.priceB2c * additionalSoftware?.quantity, additionalSoftware?.item?.tax)
      : 0;

    const additionalHardwareCost = additionalHardware?.item
      ? calculateTaxedPrice(additionalHardware?.item?.priceB2c * additionalHardware?.quantity, additionalHardware?.item?.tax)
      : 0;

    const componentsCost =
      carsCost +
      solarPanelCost +
      inverterCost +
      heatpumpCost +
      wallboxCost +
      batteryCost +
      additionalSoftwareCost +
      additionalHardwareCost;

    const allConstantsCost = webConstants.constants.reduce(
      (acc, constant) =>
        acc +
        getExtraCostForConstant({
          constant,
          projectType,
          allComponentsCost: componentsCost,
          solarPanelProduction,
          solarPanelQty: solarPanel.quantity,
        }),
      0,
    );

    const projectCost = componentsCost + allConstantsCost;
    console.log(componentsCost,allConstantsCost, "projectCost");
    const finCost = getFinancingCost({ increaseRateByBank, totalCost: projectCost });

    const appliedCost = isFinancing ? finCost : projectCost;

    dispatch(
      ProjectActions.updateFinancialDetails({
        costs: {
          totalFinancingCost: finCost,
          appliedProjectCost: appliedCost,
          totalProjectCost: projectCost,
          componentCosts: {
            constantsCost: allConstantsCost,
            carBuyingCost: carsCost,
            heatpumpCost,
            inverterCost,
            solarPanelCost,
            wallboxCost,
            batteryCost,
            additionalSoftwareCost,
            additionalHardwareCost,
            totalCost: componentsCost,
          },
        },
      }),
    );
  }, [
    battery,
    cars,
    dispatch,
    filteredProducts.batteries,
    filteredProducts.heatpumps,
    filteredProducts.inverters,
    filteredProducts.solarPanels,
    filteredProducts.wallboxes,
    heatpump,
    increaseRateByBank,
    inverter,
    isFinancing,
    projectType,
    solarPanel,
    solarPanelProduction,
    wallbox,
    webConstants.constants,
    additionalSoftware,
    additionalHardware,
  ]);
};
