import { Slider } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { MasterInput } from "src/shared/components";
import * as ParameterStyles from "src/shared/StyledComponents/Parameters";

import { ProjectSelectors } from "src/redux/selectors";

const HouseholdSupplyInputLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { household: householdModel } = useSelector(ProjectSelectors.getModel);
  const {
    percentages: { autarky, maxPossibleAutarky },
  } = householdModel;

  const sliderChangeHandler = useCallback(
    (value: any) => {
      // dispatch(setHouseholdSelfSupply(value));
      // dispatch(actionCustomAutarkyChange());
    },
    [dispatch],
  );

  const inputBoxChangeHandler = useCallback(
    (e: any) => {
      // const value = parseInt(e.target.value);
      // const finalSelfSupply = Math.max(value, maxPossibleAutarky);
      // dispatch(setHouseholdSelfSupply(finalSelfSupply));
      // dispatch(actionCustomAutarkyChange());
    },
    [dispatch, maxPossibleAutarky],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>{t("SELF SUPPLY")}</ParameterStyles.Label>
      <ParameterStyles.Input>
        <Slider
          onChange={sliderChangeHandler}
          max={Math.round(maxPossibleAutarky)}
          value={Math.round(autarky)}
        />
      </ParameterStyles.Input>
      <ParameterStyles.Value hasSlider>
        <ParameterStyles.ValueWrapper style={{ marginRight: "0px" }}>
          <MasterInput value={Math.round(autarky)} onChange={inputBoxChangeHandler} label="%" />
        </ParameterStyles.ValueWrapper>
      </ParameterStyles.Value>
    </ParameterStyles.Line>
  );
};

export default HouseholdSupplyInputLine;
