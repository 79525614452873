import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        paddingLeft: 30,
        paddingRight: 30
    },
    description: {
      border: 0.76,
      borderColor: colors.greyLight,
      borderRadius: 5,
      padding: 20,
      height: "20%"
    },
    images: {
      height: "60%",
      border: 0.76,
      borderRadius: 5,
      borderColor: colors.greyLight,
    },
    img: {
      height: 150,
      padding: 5,
      width: 150
    },
    imagesContainer: {
      display: "flex",
      flexDirection:"row",
      flexWrap: "wrap",
      justifyContent: "center"
    }
})

export default styles;