import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useNumberFormat } from "src/hooks";

import chartColors from "../../chartColors";
import { setHoverHelper } from "../../setHoverLogicHelper";
import { hoverBarsKey } from "../hover.reducer";

import {
  Bar,
  ChartArea,
  ChartWrapper,
  Circle,
  CustomTooltip,
  Divider,
  Region,
  RegionLabel,
} from "./chart.styles";

import { ProjectSelectors } from "src/redux/selectors";

export interface ReturnOnInvestCalculations {
  buildingCost: number;
  financingCost: number;
  feedInTariff: number;
  mobilitySavings: number;
  gasSavings: number;
  EVGridCost: number;
  heatpumpGridCost: number;
  cars: Array<{ name: string; cost: number }>;
  totalCarsCost: number;
  profit: number;
  loss: number;
  savingsElectricityBill: number;
}

interface ChartProps {
  calculations: ReturnOnInvestCalculations;
  hoverState: any;
  hoverDispatch: any;
  isRenting: boolean;
}
const Chart: React.FC<ChartProps> = ({
  calculations,
  hoverDispatch,
  hoverState,
  isRenting = false,
}: ChartProps) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const [barHeights, setBarHeights] = useState<Record<string, number>>({});
  const { isFinancing } = useSelector(ProjectSelectors.getFinancialDetails);

  const getPercentage = (part: number, total: number): number => {
    const fraction = part / total;
    if (isNaN(fraction)) return 0;
    return fraction * 100;
  };

  const setHover = (event: any, hoverKey: string): void => {
    setHoverHelper(hoverKey, hoverDispatch);
  };

  const shouldLighten = (hoverKey: string): boolean => {
    const currBarIsHovered = hoverKey === hoverState.hoveredBar;
    if (currBarIsHovered) return false;

    const isAnyBarHovered = hoverState.hoveredBar !== "";
    if (isAnyBarHovered) return true;
    else return false;
  };

  const shouldShowTooltip = (hoverKey: string): boolean => {
    return hoverState.tooltip && hoverState.hoveredBar === hoverKey;
  };

  useEffect(() => {
    const c = calculations;
    if (!c) return;

    const heights: any = {};

    let negativeCosts = c.EVGridCost + c.heatpumpGridCost + c.totalCarsCost + c.buildingCost;

    if (isFinancing) {
      negativeCosts += c.financingCost;
    }

    const positiveGains =
      c.feedInTariff + c.savingsElectricityBill + c.mobilitySavings + c.gasSavings;

    const profit = Math.abs(positiveGains - negativeCosts);
    const loss = c.savingsElectricityBill;
    const total = profit + loss;

    let maxBar = total;

    maxBar = Math.max(positiveGains, negativeCosts, profit, loss);
    maxBar = maxBar * 1.35; // Add 35% more of space to the bar so that chart doens't touch top or bottom ceiling

    heights.profit = getPercentage(profit, maxBar);
    heights.loss = getPercentage(loss, maxBar);

    heights.feedInTariff = getPercentage(c.feedInTariff, profit);
    heights.savingsElectricityBill = getPercentage(c.savingsElectricityBill, profit);
    heights.mobilitySavings = getPercentage(c.mobilitySavings, profit);
    heights.gasSavings = getPercentage(c.gasSavings, profit);

    heights.EVGridCost = getPercentage(c.EVGridCost, profit);
    heights.heatpumpGridCost = getPercentage(c.heatpumpGridCost, profit);
    heights.buildingCost = getPercentage(c.buildingCost, profit);
    heights.financingCost = getPercentage(c.financingCost, profit);
    heights.totalCarsCost = getPercentage(c.totalCarsCost, profit);

    setBarHeights(heights);
  }, [calculations, isFinancing]);

  const getNumericalText = (numeral: number): string => {
    const txt = formatNumber(numeral);
    if (numeral < 0) return txt;
    else return "+" + txt;
  };

  const getTitle = (numeral: string, text: string): any => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span>{numeral} €</span>
        <span>{text}</span>
      </div>
    );
  };

  return (
    <ChartWrapper>
      <Region>
        <RegionLabel verticalAlign="bottom" style={{ marginLeft: "40px" }}>
          <Circle backgroundColor={chartColors.PROFIT}></Circle>{" "}
          {calculations.profit < 0 ? t("COST NEW SYSTEM") : t("WITH SOLAR SYSTEM")}
        </RegionLabel>
        <ChartArea hovered={hoverState.showCalculation} verticalAlign="bottom">
          <CustomTooltip
            title={getTitle(
              getNumericalText(calculations?.profit),
              t(calculations?.profit < 0 ? "COST NEW SYSTEM" : "PROFIT WITH SOLAR SYSTEM"),
            )}
            arrow
            open={shouldShowTooltip(hoverBarsKey.PROFIT_HOVER)}
          >
            <Bar
              color={chartColors.PROFIT}
              style={{
                height: `${barHeights.profit}%`,
                transform: calculations?.profit < 0 ? "translateY(calc(100% + 3px))" : "initial",
              }}
              barWidthsToLeave={0.5}
              labelAlign={"top"}
              isHoverActive={hoverState.showCalculation}
              onMouseEnter={(event) => setHover(event, hoverBarsKey.PROFIT_HOVER)}
              onMouseLeave={(event) => setHover(event, "")}
              lighten={shouldLighten(hoverBarsKey.PROFIT_HOVER)}
              needNegativeBarsAlignAdjust={calculations?.profit < 0}
            >
              <div className="top-border"></div>
              <div className="bar-text">{getNumericalText(calculations?.profit)} €</div>

              <div
                className="positive-bars"
                style={{
                  display: hoverState.showCalculation ? "block" : "none",
                }}
              >
                <CustomTooltip
                  title={getTitle(
                    getNumericalText(calculations?.feedInTariff),
                    t("FEED IN TARIFF"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.FEED_IN_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.FEED_IN}
                    style={{ height: `${barHeights.feedInTariff}%` }}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.FEED_IN_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.FEED_IN_HOVER)}
                  ></Bar>
                </CustomTooltip>
                <CustomTooltip
                  title={getTitle(
                    getNumericalText(calculations?.savingsElectricityBill),
                    t("SAVING IN BILL"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.SAVINGS_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.SAVINGS}
                    style={{ height: `${barHeights.savingsElectricityBill}%` }}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.SAVINGS_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.SAVINGS_HOVER)}
                  ></Bar>
                </CustomTooltip>
                <CustomTooltip
                  title={getTitle(
                    getNumericalText(calculations?.mobilitySavings),
                    t("Savings - mobility bill"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.MOBILITY_SAVINGS}
                    style={{ height: `${barHeights.mobilitySavings}%` }}
                    data-title={`${getNumericalText(calculations?.mobilitySavings)} € ${t(
                      "Savings - mobility bill",
                    )}`}
                    hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_SAVINGS_HOVER}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
                  ></Bar>
                </CustomTooltip>
                <CustomTooltip
                  title={getTitle(
                    getNumericalText(calculations?.gasSavings),
                    t("Savings - heat bill"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.HEAT_SAVINGS_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.GAS_SAVINGS}
                    style={{ height: `${barHeights.gasSavings}%` }}
                    hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_SAVINGS_HOVER}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.HEAT_SAVINGS_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.HEAT_SAVINGS_HOVER)}
                  ></Bar>
                </CustomTooltip>

                <div className="bottom-border-with-arrow"></div>
              </div>

              <div
                className="negative-bars"
                style={{
                  display: hoverState.showCalculation ? "block" : "none",
                }}
              >
                <CustomTooltip
                  title={getTitle(
                    getNumericalText(-calculations?.EVGridCost),
                    t("Grid electricity cost- EV"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.MOBILITY_GRID_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.MOBILITY_GRID}
                    style={{ height: `${barHeights.EVGridCost}%` }}
                    hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_GRID_HOVER}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.MOBILITY_GRID_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.MOBILITY_GRID_HOVER)}
                  ></Bar>
                </CustomTooltip>

                <CustomTooltip
                  title={getTitle(
                    getNumericalText(-calculations?.heatpumpGridCost),
                    t("Grid electricity cost- heat pump"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.HEAT_GRID_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.HEAT_GRID}
                    style={{ height: `${barHeights.heatpumpGridCost}%` }}
                    data-title={`${getNumericalText(-calculations?.heatpumpGridCost)} € ${t(
                      "Grid electricity cost- heat pump",
                    )}`}
                    hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_GRID_HOVER}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.HEAT_GRID_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.HEAT_GRID_HOVER)}
                  ></Bar>
                </CustomTooltip>
                <CustomTooltip
                  title={getTitle(getNumericalText(-calculations?.totalCarsCost), t("Cars Cost"))}
                  open={shouldShowTooltip(hoverBarsKey.CARS_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.CARS}
                    style={{ height: `${barHeights.totalCarsCost}%` }}
                    hovered={hoverState.hoveredBar === hoverBarsKey.CARS_HOVER}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.CARS_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.CARS_HOVER)}
                  ></Bar>
                </CustomTooltip>
                {isFinancing && calculations.financingCost && (
                  <CustomTooltip
                    title={getTitle(
                      getNumericalText(-calculations?.financingCost),
                      t("Financing cost"),
                    )}
                    open={shouldShowTooltip(hoverBarsKey.FINANCING_COST_HOVER)}
                    arrow
                  >
                    <Bar
                      color={chartColors.FINANCING_COST}
                      style={{ height: `${barHeights.financingCost}%` }}
                      data-title={`${getNumericalText(-calculations?.financingCost)} € ${t(
                        "Financing cost",
                      )}`}
                      hovered={hoverState.hoveredBar === hoverBarsKey.FINANCING_COST_HOVER}
                      isHoverActive={hoverState.showCalculation}
                      onMouseEnter={(event) => setHover(event, hoverBarsKey.FINANCING_COST_HOVER)}
                      onMouseLeave={(event) => setHover(event, "")}
                      lighten={shouldLighten(hoverBarsKey.FINANCING_COST_HOVER)}
                    ></Bar>
                  </CustomTooltip>
                )}

                <CustomTooltip
                  title={getTitle(
                    getNumericalText(-calculations?.buildingCost),
                    !isRenting ? t("Investment / building cost") : t("Renting / rental cost"),
                  )}
                  open={shouldShowTooltip(hoverBarsKey.BUILDING_COST_HOVER)}
                  arrow
                >
                  <Bar
                    color={chartColors.BUILDING_COST}
                    style={{ height: `${barHeights.buildingCost}%` }}
                    data-title={`${getNumericalText(-calculations?.buildingCost)} € ${
                      !isRenting ? t("Investment / building cost") : t("Renting / rental cost")
                    }`}
                    hovered={hoverState.hoveredBar === hoverBarsKey.BUILDING_COST_HOVER}
                    isHoverActive={hoverState.showCalculation}
                    onMouseEnter={(event) => setHover(event, hoverBarsKey.BUILDING_COST_HOVER)}
                    onMouseLeave={(event) => setHover(event, "")}
                    lighten={shouldLighten(hoverBarsKey.BUILDING_COST_HOVER)}
                  ></Bar>
                </CustomTooltip>
              </div>
            </Bar>
          </CustomTooltip>
        </ChartArea>
      </Region>
      <Divider></Divider>
      <Region>
        <RegionLabel verticalAlign="top">
          <Circle backgroundColor={chartColors.LOSS}></Circle> {t("WITHOUT SOLAR SYSTEM")}
        </RegionLabel>
        <ChartArea hovered={hoverState.showCalculation} verticalAlign="top">
          <CustomTooltip
            title={getTitle(getNumericalText(-calculations?.loss), t("Loss"))}
            open={shouldShowTooltip(hoverBarsKey.LOSS_HOVER)}
            arrow
          >
            <Bar
              color={chartColors.LOSS}
              style={{ height: `${barHeights.loss}%` }}
              barWidthsToLeave={-0.5}
              labelAlign="bottom"
              hovered={hoverState.hoveredBar === hoverBarsKey.LOSS_HOVER}
              onMouseEnter={(event) => setHover(event, hoverBarsKey.LOSS_HOVER)}
              onMouseLeave={(event) => setHover(event, "")}
              lighten={shouldLighten(hoverBarsKey.LOSS_HOVER)}
            >
              <div className="bar-text">
                {`${getNumericalText(-calculations?.savingsElectricityBill)} €`}
              </div>
            </Bar>
          </CustomTooltip>
        </ChartArea>
      </Region>
    </ChartWrapper>
  );
};

export default Chart;
