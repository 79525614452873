import { RentingData } from "src/api";
import { InstallerDB, LastLogDB } from "src/db-types";

import { ReduxAction } from "src/redux/common.types";
import { actionNames, LanguagesShortCodes, MainUserState, UserAuthStatus } from "src/redux/user";

export const setUser = (payload: MainUserState | null): ReduxAction<MainUserState | null> => ({
  type: actionNames.SET_USER,
  payload,
});

export const setInstaller = (payload: InstallerDB | null): ReduxAction<InstallerDB | null> => ({
  type: actionNames.SET_INSTALLER,
  payload,
});

export const setSimplify = (payload: Boolean): ReduxAction<Boolean> => ({
  type: actionNames.SET_SIMPLIFY,
  payload,
});

export const setRantingData = (payload: RentingData | null): ReduxAction<RentingData | null> => ({
  type: actionNames.SET_RENTING_DATA,
  payload,
});

export const setIsAuthLoading = (flag: boolean): ReduxAction<boolean> => ({
  type: actionNames.SET_USER_AUTH_LOADING,
  payload: flag,
});

export const setLanguage = (
  languageShortCode: LanguagesShortCodes,
): ReduxAction<LanguagesShortCodes> => ({
  type: actionNames.SET_LANGUAGE,
  payload: languageShortCode,
});

export const updateLastLog = (data: LastLogDB): ReduxAction<LastLogDB> => ({
  type: actionNames.UPDATE_LAST_LOG,
  payload: data,
});

export const setUserAuthStatus = (data: UserAuthStatus): ReduxAction<UserAuthStatus> => ({
  type: actionNames.USER_AUTH_STATUS,
  payload: data,
});
