import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    energyFlowContainer: { width: "100%", height: "100%"},
    mainContainer: {
        display: "flex",
        paddingLeft: 25,
        paddingRight: 25,
        position: "relative"
    },
    flowContainer: {
        position: "relative"
    },
    box: {
        backgroundColor: "#FFE79E",
        display: "flex",
        flexDirection: "column",
        padding: 15,
        position: "absolute",
        top: 0,
        right: 0,
        width: 180,
        borderRadius: 5
    },
    box_down: {
        backgroundColor: "#FFE79E",
        display: "flex",
        flexDirection: "column",
        padding: 15,
        marginTop: 347,
        borderRadius: 5
    },
    img: {
     width: 330,
     height: 200,
     position: "absolute",
     top: 70,
     left: 90
    },
    textContainer: {
        display: "flex", 
        flexDirection: "column"
    }
})
export default styles;
