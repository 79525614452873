import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Text, View } from "@react-pdf/renderer";

import PageHeading from "../../components/pageHeading/pageHeading";
import commanStyles from "../../comanStyles/commanStyles";
import styles from "./ProductDetailsStyles";
import Product from "../../components/ProductList/Product";
import Footer from "../../components/Footer/Footer";

import { getCheapestProduct } from "src/helpers";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { useIsComponentAdded } from "src/hooks";

const ProductDetailsSecoundPage = () => {
  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const { isHeatpumpAdded, isWallboxAdded } =
    useIsComponentAdded();

  const cheapestwallboxes = useMemo(() => {
    return getCheapestProduct(filtered.wallboxes);
  }, [filtered]);

  const cheapestheatpumps = useMemo(() => {
    return getCheapestProduct(filtered.heatpumps);
  }, [filtered]);


  const wallbox = useSelector(ProjectSelectors.getWallbox);
  const heatpump = useSelector(ProjectSelectors.getHeatpump);
  const unfilteredProductsReduxWallboxes = useSelector(ProductSelectors.getWallboxes);
  const unfilteredProductsReduxHeatPumps = useSelector(ProductSelectors.getHeatpumps);

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <PageHeading heading="Dies ist eine erste Konfigurationsoption" titleWidth={330} />
      <View style={styles.mainContainer}>
        <Text style={commanStyles.static_text_small}>
          Die tatsächlichen Komponenten werden nach Verfügbarkeit vom Installateur ausgewählt
        </Text>
        <View
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          {isWallboxAdded && cheapestwallboxes && (
            <Product
              project={cheapestwallboxes}
              name={"Wallbox"}
              isB2B={false}
              backColor="#FFBF1B"
              isEnglish={true}
              flag={true}
              preferences={wallbox.preferences}
              unfilteredProductsRedux={unfilteredProductsReduxWallboxes}
            />
          )}
          {isHeatpumpAdded && cheapestheatpumps && (
            <Product
              project={cheapestheatpumps}
              name={"Wärmepumpe"}
              isB2B={false}
              backColor="#DF6569"
              isEnglish={true}
              preferences={heatpump.preferences}
              unfilteredProductsRedux={unfilteredProductsReduxHeatPumps}
              flag={true}
            />
          )}
        </View>
      </View>
      <Footer pageNumber={5} />
    </View>
  );
};

export default ProductDetailsSecoundPage;
