import { Tooltip } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useNumberFormat } from "src/hooks";

import { parseLocaleNumber } from "src/utils";

import * as S from "./MasterInput.styles";

import { selectors as userSelectors } from "src/redux/user";

interface MasterInputProps {
  value: number;
  maxNumber?: number;
  roundTill?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
  onClick?: React.MouseEventHandler<Element>;
  label?: string;
  appearance?: {
    color?: string;
    bgcolor?: string;
    cursor?: string;
    styles?: React.CSSProperties;
  };
  fullWidth?: boolean;
  fitContent?: boolean;
}

const MasterInput: React.FC<MasterInputProps> = (props: MasterInputProps) => {
  const { value, onChange, onClick, disabled, label, appearance, maxNumber, roundTill, fullWidth, fitContent } = props;
  const { bgcolor, color, cursor, styles } = appearance ?? {};

  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const langCode = useSelector(userSelectors.getLangCode);

  const [inputValue, setInputValue] = useState(formatNumber(value));

  useEffect(() => {
    setInputValue(formatNumber(value, { roundTill }));
  }, [formatNumber, roundTill, value]);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const eventInpValue: any = e.target.value;
      // const parsedInputValue = parseLocaleNumber(eventInpValue, langCode);
      const parsedInputValue = eventInpValue;

      // basic non-number validation
      const hasNumberRegex = /\d/;
      const doesNotHaveNumber = !hasNumberRegex.test(e.target.value);
      const inputIsNotEmpty = e.target.value !== "";
      if (inputIsNotEmpty && doesNotHaveNumber) {
        return toast.warning(t("String is not allowed please enter a number"));
      }

      // max-number validation
      const isGreaterThanMaxNumber = maxNumber && parsedInputValue > maxNumber;
      if (isGreaterThanMaxNumber) {
        const formattedMaxNumber = formatNumber(maxNumber);
        toast.warning(
          t("The maximum value you can set is {{ maxNumber }}", {
            maxNumber: formattedMaxNumber,
          }),
        );
        setInputValue(formattedMaxNumber);
        if (onChange) {
          onChange(maxNumber);
        }
        return;
      }

      setInputValue(eventInpValue.replace(/[^\d,.]/g, ""));

      // const shouldCallOnChange = onChange && !isNaN(parsedInputValue);
      // if (shouldCallOnChange) {
      //   onChange(parsedInputValue);
      // }
    },
    [formatNumber, maxNumber, onChange, t],
  );

  const containerStyles = fitContent ? {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    minWidth: "50px"
  } : {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    minWidth: "50px"
  }

  return (
    <S.MasterInputContainer color={color} cursor={cursor} bgcolor={bgcolor} style={containerStyles}>
      <div className="same_for_all" onClick={onClick ?? (() => { })} style={{ ...styles, width: fullWidth ? "100%" : "fit-content", minWidth: "50px" }}>
        <Tooltip title={value}>
          <input
            size={Math.max(1, inputValue.length - 1)}
            disabled={disabled}
            value={inputValue}
            onInput={onChangeHandler}
            onBlur={() => {
              const num = parseLocaleNumber(inputValue, langCode);

              setInputValue(formatNumber(num, { roundTill }));

              if (num !== value) {
                if (onChange) onChange(num);
              }

              // if (isNaN(subValue)) {
              //   setValue(formatNumber(onBulrValue));
              //   dispatchHelperFucntion(onBulrValue);
              // } else if (subValue === null) {
              //   setValue(formatNumber(onBulrValue));
              //   dispatchHelperFucntion(onBulrValue);
              // }
            }}
          />
        </Tooltip>
        <div className="percentage">{label}</div>
      </div>
    </S.MasterInputContainer>
  );
};

MasterInput.defaultProps = {
  appearance: {},
  roundTill: 2,
};

export default MasterInput;