import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "src/redux/project/selectors";
import axios from "axios";
import { ProjectActions } from "src/redux/actionCreators";
import { updateBakeModelProgress } from "src/redux/project/action/action.creators";

const useFetchArtifacts = () => {
  const project = useSelector(getProject);
  const [progress, setProgress] = useState(project.trackProgress.percentage || 0);
  const dispatch = useDispatch();
  
  const formatTime = (minutes: number, seconds: number) => {
    return `~ ${minutes}`;
  };

  useEffect(() => {
    if (!project.bakeModel.id) return;

    if (project?.bakeModel?.artifacts?.length) return;

    let intervalId: NodeJS.Timeout;

    const fetchArtifactsInterval = 30000; // 30 seconds
    const maxTime = 3000000; // 50 minutes in milliseconds
    let elapsed = project.trackProgress.elapsed || 0;

    const fetchArtifacts = async () => {
      try {
        const result = await axios.get(
          `https://tufhsl1q81.execute-api.eu-central-1.amazonaws.com/bakeModel?bakedModelId=${project.bakeModel.id}`,
        );

        if (result && result.data?.status === "pending") {
          setProgress((elapsed / maxTime) * 100);
        } else if (result && result.data?.assetUrls?.length) {
          setProgress(100);
          localStorage.setItem("progress", "0");
          localStorage.setItem("returnTime", "");
          const assetsUrls = result?.data?.assetUrls?.map((s3Url: string) => {
            const [, ,bucket, ...objectKeyParts] = s3Url.split("/");
            const objectKey = objectKeyParts.join("/");
            const httpsUrl = `https://${bucket.replace(".s3.", "")}.s3.eu-central-1.amazonaws.com/${objectKey}`;
            return httpsUrl;
          });
          dispatch(
            ProjectActions.updateBakeModelId({ id: project.bakeModel.id, artifacts: assetsUrls }),
          );
          dispatch(updateBakeModelProgress({percentage: 0, time: "", elapsed: 0}))
        }

        const remainingTime = maxTime - elapsed;
        const remainingMinutes = Math.floor(remainingTime / 60000);
        const remainingSeconds = Math.floor((remainingTime % 60000) / 1000);

        elapsed += fetchArtifactsInterval;
        dispatch(updateBakeModelProgress({percentage: (elapsed / maxTime) * 100, elapsed: elapsed, time: formatTime(remainingMinutes, remainingSeconds)}))

        if (elapsed >= maxTime) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching artifacts:", error);
      }
    };

    void fetchArtifacts();

    intervalId = setInterval(() => {
      void fetchArtifacts();
    }, fetchArtifactsInterval);

    return () => clearInterval(intervalId);
  }, [project.bakeModel]);


  return { progress };
};

export default useFetchArtifacts;
