import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { getFormattedDate } from "../../Utils/helperMethods";
import { getProject } from "src/redux/project/selectors";
import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 40,
    backgroundColor: "#2D4764",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    color: "#ffffff",
    fontSize: 10,
    borderTopRightRadius: 1000
  }
});

const Footer = ({ pageNumber }) => {
  const project = useSelector(getProject);

  return (
    <View style={styles.footer}>
      <Text>#{`${project?.shortId}-${project?.version}`}</Text>
      <Text>{getFormattedDate()}</Text>
      <Text>Seite {pageNumber}</Text>
    </View>
  );
};

Footer.defaultProps = {
  pageNumber: 1
};

export default Footer;
