import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DownArrow, UpArrow } from "src/assets/svgs";
import * as S from "./styles";

import { getRentingData } from "src/redux/user/selectors";
import { useSelector } from "react-redux";
import ReturnOnInvest from "../ReturnOnInvest/ReturnOnInvest";
import MonthlyElectricityCost from "../MonthlyChart/MonthlyElectricityCost";
import { ProjectSelectors } from "src/redux/selectors";
import { formatNumberByLang } from "src/hooks";
import { FinanceDropdown, FinanceTypeLiteral } from "../FinanceDropdown/FinanceDropdown";
import useGetRentingOffer from "src/hooks/useGetRentingOffer";
import { OfferType } from "src/db-types";

interface IRent {
  dropDownType: FinanceTypeLiteral;
  setDropDownType: (value: FinanceTypeLiteral) => void;
}

export const Rent: React.FC<IRent> = ({ dropDownType, setDropDownType }: IRent) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [openCalculation, setOpenCalculation] = useState(false);
  const { t } = useTranslation();
  const rentingData = useSelector(getRentingData);
  const {
    costs: { totalProjectCost },
  } = useSelector(ProjectSelectors.getFinancialDetails);
  const { handleGetOffer, isPdfLoading, isOfferLoading } = useGetRentingOffer();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const consumptionDetailsStyles: any = makeStyles(() => ({
    arrow: {
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        background: "#dfdfdf",
      },
    },
  }));
  const classes = consumptionDetailsStyles();

  return (
    <>
      <S.RentDetails>
        <div>
          {loading ? (
            <S.StyledLoaderButton>
              <CircularProgress size={25} className="circular-progress-custom" />
            </S.StyledLoaderButton>
          ) : rentingData?.rate_with_vat ? (
            <S.StyledSecondaryButton>
              {formatNumberByLang(String(rentingData.rate_with_vat.toFixed(0)))}€
            </S.StyledSecondaryButton>
          ) : (
            <S.StyledSecondaryButton>-</S.StyledSecondaryButton>
          )}
          <div className="heading">{t("Expected monthly rent")}</div>
          <div className="heading">
            {t("at a system price of")}{" "}
            <span>{formatNumberByLang(totalProjectCost?.toFixed(0))}€</span>
          </div>
        </div>
        <S.StyledButtons>
          <S.StyledButton onClick={() => handleGetOffer({ type: OfferType.REDIRECT })}>
            {isOfferLoading && <CircularProgress size={20} color="success" />}
            {t("Request rental")}
          </S.StyledButton>
          <S.StyledButton onClick={() => handleGetOffer({ type: OfferType.PDF })}>
            {isPdfLoading && <CircularProgress size={20} color="success" />}
            {t("Rental PDF")}
          </S.StyledButton>
        </S.StyledButtons>
        <div>
          {t("Rental period:")} 20 {t("years")} <br /> {t("Free purchase option during the term")}
        </div>
      </S.RentDetails>
      <Grid
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        height="48px"
        paddingX="17px"
        marginTop="5px"
        paddingBottom="5px"
        className={classes.container}
        onClick={() => {
          setOpenDetails(!openDetails);
        }}
      >
        <S.Text>{t("Details about renting option")}</S.Text>

        <Grid
          onClick={() => {
            setOpenDetails(!openDetails);
          }}
          className={classes.arrow}
        >
          {openDetails ? <UpArrow /> : <DownArrow />}
        </Grid>
      </Grid>

      {openDetails && (
        <S.StyledRentingOption>
          <li>{t("Service und Wartung inklusive")}</li>
          <li>{t("Anlage vollständig versichert")}</li>
          <li>{t("Sofortkauf jederzeit möglich")}</li>
          <li>{t("Eigentümer nach Ende der Miete")}</li>
        </S.StyledRentingOption>
      )}

      <Grid
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        height="48px"
        paddingX="17px"
        marginTop="5px"
        paddingBottom="5px"
        className={classes.container}
        onClick={() => {
          setOpenCalculation(!openCalculation);
        }}
      >
        <S.Text>{t("Detailed Calculations")}</S.Text>

        <Grid
          onClick={() => {
            setOpenCalculation(!openCalculation);
          }}
          className={classes.arrow}
        >
          {openCalculation ? <UpArrow /> : <DownArrow />}
        </Grid>
      </Grid>
      {openCalculation && (
        <FinanceDropdown setDropdownType={setDropDownType} dropDownType={dropDownType} />
      )}
      {openCalculation &&
        (dropDownType === "return-on-investment" ? (
          <ReturnOnInvest isRenting />
        ) : (
          <MonthlyElectricityCost isRenting />
        ))}
    </>
  );
};
