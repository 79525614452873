import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Image, Text, View } from "@react-pdf/renderer";

import { formatNumberByLang } from "../../../hooks/useNumberFormat";
import HeatPump from "./images/Heatpump.png";
import Emobility from "./images/Emobility.png";
import HeatpumpEmobility from "./images/HeatpumpEmobility.png";
import SolarOnly from "./images/SolarOnly.png";
import sol from "./images/sol.png";
import { getEnergy, getModel } from "../../../redux/project/selectors";
import styles from "./HomeEnergyStyles";
import { useIsComponentAdded } from "src/hooks";
import { useTranslation } from "react-i18next";

const HomeEnergy = () => {
  const model = useSelector(getModel);
  const { solarPanelProduction } = useSelector(getEnergy);
  const { isHeatpumpAdded, isWallboxAdded } = useIsComponentAdded();
  const { t } = useTranslation();

  const rightImage = useMemo(() => {
    if (isHeatpumpAdded && isWallboxAdded) return HeatpumpEmobility;
    if (!isHeatpumpAdded && !isWallboxAdded) return SolarOnly;
    if (isHeatpumpAdded && !isWallboxAdded) return HeatPump;
    if (isWallboxAdded && !isHeatpumpAdded) return Emobility;
  }, [isHeatpumpAdded, isWallboxAdded]);

  return (
    <View style={styles.home_wrapper}>
      <View style={styles.make_in_same_line}>
        <View style={styles.info_text}>
          <Text style={styles.upper}>{formatNumberByLang(Math.round(model.consumption).toFixed(0), "de")} kWh</Text>
          <Text style={styles.lower}>{t("Total consumption")}</Text>
        </View>
        <View style={styles.info_text_sub}>
        <View style={{display: "flex", flexDirection: "row", gap: 10}}>
         <Image src={sol} />
         <View style={{ display: 'flex' , flexDirection: "column"}}>
          <Text style={styles.upper_yellow}>
            {formatNumberByLang(Math.round(solarPanelProduction).toFixed(0), "de")} kWh
          </Text>
          <Text style={styles.lower}>{t("Generation")}</Text>
         </View>
        </View>
        </View>
      </View>
      <View style={styles.flex_row}>
        <View style={styles.wra}>
          <View style={styles.info_text22}>
            <View style={styles.upper_dark_yellow}>
              <Text>{formatNumberByLang(model.household.consumption.toFixed(0))} kWh</Text>
            </View>
            <Text style={styles.lower}>{t("Household consumption")}</Text>
          </View>
          <View style={styles.info_text3}>
            <View style={styles.upper_green_yellow}>
              <Text>
                {isWallboxAdded ? formatNumberByLang(model.ev.consumption.toFixed(0)) : ""}{" "}
                {isWallboxAdded ? "kWh" : ""}
              </Text>
            </View>
            <Text style={styles.lower}>{isWallboxAdded ? t("E-mobility consumption") : ""}</Text>
          </View>
          <View style={styles.info_text2}>
            <View style={styles.upper_red_dark}>
              <Text>
                {isHeatpumpAdded
                  ? `${formatNumberByLang(model.heatpump.consumption.toFixed(0))} kWh`
                  : ""}
              </Text>
            </View>
            <Text style={styles.lower}>{isHeatpumpAdded ? t("Heatpump consumption") : ""}</Text>
          </View>
        </View>
        <View style={styles.relative_wrapper}>
          <Image src={rightImage} alt="" style={styles.img} />
          <View style={{...styles.info_box, bottom: (!isWallboxAdded && !isHeatpumpAdded) ? -17 : -3}}>
            <View style={styles.info_text_point}>
              <Text style={styles.upper_red}>
                {formatNumberByLang(model.fromGrid.toFixed(0))} kWh
              </Text>
              <Text style={styles.lower}>{t("Grid supply")}</Text>
            </View>
            <View style={styles.info_text_point_upper}>
              <Text style={styles.upper_green}>
                {formatNumberByLang(model.feedIn.toFixed(0))} kWh
              </Text>
              <Text style={styles.lower}>{t("Feed in")}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default HomeEnergy;
