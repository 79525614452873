import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { shareProjectWithCustomer } from "src/api/backend-endpoints";
import { SolarImage } from "src/assets/images";
import { LongLogo } from "src/assets/svgs";
import { ApiAxiosClient } from "src/axios";
import { PopupSkeleton } from "src/shared/components";

import { InstallerPartner } from "../WelcomePopupForNoAddress/components/MiddlePath/MiddlePath.styles";

import cssClasses from "./savedProjectUserDetail.module.css";

import { ProjectActions } from "src/redux/actionCreators";
import {
  selectors as PopupSelectors,
  actionCreators as PopupActions,
  POPUP_KEYS,
} from "src/redux/popups";
import { UserInfo } from "src/redux/project/types/project-state";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { getInstaller } from "src/redux/user/selectors";
import MarkdownEditor from "@uiw/react-markdown-editor";
import rehypeSanitize from "rehype-sanitize";
import { marked } from "marked";

interface ISendofferPopupContentProps {
  setShowSendMailPopup: (arg: boolean) => void;
  handleShareProject: (e: any) => void;
  emailContent: string;
  setEmailContent: (arg: string) => void;
  sendPdfWithEmail: boolean;
  setSendPdfWithEmail: (arg: boolean) => void;
}

interface IMdxEditorProps {
  setEmailContent: (arg: string) => void;
}

const MdxEditor: React.FC<IMdxEditorProps> = ({ setEmailContent }) => {
  const [markdown, setMarkdown] = useState("");

  const handleSetEmailContent = useCallback(async () => {
    const emailContent = await marked(markdown);
    setEmailContent(emailContent.replaceAll("<p>", "<p style='margin: 10px 0;'>"));
  }, [markdown, setEmailContent]);

  useEffect(() => {
    handleSetEmailContent();
  }, [markdown]);

  return (
    <div className="w-[70vw]" data-color-mode="light">
      <MarkdownEditor
        value={markdown}
        height="200px"
        onChange={(value) => setMarkdown(value)}
        toolbars={[
          "undo",
          "redo",
          "bold",
          "italic",
          "underline",
          "strike",
          "header",
          "link",
          "todo",
        ]}
        previewProps={{
          rehypePlugins: [[rehypeSanitize]],
        }}
        visible
        indentWithTab
        enablePreview
      />
    </div>
  );
};

const SendOfferPopupContent: React.FC<ISendofferPopupContentProps> = ({
  setShowSendMailPopup,
  handleShareProject,
  emailContent,
  setEmailContent,
  sendPdfWithEmail,
  setSendPdfWithEmail,
}) => {
  const { t } = useTranslation();
  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);

  const onCancelClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSendMailPopup(false);
  }, []);

  const handleCheckboxChange = useCallback(
    (e, checked) => {
      e.preventDefault();
      e.stopPropagation();
      setSendPdfWithEmail(checked);
    },
    [setSendPdfWithEmail],
  );

  return (
    <div className={cssClasses.headerOuterContainer}>
      <div className={cssClasses.headerInnerContainer}>
        <LongLogo />
        <div className={cssClasses.headerTitle}>Send the offer to your customer per mail</div>
        <div className={cssClasses.formSubtitle}>{completeAddress}</div>
        <MdxEditor setEmailContent={setEmailContent} />
        <FormControlLabel
          control={
            <Checkbox
              checked={sendPdfWithEmail}
              onChange={handleCheckboxChange}
              defaultChecked
              disableFocusRipple
              disableRipple
              disableTouchRipple
            />
          }
          label={<div className={cssClasses.checkboxText}>Add PDF to E-Mail</div>}
        />
        <div className={cssClasses.buttonContainer}>
          <button className={cssClasses.primaryButton} onClick={handleShareProject}>
            {t("Send to customer")}
          </button>
          <button className={cssClasses.secondaryButton} onClick={onCancelClick}>
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

const SavedProjectUserDetailPopup: React.FC = () => {
  const [showSendMailPopup, setShowSendMailPopup] = useState(false);
  const [sendPdfWithEmail, setSendPdfWithEmail] = useState(false);
  const [emailContent, setEmailContent] = useState("");

  useEffect(() => {
    if (!setShowSendMailPopup) {
      setEmailContent("");
      setSendPdfWithEmail(false);
    }
  }, [showSendMailPopup]);

  const { t } = useTranslation();
  const disptach = useDispatch();
  const instalelrParnter = useSelector(getInstaller);

  const [formData, setFormData] = useState<UserInfo>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const completeAddress = useSelector(ProjectSelectors.getCompleteAddress);
  const user = useSelector(UserSelectors.getUser);
  const projectUserInfo = useSelector(ProjectSelectors.getUserInfo);

  const onCloseClick = useCallback(() => {
    disptach(PopupActions.closePopup(POPUP_KEYS.saved_project_user_info));
  }, [disptach]);

  useEffect(() => {
    if (projectUserInfo) {
      setFormData({
        firstName: projectUserInfo.firstName,
        lastName: projectUserInfo.lastName,
        email: projectUserInfo.email,
        phoneNumber: projectUserInfo.phoneNumber,
      });
    } else if (user) {
      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
  }, [user, projectUserInfo]);

  const handleUpdateFormData = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [],
  );

  const handleSave = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      disptach(ProjectActions.addUserInfo(formData));
      toast.success("User info saved successfully");
      onCloseClick();
    },
    [formData, disptach, onCloseClick],
  );

  const handleShareProject = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        disptach(ProjectActions.addUserInfo(formData));
        const payload = {
          name: `${formData.firstName} ${formData.lastName}`,
          projectLink: location.href,
          address: completeAddress,
          email: formData.email,
          iid: instalelrParnter?._id,
          emailContent: emailContent,
          // sendPdfWithEmail: sendPdfWithEmail,
          // img: "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/quote/66bef550788a0c0c78f5a594/image%20(4).png",
        };
        if (!instalelrParnter?._id) {
          delete payload.iid;
        }
        await ApiAxiosClient.post(shareProjectWithCustomer.url, payload);
        toast.success(t("The project details have been successfully sent to the user."));
      } catch (error) {
        toast.error(t("An error occurred while sending the project details to the user."));
      }
      onCloseClick();
    },
    [
      completeAddress,
      disptach,
      formData,
      instalelrParnter?._id,
      onCloseClick,
      t,
      emailContent,
      sendPdfWithEmail,
    ],
  );

  const handleOpenSendMailPopup = useCallback(() => {
    setShowSendMailPopup(true);
  }, []);

  return (
    <PopupSkeleton
      handleClose={onCloseClick}
      open={true}
      styless={{ height: "100%", maxHeight: "fit-content" }}
      styleToDialog={{ zIndex: 2000 }}
    >
      <div className={cssClasses.popupContainer}>
        {showSendMailPopup ? (
          <SendOfferPopupContent
            setShowSendMailPopup={setShowSendMailPopup}
            handleShareProject={handleShareProject}
            emailContent={emailContent}
            setEmailContent={setEmailContent}
            sendPdfWithEmail={sendPdfWithEmail}
            setSendPdfWithEmail={setSendPdfWithEmail}
          />
        ) : (
          <>
            <div className={cssClasses.popupImage}>
              <img src={SolarImage} />
            </div>
            <div className={cssClasses.popupFormContainer}>
              <div className={cssClasses.formHeader}>
                <LongLogo />
              </div>
              <div className={cssClasses.formTitle}>{t("Contact details project")}</div>
              <div className={cssClasses.formSubtitle}>{completeAddress}</div>

              <div className={cssClasses.formContainer}>
                <TextField
                  fullWidth
                  name="firstName"
                  id="firstname-form-field"
                  label={t("First Name")}
                  variant="outlined"
                  size="small"
                  value={formData.firstName}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="lastName"
                  id="lastname-form-field"
                  label={t("Last Name")}
                  variant="outlined"
                  size="small"
                  value={formData.lastName}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="email"
                  id="email-form-field"
                  label={t("E-Mail")}
                  variant="outlined"
                  size="small"
                  value={formData.email}
                  onChange={handleUpdateFormData}
                />
                <TextField
                  fullWidth
                  name="phoneNumber"
                  id="phone-form-field"
                  label={t("Phone")}
                  variant="outlined"
                  size="small"
                  value={formData.phoneNumber}
                  onChange={handleUpdateFormData}
                />
                <div className={cssClasses.buttonContainer}>
                  <button className={cssClasses.primaryButton} onClick={handleSave}>
                    {t("Save")}
                  </button>
                  <button className={cssClasses.secondaryButton} onClick={handleOpenSendMailPopup}>
                    {t("Send project details")}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </PopupSkeleton>
  );
};

export default SavedProjectUserDetailPopup;
