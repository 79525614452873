import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useIsComponentAdded, useNumberFormat } from "src/hooks";

import { getNumber } from "src/utils";

import chartColors from "../../chartColors";
import { setHoverHelper } from "../../setHoverLogicHelper";
import { hoverBarsKey } from "../hover.reducer";
import { Circle } from "../price-break-down-chart/chart.styles";

import {
  MainContainer,
  NegativeContainer,
  Number1,
  Number2,
  Number3,
  PositiveContainer,
  SubContainer,
  SubContainer1,
  Text1,
  Text2,
  Text3,
} from "./styles";

import { ProjectSelectors, UserSelectors } from "src/redux/selectors";

export interface ReturnOnInvestCalculations {
  buildingCost: number;
  financingCost: number;
  feedInTariff: number;
  mobilitySavings: number;
  gasSavings: number;
  EVGridCost: number;
  heatpumpGridCost: number;
  cars: Array<{ name: string; cost: number }>;
  totalCarsCost: number;
  profit: number;
  loss: number;
  savingsElectricityBill: number;
}

interface MainBodyProps {
  calculations: ReturnOnInvestCalculations;
  hoverState: any;
  hoverDispatch: any;
  isRenting: boolean;
}
const Calculations: React.FC<MainBodyProps> = ({
  hoverDispatch,
  hoverState,
  calculations,
  isRenting,
}: MainBodyProps) => {
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const {
    amortizationTime,
    isFinancing,
    costs: {
      appliedProjectCost,
      componentCosts: { carBuyingCost },
    },
  } = useSelector(ProjectSelectors.getFinancialDetails);
  const cars = useSelector(ProjectSelectors.getCars);
  const simplify = useSelector(UserSelectors.getSimplify);

  const { areCarsAdded, isHeatpumpAdded } = useIsComponentAdded();

  const setHover = (key: string): void => {
    setHoverHelper(key, hoverDispatch);
  };

  return (
    <MainContainer>
      <PositiveContainer>
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.FEED_IN_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.FEED_IN_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.FEED_IN} />
            <Text1>{t("FEED IN TARIFF")}</Text1>
          </SubContainer1>
          <Number1>+ {formatNumber(calculations.feedInTariff)}€</Number1>
        </SubContainer>
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.SAVINGS_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.SAVINGS_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.SAVINGS} />
            <Text1>{t("SAVING IN BILL")}</Text1>
          </SubContainer1>
          <Number1>+ {formatNumber(Math.round(calculations.savingsElectricityBill))}€</Number1>
        </SubContainer>
        {areCarsAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_SAVINGS_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_SAVINGS_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.MOBILITY_SAVINGS} />
              <Text1>{t("Savings - mobility bill")}</Text1>
            </SubContainer1>
            <Number1>+ {formatNumber(Math.round(calculations.mobilitySavings))}€</Number1>
          </SubContainer>
        )}
        {isHeatpumpAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_SAVINGS_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.HEAT_SAVINGS_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.GAS_SAVINGS} />
              <Text1>{t("Savings - heat bill")}</Text1>
            </SubContainer1>
            <Number1>+ {formatNumber(calculations.gasSavings)}€</Number1>
          </SubContainer>
        )}
      </PositiveContainer>
      <NegativeContainer>
        {areCarsAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.MOBILITY_GRID_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.MOBILITY_GRID_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.MOBILITY_GRID} />
              <Text1>{t("Grid electricity cost- EV")}</Text1>
            </SubContainer1>
            <Number1>- {formatNumber(Math.round(calculations.EVGridCost))}€</Number1>
          </SubContainer>
        )}
        {isHeatpumpAdded && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.HEAT_GRID_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.HEAT_GRID_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.HEAT_GRID} />
              <Text1>{t("Grid electricity cost- heat pump")}</Text1>
            </SubContainer1>
            <Number1>- {formatNumber(calculations.heatpumpGridCost)}€</Number1>
          </SubContainer>
        )}
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.BUILDING_COST_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.BUILDING_COST_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.BUILDING_COST} />
            <Text1>
              {!isRenting ? t("Investment / building cost") : t("Renting / rental cost")}
            </Text1>
          </SubContainer1>
          <Number1>-{formatNumber(Math.round(calculations.buildingCost))}€</Number1>
        </SubContainer>
        {isFinancing && !isRenting && (
          <SubContainer
            hovered={hoverState.hoveredBar === hoverBarsKey.FINANCING_COST_HOVER}
            onMouseEnter={() => setHover(hoverBarsKey.FINANCING_COST_HOVER)}
            onMouseLeave={() => setHover("")}
          >
            <SubContainer1>
              <Circle backgroundColor={chartColors.FINANCING_COST} />
              <Text1>{t("Financing cost")}</Text1>
            </SubContainer1>
            <Number1>-{formatNumber(Math.round(calculations.financingCost))}€</Number1>
          </SubContainer>
        )}
        {areCarsAdded &&
          cars.map((doc) => {
            return (
              doc.item &&
              !doc.isLeasing && (
                <SubContainer
                  key={doc.id}
                  hovered={hoverState.hoveredBar === hoverBarsKey.CARS_HOVER}
                  onMouseEnter={() => setHover(hoverBarsKey.CARS_HOVER)}
                  onMouseLeave={() => setHover("")}
                >
                  <SubContainer1>
                    <Circle backgroundColor={chartColors.CARS} />
                    <Text1>{doc?.item?.name}</Text1>
                  </SubContainer1>
                  <Number1>
                    -{formatNumber(Math.round(getNumber(doc?.item?.buyingCarPrice)))}€
                  </Number1>
                </SubContainer>
              )
            );
          })}
      </NegativeContainer>
      <div className="saprate"></div>
      <div style={{ paddingInline: "10px", paddingBlock: "5px", width: "100%" }}>
        <SubContainer
          hovered={hoverState.hoveredBar === hoverBarsKey.PROFIT_HOVER}
          onMouseEnter={() => setHover(hoverBarsKey.PROFIT_HOVER)}
          onMouseLeave={() => setHover("")}
        >
          <SubContainer1>
            <Circle backgroundColor={chartColors.PROFIT} />
            <Text2>
              {calculations.profit < 0 ? t("COST NEW SYSTEM") : t("PROFIT WITH SOLAR SYSTEM")}
            </Text2>
          </SubContainer1>
          <Number2>{formatNumber(calculations.profit)}€</Number2>
        </SubContainer>
      </div>
      {!isRenting && !simplify && (
        <SubContainer>
          <Text3>{t("AMORTISATION TIME")}</Text3>
          {amortizationTime !== null &&
            (calculations.profit > 0 ? (
              <Number3>{`${!isRenting ? amortizationTime : 20}  ${t("YEARS")}`}</Number3>
            ) : (
              <Number3>{`${t("Not Applicable")}`}</Number3>
            ))}
        </SubContainer>
      )}

      {isFinancing && !isRenting && (
        <SubContainer>
          <Text3>{t("FINANCE TIME")}</Text3>
          <Number3>
            {12} {t("YEARS")}
          </Number3>
        </SubContainer>
      )}
      <Number3 style={{ width: "100%", textAlign: "left" }}>{`* ${t("prices are netto")}`}</Number3>
    </MainContainer>
  );
};

export default Calculations;
