import React, { useEffect, useState } from "react";
import styles from "./EnergyFlowStyles";
import PageHeading from "../pageHeading/pageHeading";
import { Image, Text, View } from "@react-pdf/renderer";
import commanStyles from "src/pdf/comanStyles/commanStyles";
import HouseTop from "./images/HouseTop.png";
import autarky from "./images/autarky.png";
import { useSelector } from "react-redux";
import { getEnergy, getHousehold, getModel } from "src/redux/project/selectors";
import { formatNumberByLang, useNumberFormat } from "src/hooks";
import HeadingWithLogo from "../headingWithLogo/headingWithLogo";
import { ProjectSelectors } from "src/redux/selectors";
import { getPercentage } from "src/utils";

const EnergyFlow = () => {
  const model = useSelector(getModel);
  const energy = useSelector(getEnergy);
  // const household = useSelector(getHousehold);
  const { formatNumber } = useNumberFormat();
  const [leftPartPercentage, setLeftPartPercentage] = useState(0);

  useEffect(() => {
    const leftPercent = getPercentage(
      model.fromPVAndBattery,
      model.feedIn + model.fromPVAndBattery,
    );
    setLeftPartPercentage(leftPercent);
  }, [model]);

  return (
    <View style={styles.energyFlowContainer}>
      <PageHeading heading="Eigenverbrauch: Wohin geht mein Strom?" titleWidth={390} />
      <View style={styles.mainContainer}>
        <View style={styles.flowContainer}>
          <Image src={HouseTop} style={styles.img} />
          <TextMaker
            style={{ position: "absolute", top: 197 - 21, left: 5, width: 100 }}
            color={"#F2994A"}
            heading={`Batterie ${formatNumberByLang(model.fromBattery.toFixed(0))} kWh`}
            description={`${model.percentages.fromBattery.toFixed(0)}% Solarstrom`}
          />
          <TextMaker
            style={{ position: "absolute", left: 150, top: 67 }}
            heading={`${formatNumberByLang(energy.solarPanelProduction.toFixed(0))} kWh`}
            description="Erzeugung Solarstrom"
            border
          />
          <TextMaker
            style={{ position: "absolute", left: 155, top: 290 }}
            color={"#4D7E6D"}
            heading={`${formatNumberByLang(model.fromPV.toFixed(0))} kWh`}
            description={`${model.percentages.fromPV.toFixed(0)}% PV Direktverbrauch`}
            border
          />
          <TextMaker
            style={{ position: "absolute", right: 120, top: 250 }}
            color={"#4D7E6D"}
            heading={`${formatNumberByLang(model.feedIn.toFixed(0))} kWh`}
            description="Netzeinspeisung"
          />
          <View style={styles.box}>
            <Text style={commanStyles.static_text}>
              Dein Eigenverbrauch: {formatNumber(leftPartPercentage)}%{" "}
            </Text>
            <Text style={{ ...commanStyles.static_text_light, paddingTop: 5 }}>
              Das ist der Solarstrom den du selbst verbrauchst
            </Text>
          </View>
          <View style={styles.box_down}>
            <Text style={commanStyles.static_text}>Warum speise ich Strom ins Netz ein?</Text>
            <Text style={{ ...commanStyles.static_text_light, paddingTop: 5 }}>
              Du kannst deinen Solarstrom direkt verbrauchen oder in einem Batteriespeicher
              zwischenspeichern. Überschüsse werden ins Stromnetz eingespeist.
            </Text>
          </View>
        </View>
        <HeadingWithLogo titleWidth={390} heading="Unabhängigkeit: Woher kommt mein Strom?" />
        <View style={styles.flowContainer}>
          <Image src={autarky} style={styles.img} />
          <TextMaker
            style={{ position: "absolute", top: 197 - 21, left: 5, width: 100 }}
            color={"#F2994A"}
            heading={`Batterie ${formatNumberByLang(model.fromBattery.toFixed(0))} kWh`}
            description={`${model.percentages.fromBattery.toFixed(0)}% Solarstrom`}
          />
          <TextMaker
            style={{ position: "absolute", left: 150, top: 67 }}
            heading={`${formatNumberByLang(model.fromPV.toFixed(0))} kWh`}
            description={`${model.percentages.fromPV.toFixed(0)}% Direktverbrauch Solar`}
            border
          />
          <TextMaker
            style={{ position: "absolute", left: 155, top: 290 }}
            color={"#4D7E6D"}
            heading={`${formatNumberByLang(
              (model.fromPV + model.fromBattery + model.fromGrid).toFixed(0),
            )} kWh`}
            description="Gesamtverbrauch"
            border
          />
          <TextMaker
            style={{ position: "absolute", right: 0, top: 250 }}
            color={"#4D7E6D"}
            heading={`${formatNumberByLang(
              model.fromGrid.toFixed(0),
            )} kWh - ${model.percentages.fromGrid.toFixed(0)}% Solarstrom`}
            description="Netzbezug"
          />
          <View style={styles.box}>
            <Text style={{ ...commanStyles.static_text, fontSize: 12 }}>
              Dein Unabhängigkeitsgrad: {Math.round(model.percentages.autarky).toFixed(0)}%
            </Text>
            <Text style={{ ...commanStyles.static_text_light, paddingTop: 5 }}>
              Das ist der Anteil deines Strombedarfes den du selbst abdeckst
            </Text>
          </View>
          <View style={styles.box_down}>
            <Text style={commanStyles.static_text}>Warum benötige ich Strom vom Netz?</Text>
            <Text style={{ ...commanStyles.static_text_light, paddingTop: 5 }}>
              Selbst wenn deine Solarstromanlage über das ganze Jahr gesehen mehr Strom produziert
              als du verbrauchst benötigst du z.B. in Winternächten trotz Batteriespeicher Strom vom
              Netz.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const TextMaker = ({ style, heading, description, color, border }) => (
  <View style={{ ...styles.textContainer, ...style }}>
    <Text style={{ ...commanStyles.static_text, color: color ? color : "#FFD75D" }}>{heading}</Text>
    <Text
      style={{
        ...commanStyles.static_text_light,
        borderBottom: border ? border : "none",
        borderColor: "#FFD75D",
      }}
    >
      {description}
    </Text>
  </View>
);

export default EnergyFlow;
