import { FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { Button, Checkbox, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import PVSol from "src/assets/PVSol.png";
import Solaredge from "src/assets/Solaredge.png";
import Sunnydesign from "src/assets/Sunnydesign.png";
import { DownArrow, UpArrow } from "src/assets/svgs";

import * as S from "./styles";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectImagesKey } from "src/redux/project/types/house-images";
import { ProjectSelectors } from "src/redux/selectors";
import { useHubspotUpdate } from "src/pages/ApplicationPage/hooks/useHubspotUpdate";
import { PrimaryButton } from "src/shared/StyledComponents";

const { Option } = Select;

type HouseTopAction = typeof ProjectActions.updateHouseTop;
type HouseBottomAction = typeof ProjectActions.updateHouseBottom;
type ElectricMeterAction = typeof ProjectActions.updateElectricMeter;
type ImagesUpdateAction = HouseBottomAction | HouseTopAction | ElectricMeterAction;

interface DescriptionProps {
  cardType: ProjectImagesKey;
}
const Description: React.FC<DescriptionProps> = (props: DescriptionProps) => {
  const { cardType } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const houseTop = useSelector(ProjectSelectors.getHouseTop);
  const ThreeDModels = useSelector(ProjectSelectors.getDroneData);
  const electricMeter = useSelector(ProjectSelectors.getElectricMeter);
  const houseBottom = useSelector(ProjectSelectors.getHouseBottom);

  const [openDescription, setOpenDescription] = useState(false);
  const { createTask } = useHubspotUpdate();

  const buildingYearChangeHandler = (value: string): void => {
    dispatch(
      ProjectActions.updateHouseTop({
        buildingYearId: value,
      }),
    );
  };

  const droneDataChangeHandler: any = (value: string, name: string): any => {
    dispatch(
      ProjectActions.updateDroneData({
        [name]: value,
      }),
    );
  };

  const meterTypeChangeHandler: any = (e: any) => {
    dispatch(
      ProjectActions.updateElectricMeter({
        meterTypeId: e.target.value,
      }),
    );
  };

  const descriptionChangeHandler = (e: any, action: ImagesUpdateAction): void => {
    if (e.target.value.length >= 500) {
      toast.warning(t("Very long description is not allowed"));
      return;
    }
    dispatch(
      action({
        description: e.target.value,
      }),
    );
  };

  const scrollToBottom: any = () => {
    setTimeout(() => {
      document?.getElementById("bottom-div")?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    const isBottomAndDescAdded = cardType === "houseBottom" && houseBottom.description !== "";
    const isTopAndDescAdded = cardType === "houseTop" && houseTop.description !== "";
    const isMeterAndDescAdded = cardType === "electricMeter" && electricMeter.description !== "";

    setOpenDescription(isBottomAndDescAdded || isMeterAndDescAdded || isTopAndDescAdded);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType]);

  const [houseTopYearPrefix, houseTopYearNum] = houseTop.buildingYearId.split(" ");

  return (
    <div style={{ position: "relative" }}>
      <S.SubContainer1
        openDescription={openDescription}
        onClick={() => {
          setOpenDescription((p) => !p);
        }}
      >
        <S.MainText11>
          {cardType === "ThreeDModels" ? (
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {t("Add PV-Simulation")}
              <img src={PVSol} alt="PVSol" height={18} />
              <img src={Solaredge} alt="Solaredge" height={18} width={50}/>
            </div>
          ) : (
            t("Add description")
          )}
        </S.MainText11>
        {openDescription ? (
          <S.ArrowContainer
            onClick={() => {
              setOpenDescription(false);
              document
                .getElementById("application-body-container")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <UpArrow />
          </S.ArrowContainer>
        ) : (
          <S.ArrowContainer
            onClick={() => {
              setOpenDescription(true);
              scrollToBottom();
            }}
          >
            <DownArrow />
          </S.ArrowContainer>
        )}
      </S.SubContainer1>
      {openDescription && (
        <>
          {cardType === "houseTop" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.MainText1 style={{ fontSize: "14px", marginBottom: "initial" }}>
                {t("Building year of the roof")}
              </S.MainText1>
              <S.SubContainer112>
                <Select
                  defaultValue={"After 1980"}
                  value={`${t(houseTopYearPrefix)} ${houseTopYearNum}`}
                  style={{ width: 233 }}
                  onChange={buildingYearChangeHandler}
                >
                  <Option value="After 1980">{`${t("After")} 1980`}</Option>
                  <Option value="After 1990">{`${t("After")} 1990`}</Option>
                  <Option value="After 2000">{`${t("After")} 2000`}</Option>
                  <Option value="After 2010">{`${t("After")} 2010`}</Option>
                  <Option value="After 2020">{`${t("After")} 2020`}</Option>
                </Select>
              </S.SubContainer112>

              <S.SubText3
                placeholder={t("Add your home description....")}
                onChange={(e) => descriptionChangeHandler(e, ProjectActions.updateHouseTop)}
                value={houseTop.description}
              />
            </div>
          )}
          {cardType === "houseBottom" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.SubText4
                placeholder={t("Add your home description....")}
                onChange={(e) => descriptionChangeHandler(e, ProjectActions.updateHouseBottom)}
                value={houseBottom.description}
              />
            </div>
          )}
          {cardType === "ThreeDModels" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.MainText1 style={{ fontSize: "14px", marginBottom: "initial" }}>
                {t("We create a detailed PV-Plan for you")}
              </S.MainText1>
              <S.SubContainer112 style={{ height: "auto" }}>
                <FormControl component="fieldset" variant="standard" style={{ margin: "10px" }}>
                  <FormGroup style={{ display: "flex", gap: "5px", flexDirection: "column" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ThreeDModels.planYourSelf}
                          onChange={(e: any) =>
                            droneDataChangeHandler(e.target.checked, "planYourSelf")
                          }
                        />
                      }
                      className="checkbox"
                      label={
                        <div
                          style={{
                            paddingLeft: "8px",
                          }}
                        >
                          {t("OBJ 3D-model file (integrate & plan yourself)")}
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ThreeDModels.pvSolModifyModel}
                          onChange={(e: any) =>
                            droneDataChangeHandler(e.target.checked, "pvSolModifyModel")
                          }
                        />
                      }
                      className="checkbox"
                      label={
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            paddingLeft: "8px",
                          }}
                        >
                          <img src={PVSol} alt="PVSol" height={10} />
                          {t("PVSol 3D-PV-model (easily modify model)")}
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ThreeDModels.threeDpvModelSunnyDesign}
                          onChange={(e: any) =>
                            droneDataChangeHandler(e.target.checked, "threeDpvModelSunnyDesign")
                          }
                        />
                      }
                      className="checkbox"
                      label={
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            paddingLeft: "8px",
                          }}
                        >
                          <img src={Sunnydesign} alt="Solaredge" height={10} />
                          {t("3D-PV-model (easily modify model)")}
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ThreeDModels.threeDpvModelSolarEdgeDesign}
                          onChange={(e: any) =>
                            droneDataChangeHandler(e.target.checked, "threeDpvModelSolarEdgeDesign")
                          }
                        />
                      }
                      className="checkbox"
                      label={
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            paddingLeft: "8px",
                          }}
                        >
                          <img src={Solaredge} alt="Solaredge" height={10} />
                          {t("3D-PV-model (easily modify model)")}
                        </div>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </S.SubContainer112>
              <PrimaryButton onClick={createTask}>{t("Request PV-Planning")}</PrimaryButton>
              <S.SubText3
                placeholder={t("Add your wishes for PV-Planning....")}
                onChange={(e) => descriptionChangeHandler(e, ProjectActions.updateDroneData)}
                value={ThreeDModels.description}
              />
            </div>
          )}
          {cardType === "electricMeter" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.MainText1 style={{ fontSize: "14px", marginBottom: "initial" }}>
                {t("Electric meter type")}
              </S.MainText1>
              <S.SubContainer112>
                <Radio.Group
                  onChange={meterTypeChangeHandler}
                  value={electricMeter.meterTypeId}
                  style={{ display: "flex", gap: "10px" }}
                >
                  <Radio value={"Digital meter"}>{t("Digital meter")}</Radio>
                  <Radio value={"Analog meter"}>{t("Analog meter")}</Radio>
                </Radio.Group>
              </S.SubContainer112>

              <S.SubText3
                placeholder={t("Add your elctric meter description....")}
                onChange={(e) => descriptionChangeHandler(e, ProjectActions.updateElectricMeter)}
                value={electricMeter.description}
              />
            </div>
          )}
        </>
      )}
      <div id={"bottom-div"} />
    </div>
  );
};

export default Description;
