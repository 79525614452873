export const ProductTitle = {
  solarPanel: "Solar Panel",
  inverter: "Inverter",
  battery: "Battery",
  wallbox: "Wall Box",
  heatpump: "Heat Pump",
  additionalSoftware: "Additional Software",
  additionalHardware: "Additional Hardware",
};
export const ProductVideo = {
  solarPanel: "https://youtu.be/UGxqbY7v_l0 ",
  inverter: "https://youtu.be/UGxqbY7v_l0 ",
  battery: "https://youtu.be/UGxqbY7v_l0 ",
  wallbox: "https://youtu.be/UGxqbY7v_l0 ",
  heatpump: "https://youtu.be/UGxqbY7v_l0 ",
  additionalSoftware: "https://youtu.be/UGxqbY7v_l0 ",
  additionalHardware: "https://youtu.be/UGxqbY7v_l0 ",
};
export const SubBody = {
  solarPanel: "TEXT FOR SOLAR",
  inverter: "TEXT FOR INVERTER",
  battery: "TEXT FOR BATTERY",
  wallbox: "TEXT FOR WALLBOX",
  heatpump: "TEXT FOR HEATPUMP",
  additionalSoftware: "TEXT FOR ADDITIONALSOFTWARE",
  additionalHardware: "TEXT FOR ADDITIONALHARDWARE"

};
export const ProductMainBody = {
  solarPanel: [
    "TEXT SOLAR PANEL 1",
    "TEXT SOLAR PANEL 2",
    "TEXT SOLAR PANEL 3",
    "TEXT SOLAR PANEL 4",
  ],
  inverter: [
    "TEXT INVERTER 1",
    "TEXT INVERTER 2",
    "TEXT INVERTER 3",
    "TEXT INVERTER 4",
    "TEXT INVERTER 5",
  ],
  battery: ["TEXT BATTERY 1", "TEXT BATTERY 2", "TEXT BATTERY 3", "TEXT BATTERY 4"],
  wallbox: ["TEXT WALLBOX 1", "TEXT WALLBOX 2", "TEXT WALLBOX 3"],
  heatpump: ["TEXT HEATPUMP 1", "TEXT HEATPUMP 2", "TEXT HEATPUMP 3", "TEXT HEATPUMP 4"],
  additionalSoftware: ["TEXT HEATPUMP 1", "TEXT HEATPUMP 2", "TEXT HEATPUMP 3", "TEXT HEATPUMP 4"],
  additionalHardware: ["TEXT HEATPUMP 1", "TEXT HEATPUMP 2", "TEXT HEATPUMP 3", "TEXT HEATPUMP 4"],
};
