import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        paddingLeft: 30,
        paddingRight: 30
      },
})

export default styles;