import React, { useEffect, useMemo, useState } from "react";
import { Image, Text, View } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

import { formatNumberByLang, useIsComponentAdded } from "src/hooks";
import PageHeading from "../../components/pageHeading/pageHeading";
import commanStyles from "../../comanStyles/commanStyles";
import HeadingWithLogo from "../../components/headingWithLogo/headingWithLogo";
import projectImage from "../../../assets/bigScreenImages/projectImage.png";
import InvestMentChart from "../../components/InvestMentChart/InvestMentChart";
import Footer from "../../components/Footer/Footer";

import { getAddress, getProject, getQuoteProfile } from "../../../redux/project/selectors";
import { getUser } from "../../../redux/user/selectors";
import styles from "./PersonalEnergyStyles";
import { getCheapestProduct } from "src/helpers";
import { ProductSelectors, UserSelectors } from "src/redux/selectors";
import { isValidURL } from "src/utils";

const PersonalEnergy = () => {
  const user = useSelector(getUser);
  const quoteProfile = useSelector(getQuoteProfile);
  const project = useSelector(getProject);
  const address = useSelector(getAddress);
  const [image, setImage] = useState("");

  const batteryStorage = project?.components?.battery?.item?.applicableMaxCapacity / 1000;
  const componentCost = project?.financialDetails?.costs?.componentCosts?.totalCost;
  const wallBoxCost = project?.financialDetails?.costs?.componentCosts?.wallboxCost;
  const heatpumpCost = project?.financialDetails?.costs?.componentCosts?.heatpumpCost;
  const wallBoxAndHeatPumpCost = ((wallBoxCost + heatpumpCost) * 19) / 100;
  const withoutTexProduct = componentCost - wallBoxCost - heatpumpCost;
  const lastInvestMentAfterTex = componentCost + wallBoxAndHeatPumpCost - withoutTexProduct;
  const totalProjectCost = project?.financialDetails?.costs?.totalProjectCost;

  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);
  const { isSolarPanelAdded, isInvertedAdded, isHeatpumpAdded, isWallboxAdded, isBatteryAdded } =
    useIsComponentAdded();

  const cheapestSolarPanel = useMemo(() => {
    return getCheapestProduct(filtered.solarPanels);
  }, [filtered]);

  const solarStorage =
    (cheapestSolarPanel?.applicableMaxCapacity / 1000) * project?.components?.solarPanel?.quantity;

  const cheapestinverters = useMemo(() => {
    return getCheapestProduct(filtered.inverters);
  }, [filtered]);

  const cheapestbatteries = useMemo(() => {
    return getCheapestProduct(filtered.batteries);
  }, [filtered]);

  const cheapestwallboxes = useMemo(() => {
    return getCheapestProduct(filtered.wallboxes);
  }, [filtered]);

  const cheapestheatpumps = useMemo(() => {
    return getCheapestProduct(filtered.heatpumps);
  }, [filtered]);

  const filteredImages = useMemo(() => {
    return quoteProfile?.imgURLs.filter((img) => {
      const fileExtension = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address, quoteProfile.imgURLs]);

  const getImageUrl = useMemo(() => {
    return filteredImages.length ? filteredImages[0] : "";
  }, [filteredImages]);

  useEffect(() => {
    setImage(`${getImageUrl}?news=${Math.random() * 10000}`);
  }, [getImageUrl]);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <PageHeading heading="Dein persönliches Energiekonzept" titleWidth={350} />
      <View style={styles.mainContainer}>
        <Text style={commanStyles.black_heading}> Hallo {user?.firstName}, </Text>
        <Text style={{ ...commanStyles.black_description, ...styles.description }}>
          Wir bedanken uns für deine Anfrage. Nachfolgend findest du einen Vorschlag für dein
          nachhaltiges Energiekonzept.
        </Text>
        <HeadingWithLogo heading="Deine Solaranlage" />
        <View style={styles.bigImage}>
          <Image
            src={image}
            source={{ header: { "Access-Control-Allow-Origin": "*" } }}
            style={{ height: 300, objectFit: "cover" }}
            cache={false}
          />
        </View>
        <HeadingWithLogo heading="Dein Energiesystem" />
        <View style={commanStyles.divider} />
        <View style={styles.table}>
          <View style={styles.table_left}>
            <Text style={commanStyles.static_text}>Überblick</Text>
          </View>
          <View style={styles.table_right}>
            <Text style={commanStyles.static_text_light}>
              Photovoltaikanlage ({formatNumberByLang(solarStorage.toFixed(0))} kWp)
            </Text>
            {cheapestbatteries && isInvertedAdded && (
              <Text style={commanStyles.static_text_light}>
                Batteriespeicher (
                {formatNumberByLang((cheapestbatteries?.applicableMaxCapacity / 1000).toFixed(0))}{" "}
                kWh)
              </Text>
            )}
            {cheapestinverters && isInvertedAdded && (
              <Text style={commanStyles.static_text_light}>Energiemanagemtsystem</Text>
            )}
            {cheapestwallboxes && isWallboxAdded && (
              <Text style={commanStyles.static_text_light}>
                Wärmesystem (
                {formatNumberByLang((cheapestwallboxes?.applicableMaxCapacity / 1000).toFixed(0))}{" "}
                kWh)
              </Text>
            )}
            {cheapestheatpumps && isWallboxAdded && (
              <Text style={commanStyles.static_text_light}>
                Ladestation (
                {formatNumberByLang((cheapestheatpumps?.applicableMaxCapacity / 1000).toFixed(0))}{" "}
                kWh)
              </Text>
            )}
          </View>
        </View>
        <View style={{ ...commanStyles.divider, marginTop: 10 }} />
        <View style={styles.makeItCenter}>
          <Text style={commanStyles.static_text}>Deine Investition*</Text>
          <Text style={commanStyles.static_text_light}>
            - Gesamtinvestition nachhaltige Energielösung
          </Text>
          <InvestmentDetails
            componentCost={totalProjectCost}
            wallBoxAndHeatPumpCost={wallBoxAndHeatPumpCost}
            withoutTexProduct={withoutTexProduct}
            lastInvestMentAfterTex={lastInvestMentAfterTex}
          />
        </View>
        <View
          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
          <View
            style={{
              height: 150,
              marginTop: 15,
              width: "60%",
            }}
          >
            <InvestMentChart />
          </View>
        </View>
        <View style={{ ...commanStyles.divider, marginTop: 40 }} />
        <Text style={styles.black_description_small}>
          * Gesetzl. Förderungen sind einbezogen - können nicht garantiert werden
        </Text>
      </View>
      <Footer pageNumber={2} />
    </View>
  );
};

const InvestmentDetails = ({ componentCost }) => (
  <Text style={{ ...commanStyles.static_text_light, ...styles.paddingLeft }}>
    {formatNumberByLang(componentCost.toFixed(0))}€
  </Text>
);

export default PersonalEnergy;
