import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { formatNumberByLang, useAppDispatch, useAppSelector, useNumberFormat } from "src/hooks";

import { ProjectActions } from "src/redux/actionCreators";
import { getModel } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";

const ElectricalWaterHeatingLine: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const model = useSelector(getModel);
  const { formatNumber } = useNumberFormat();

  const waterHeater = useAppSelector(ProjectSelectors.getWaterHeating);

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      if (e.target.checked) dispatch(ProjectActions.addWaterHeater());
      else dispatch(ProjectActions.deleteWaterHeater());
    },
    [dispatch],
  );

  return (
    <div className="same_part">
      <div className="text_same">
        <Checkbox checked={waterHeater} onChange={onChange}>
          {t("Electrical water heating")}
        </Checkbox>
      </div>
      <div className="round_button">{formatNumber(model.heatingRod.consumption)} kWh</div>
    </div>
  );
};

export default ElectricalWaterHeatingLine;
