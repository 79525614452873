import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DownArrow, UpArrow } from "src/assets/svgs";
import { Mixpanel, trackingEvents } from "src/mix-panel";

import Chart from "../price-break-down-chart/chart";

import Calculations from "./../calculations/Calculations";
import { MainText1, SubContainer2 } from "./styles";
import { useSelector } from "react-redux";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { Number3, SubContainer, Text3 } from "../calculations/styles";
import { useNumberFormat } from "src/hooks";

export interface ReturnOnInvestCalculations {
  buildingCost: number;
  financingCost: number;
  feedInTariff: number;
  mobilitySavings: number;
  gasSavings: number;
  EVGridCost: number;
  heatpumpGridCost: number;
  cars: Array<{ name: string; cost: number }>;
  totalCarsCost: number;
  profit: number;
  loss: number;
  savingsElectricityBill: number;
}

interface MainBodyProps {
  calculations: ReturnOnInvestCalculations;
  hoverState: any;
  hoverDispatch: any;
  isRenting: boolean;
}
const MainBody: React.FC<MainBodyProps> = (props: MainBodyProps) => {
  const { calculations, hoverDispatch, hoverState, isRenting } = props;
  const { t } = useTranslation();
  const { formatNumber } = useNumberFormat();

  const [calculationToggle, setcalculationToggle] = useState(false);

  const simplify = useSelector(UserSelectors.getSimplify);
  const {
    amortizationTime,
    isFinancing,
    costs: {
      appliedProjectCost,
      componentCosts: { carBuyingCost },
    },
  } = useSelector(ProjectSelectors.getFinancialDetails);

  return (
    <>
      <Chart
        calculations={calculations}
        hoverDispatch={hoverDispatch}
        hoverState={hoverState}
        isRenting={isRenting}
      />
      {calculationToggle ? (
        <SubContainer2
          onClick={() => {
            setcalculationToggle(false);
          }}
        >
          <MainText1>{t("HIDE CALCULATIONS")}</MainText1>
          <div>
            <UpArrow />
          </div>
        </SubContainer2>
      ) : (
        <SubContainer2
          onClick={() => {
            Mixpanel.track(trackingEvents.USER_CLICKS_ON_SHOW_DETAILED_CALCULATIONS_STEP1, {});
            setcalculationToggle(true);
          }}
        >
          <MainText1>{t("SHOW CALCULATIONS")}</MainText1>
          <div>
            <DownArrow />
          </div>
        </SubContainer2>
      )}
      {calculationToggle && (
        <Calculations
          calculations={calculations}
          hoverDispatch={hoverDispatch}
          hoverState={hoverState}
          isRenting={isRenting}
        />
      )}
      {!isRenting && simplify && (
        <SubContainer>
          <Text3>{t("AMORTISATION TIME")}</Text3>
          {amortizationTime !== null &&
            (calculations.profit > 0 ? (
              <Number3>{`${!isRenting ? amortizationTime : 20}  ${t("YEARS")}`}</Number3>
            ) : (
              <Number3>{`${t("Not Applicable")}`}</Number3>
            ))}
        </SubContainer>
      )}
      {simplify && (
        <SubContainer>
          <Text3>{t("Investment")}</Text3>
          {formatNumber(Math.round(calculations.buildingCost))}€
        </SubContainer>
      )}
      {simplify && (
        <SubContainer>
          <Text3>{t("WITHOUT SOLAR SYSTEM")}</Text3>-{" "}
          {formatNumber(Math.round(calculations.savingsElectricityBill))}€
        </SubContainer>
      )}
    </>
  );
};

export default MainBody;
