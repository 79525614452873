import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import smallLogo from "../../../assets/logos/smallLogo.png";
import colors from "../../Utils/colors";

const HeadingWithLogo = ({ heading, titleWidth = 265 }) => {
  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      paddingTop: 20,
      paddingBottom: 20,
    },
    heading: {
        fontFamily: "Nunito",
        fontWeight: 700,
        fontSize: 20,
        paddingLeft: 10,
        color: colors.primaryBlue,
        zIndex: 1
    },
    img: {
      height: 30,
      width: 20
    }
  });

  return (
    <View style={styles.container}>
      <Image src={smallLogo} style={styles.img}></Image>
      <Text style={styles.heading}>{heading}</Text>
    </View>
  );
};

export default HeadingWithLogo;
