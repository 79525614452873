import { StyleSheet } from "@react-pdf/renderer";
import colors from "../Utils/colors";

const commanStyles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 30,
    paddingRight: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
  solarhubLogo: {
    width: 160,
    height: 60,
    marginBottom: 20
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexRowLeftAlign: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  flexRowRightAlign: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column"
  },
  flexBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  stactic_heading: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 16,
    color: colors.primaryBlue,
  },
  static_text: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
  },
  static_text_small: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 12,
    color: colors.primaryBlue,
  },
  static_text_light: {
    fontFamily: "Nunito",
    fontSize: 10,
    color: colors.primaryBlue,
  },
  black_heading: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 16,
    color: colors.black,
  },
  divider: {
    borderBottom: "0.7 solid #666666",
    width: "100%",
    marginBottom: 10,
  },
  black_description: {
    fontFamily: "Nunito",
    fontWeight: 100,
    opacity: 0.9,
    fontSize: 14,
    color: colors.black,
  },
});

export default commanStyles;
