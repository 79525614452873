import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { useAddConfiguration, useAppDispatch, useNaivgateAbsolute } from "src/hooks";

import { ApiAxiosClient } from "src/axios";
import { convertProjectToSaveRequest } from "src/db-converters";
import { useQuoteApi, useSaveProjectApi } from "src/hooks/apis";
import { Loader } from "src/shared/components";
import { colors, getErrorMessage } from "src/utils";

import { useLoadInitialProducts } from "../ApplicationPage/hooks";

import { Header, ProjectsSliderView } from "./components";
import { useLoadAllProjects } from "./hooks";
import { MainContainer } from "./UserOverview.styles";

import { ProjectActions } from "src/redux/actionCreators";
import { MapboxAddress, ProjectState } from "src/redux/project";
import { defaultProjectState } from "src/redux/project/initialState";
import { AppSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { UserAuthStatus } from "src/redux/user";
import { updateInstallerId } from "src/redux/project/action/action.creators";
import { getUser } from "src/redux/user/selectors";

export const UserOverview: React.FC = () => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isMapView, setIsMapView] = useState(false);
  const [showProjects, setShowProjects] = useState(true);
  const installerDetails = useSelector(UserSelectors?.getInstaller);
  const authStatus = useSelector(UserSelectors.getAuthStatus);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const uid = useSelector(UserSelectors.getUser)?.id;
  const { isLoadAllProjectsApiLoading } = useSelector(AppSelectors.getApiStates);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);
  const navigate = useNaivgateAbsolute();
  const { projects, removeProject, loadAllProjects } = useLoadAllProjects();
  const { addConfig } = useAddConfiguration();
  const user = useSelector(getUser);

  useLoadInitialProducts();

  useEffect(() => {
    if (authStatus === UserAuthStatus.loggedOut) {
      toast.warning(t("Please login to view the saved projects view"));
      navigate("/application");
    }
  }, [authStatus, navigate, t]);

  const deleteBtnClickHandler = useCallback(
    async (address: string, id: string) => {
      const result = window.confirm(t(`Are you sure you want to delete ${address} project?`));
      if (result) {
        try {
          setDeleteProjectLoading(true);
          await ApiAxiosClient.put(BackendApis.deleteProjects.url, {
            deletedProjects: [id],
          });
          setDeleteProjectLoading(false);
          removeProject(id);
        } catch (error) {
          toast.error(t("Delete project failed"));
        }
      }
    },
    [removeProject, t],
  );

  const onAddDummyProjectClick = useCallback(async () => {
    let quoteIdToSend = quoteId;
    if (quoteIdToSend === "") {
      quoteIdToSend = projects.map((project) => project.quoteID._id)[0];
    }
    await addConfig(quoteIdToSend);
    loadAllProjects(uid);
  }, [addConfig, loadAllProjects, projects, quoteId, uid]);

  const { error, fetchData, loading, response } = useSaveProjectApi();
  const { error: quoteError, fetchData: quoteFetchData, response: quoteResponse } = useQuoteApi();

  useEffect(() => {
    if (!quoteError) return;

    toast.error(t("Add project failed"));
  }, [quoteError]);

  useEffect(() => {
    if (!quoteResponse) return;

    const {
      data: { data: quoteDB },
    } = quoteResponse;
    const projectState: ProjectState = cloneDeep(defaultProjectState);
    projectState.components.solarPanel.quantity = quoteDB.profiles.green.moduleQuantity || 0;
    projectState.quoteId = quoteDB._id;

    void fetchData({
      ...BackendApis.saveProject,
      data: convertProjectToSaveRequest(projectState),
    });
  }, [fetchData, quoteResponse]);

  useEffect(() => {
    if (!response) return;
    if (installerDetails) {
      dispatch(
        updateInstallerId({
          iid: installerDetails?.companyName,
        }),
      );
    }

    toast.success(t("Your project has been saved successfully"));
    loadAllProjects(uid);
  }, [loadAllProjects, response, t, uid, installerDetails, user]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));

    return () => {
      toast.dismiss(id);
    };
  }, [error, t]);

  const saveProjectHandler = useCallback(
    (mapboxAddress: MapboxAddress) => {
      void quoteFetchData({
        ...BackendApis.quote,
        data: {
          mapboxAddress,
        },
      });
    },
    [quoteFetchData],
  );

  return (
    <MainContainer>
      <Header
        showProjects={showProjects}
        setShowProjects={setShowProjects}
        showMapView={isMapView}
        setShowMapView={setIsMapView}
        onProjectSave={saveProjectHandler}
        onAddDummyProjectClick={onAddDummyProjectClick}
      />
      {/* {isMapView && <MapView projects={projects}
        savedProjectsEnabled={showProjects}
        onDelete={deleteBtnClickHandler}  />} */}
      {!isMapView && (
        <ProjectsSliderView
          projects={projects}
          savedProjectsEnabled={showProjects}
          onDelete={deleteBtnClickHandler}
        />
      )}
      <Loader
        loading={deleteProjectLoading || isLoadAllProjectsApiLoading}
        color={colors.orangeSolid}
      />
    </MainContainer>
  );
};
