import { cloneDeep } from "lodash";

import { ProductsState } from "../types";

import { ReduxAction } from "src/redux/common.types";
import { actionNames, actionHandlers } from "src/redux/products";

const initialState: ProductsState = {
  products: [],
  batteries: [],
  cars: [],
  heatpumps: [],
  inverters: [],
  solarPanels: [],
  wallboxes: [],
  additionalSoftware: [],
  additionalHardware: [],
  filteredProducts: {
    batteries: [],
    wallboxes: [],
    heatpumps: [],
    inverters: [],
    solarPanels: [],
    additionalSoftware: [],
    additionalHardware: [],
  },
  productObjFiles: [],
};

const reducer = (state = initialState, action: ReduxAction<any>): ProductsState => {
  switch (action.type) {
    case actionNames.SET_PRODUCTS:
      return actionHandlers.setProducts(state, action);
    case actionNames.SET_FILTERED_PRODUCTS:
      return actionHandlers.setFilteredProducts(state, action);
      case actionNames.SET_PRODUCT_OBJ_FILES:
        return actionHandlers.setProductObjFiles(state, action);
    case "RESET_REDUX":
      return cloneDeep(initialState);
    default:
      return state;
  }
};

export default reducer;
