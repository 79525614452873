import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackendApis } from "src/api";

import { convertProjectToSaveRequest } from "src/db-converters";
import { getErrorMessage, isNoQuotePresent } from "src/utils";

import { useSaveDeal, useSaveProjectApi, userUpdateRefCredits } from "./apis";

import { AppActions } from "src/redux";
import { AppSelectors, ProjectSelectors, UserSelectors } from "src/redux/selectors";
import store from "src/redux/store";
import { getInstaller, getUser } from "src/redux/user/selectors";

import { useAppDispatch, useAppSelector, useNaivgateAbsolute } from ".";
import { getPartnerCityData } from "src/redux/application/selector";
import { updateInstallerId } from "src/redux/project/action/action.creators";

export const useSaveProject = (): void => {
  const dispatch = useAppDispatch();
  const navigate = useNaivgateAbsolute();
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const user = useSelector(getUser);
  const refCode: string | null = query.get("refCode");
  const installerSlug = query.get("partner");
  const installerParter = useSelector(getInstaller);
  const getPartnerCity = useSelector(getPartnerCityData);

  const { saveProjectRemaining, quoteFetching } = useAppSelector(AppSelectors.getState);

  const { error, fetchData, loading, response } = useSaveProjectApi();
  const {
    error: dealError,
    fetchData: dealAPI,
    loading: dealLoading,
    response: dealResponse,
  } = useSaveDeal();

  const { fetchData: refCreditsAPI } = userUpdateRefCredits();
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  useEffect(() => {
    if (installerDetails) {
      dispatch(
        updateInstallerId({
          iid: installerDetails?.companyName,
        }),
      );
    }
  }, [installerDetails, user]);

  useEffect(() => {
    if (!response) return;
    dispatch(
      AppActions.updateAppState({
        saveProjectRemaining: false,
      }),
    );

    const {
      data: { data: projectDB },
    } = response;

    if (Array.isArray(projectDB)) {
      if (projectDB.length <= 0) {
        toast.error(t("Loading project failed"));
      }

      // Already existing project
      const { shortId, version, _id } = projectDB[0];

      // navigate(`/application/saved-${shortId}/${version}`);
      if (installerSlug) {
        navigate(`/application/saved-${shortId}/${version}?partner=${installerSlug}`);
      } else {
        navigate(`/application/saved-${shortId}/${version}`);
      }

      if (refCode && user?.id) {
        void refCreditsAPI({
          ...BackendApis.updateRefCredits,
          data: {
            userId: user?.id,
            projectId: _id,
            quoteId,
            refCode,
            shortId,
            version,
          },
        });
      }
      return;
    }

    const { shortId, version, _id } = projectDB;

    dispatch(
      AppActions.updateAppState({
        shouldSaveLastLog: true,
        actions: {
          shouldLoadUniqueProjects: true,
        },
      }),
    );

    if (installerSlug) {
      console.log("1st");
      navigate(`/application/saved-${shortId}/${version}?partner=${installerSlug}`);
    } else {
      console.log("2nd");

      navigate(`/application/saved-${shortId}/${version}`);
    }
    toast.success(t("Your project has been saved successfully"));

    if (refCode && user?.id) {
      void refCreditsAPI({
        ...BackendApis.updateRefCredits,
        data: {
          userId: user?.id,
          projectId: _id,
          quoteId,
          refCode,
          shortId,
          version,
        },
      });
    }
    let projectURL = `https://${location.host}/application/saved-${shortId}/${version}?admin=true`;

    if (installerSlug) {
      projectURL = `https://${location.host}/application/saved-${shortId}/${version}?admin=true?partner=${installerSlug}`;
    }
    void dealAPI({
      ...BackendApis.createHubspotDeal,
      data: {
        address: projectDB.quoteID.mapboxAddress.complete,
        projectURL,
        white_label_lead_tag: !!installerParter,
        gemeindeschlussel:
          projectDB.quoteID.Gemeindeschluessel8 || getPartnerCity.Gemeindeschluessel8,
      },
    });
  }, [
    dealAPI,
    dispatch,
    navigate,
    quoteId,
    refCode,
    refCreditsAPI,
    response,
    t,
    user,
    installerSlug,
    installerParter,
    getPartnerCity,
    installerDetails,
  ]);

  useEffect(() => {
    dispatch(
      AppActions.updateAppState({
        isSavedProjectRequestLoading: loading,
      }),
    );
  }, [dispatch, loading]);

  useEffect(() => {
    if (!error) return;

    const message = getErrorMessage(error);
    const id = toast.error(t(message));

    return () => {
      toast.dismiss(id);
    };
  }, [error, t]);

  useEffect(() => {
    if (!saveProjectRemaining) return;
    if (quoteFetching) return;
    if (isNoQuotePresent(quoteId)) return;

    const state: any = store.getState();

    const saveProjectDB = convertProjectToSaveRequest({
      ...state?.project,
      installer: state?.user?.installer?.email,
    });

    void fetchData({
      ...BackendApis.saveProject,
      data: { ...saveProjectDB },
    });
  }, [fetchData, quoteFetching, saveProjectRemaining, quoteId]);
};
