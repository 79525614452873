import React from "react";
import { useSelector } from "react-redux";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

import { formatNumberByLang } from "src/hooks";
import colors from "../../Utils/colors";

import { getFinancialDetails } from "../../../redux/project/selectors";
import { useYearlySystemProfit } from "../../../hooks/useYearlySystemProfit";
import commanStyles from "../../comanStyles/commanStyles";

const styles = StyleSheet.create({
  chartContainer: {
    flex: 1,
    margin: 10,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "relative",
  },
  horizontalLine: {
    width: "100%",
    height: 0.5,
    backgroundColor: colors.grey,
  },
  barContainer: {
    height: "80%",
    width: 30,
    marginLeft: 40,
  },
  profitBar: {
    flex: 1,
    marginLeft: 17,
    backgroundColor: colors.primaryYellow,
  },
  lossBar: {
    flex: 1,
    marginRight: 17,
    backgroundColor: colors.red,
  },
  informasion_profit: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: 50,
  },
  informasion_loss: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 50,
  },
});

const calculateDynamicHeights = (profit, loss) => {
  const total = profit + loss;
  const profitHeight = total > 0 ? (profit / total) * 100 : 0;
  const lossHeight = total > 0 ? (loss / total) * 100 : 0;

  return {
    profitHeight,
    lossHeight,
  };
};

const InvestMentChart = ({ lossPosition, profitPossition, setScreenshot }) => {
  const { expectedReturnTime } = useSelector(getFinancialDetails);
  const { profitByYear } = useYearlySystemProfit();
  const { profit, electricityBillSavingsTillNow } = profitByYear(expectedReturnTime);
  const loss = electricityBillSavingsTillNow;

  const { profitHeight, lossHeight } = calculateDynamicHeights(profit, loss);

  return (
    <View style={styles.chartContainer}>
      <View style={styles.barContainer}>
        <View style={[styles.profitBar, { height: `${profitHeight}%` }]} />
      </View>
      <View style={styles.horizontalLine} />
      <View style={styles.barContainer}>
        <View style={[styles.lossBar, { height: `${lossHeight}%` }]} />
      </View>
      <View style={{ ...styles.informasion_profit, top: profitPossition }}>
        <Text style={commanStyles.static_text_small}>
          {profit < 0
            ? formatNumberByLang(profit.toFixed(0))
            : `+ ${formatNumberByLang(profit.toFixed(0))}`}{" "}
          €
        </Text>
        <View style={{ paddingLeft: 10 }}>
          <Text style={commanStyles.static_text_small}>Gewinn mit</Text>
          <Text style={commanStyles.static_text_small}>Solaranlage</Text>
        </View>
      </View>
      <View style={{ ...styles.informasion_loss, bottom: lossPosition }}>
        <Text style={commanStyles.static_text_small}>-{formatNumberByLang(loss.toFixed(0))} €</Text>
        <View style={{ paddingLeft: 10 }}>
          <Text style={commanStyles.static_text_small}>Kosten ohne</Text>
          <Text style={commanStyles.static_text_small}>Solaranlage</Text>
        </View>
      </View>
    </View>
  );
};

InvestMentChart.defaultProps = {
  lossPosition: 10,
  profitPossition: 0,
};

export default InvestMentChart;
