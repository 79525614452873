import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Image, Text, View } from "@react-pdf/renderer";

import { WEB_CONSTANTS_KEYS } from "src/data";
import { formatNumberByLang } from "../../../hooks/useNumberFormat";

import PageHeading from "../../components/pageHeading/pageHeading";
import HeadingWithLogo from "../../components/headingWithLogo/headingWithLogo";
import InvestMentChart from "../../components/InvestMentChart/InvestMentChart";
import InvestMentDetails from "./InvestMentDetails";
import Footer from "../../components/Footer/Footer";
import commanStyles from "../../comanStyles/commanStyles";

import {
  getBattery,
  getEnergy,
  getEnergyProductionParams,
  getFinancialDetails,
  getHousehold,
  getProject,
  getProjectType,
  getQuote,
  getSolarPanel,
  getWebConstants,
} from "../../../redux/project/selectors";

import { getNumber } from "../../Utils/helperMethods";
import styles from "./InvestMentOverviewStyles";
import { getCheapestProduct } from "src/helpers";
import { ProductSelectors } from "src/redux/selectors";
import { useIsComponentAdded } from "src/hooks";
import { useTranslation } from "react-i18next";

const getExtraCostForConstant = ({
  constant,
  projectType,
  solarPanelProduction,
  allComponentsCost,
  solarPanelQty,
}) => {
  const priceKey = projectType ? "price_b2b" : "price_b2c";

  switch (constant.Type) {
    case WEB_CONSTANTS_KEYS.FIX_FOR_PROJECT:
      return getNumber(constant[priceKey]);

    case WEB_CONSTANTS_KEYS.DEPENDING_ON_KWH:
      return (getNumber(constant[priceKey]) * solarPanelProduction) / 1000;

    case WEB_CONSTANTS_KEYS.FIX_PER_PANEL:
      return getNumber(constant[priceKey]) * solarPanelQty;

    case WEB_CONSTANTS_KEYS.PERCENTAGE_OF_PROJECT_COST:
      return (getNumber(constant[priceKey]) * allComponentsCost) / 100;
    default:
      return 0;
  }
};

const InvestMentOverview = ({ investMentChartScreenShot }) => {
  const solarPanel = useSelector(getSolarPanel);
  const project = useSelector(getProject);
  const energy = useSelector(getEnergy);
  const household = useSelector(getHousehold);
  const battery = useSelector(getBattery);
  const energyProdParams = useSelector(getEnergyProductionParams);
  const { t } = useTranslation();

  const quote = useSelector(getQuote);
  const selectedProfileQuote = quote[project?.profile.toLowerCase()]?.roofOrientation;
  const {
    costs: {
      componentCosts: { solarPanelCost, inverterCost },
    },
  } = useSelector(getFinancialDetails);
  const webConstants = useSelector(getWebConstants);
  const projectType = useSelector(getProjectType);
  const componentCost = solarPanelCost + inverterCost;
  const filtered = useSelector(ProductSelectors.getAllFilteredProducts);

  const cheapestSolarPanel = useMemo(() => {
    return getCheapestProduct(filtered.solarPanels);
  }, [filtered]);

  const solarStorage =
    (cheapestSolarPanel?.applicableMaxCapacity / 1000) * project?.components?.solarPanel?.quantity;

  const allConstantsCost = webConstants.constants.reduce(
    (acc, constant) =>
      acc +
      getExtraCostForConstant({
        constant,
        projectType,
        allComponentsCost: componentCost,
        solarPanelProduction: energy.solarPanelProduction,
        solarPanelQty: solarPanel.quantity,
      }),
    0,
  );
  const totalCost = allConstantsCost + componentCost;

  const calculatedCost = totalCost / (energy.solarPanelProduction * 25);
  const { isBatteryAdded } = useIsComponentAdded();
  const cheapestbatteries = useMemo(() => {
    return getCheapestProduct(filtered.batteries);
  }, [filtered]);

  const totalSystemConfigurationList = [
    {
      label: "Modulanzahl",
      value: solarPanel.quantity,
      isVisible: true,
    },
    {
      label: "Jahresertrag",
      value: `${formatNumberByLang(energy.solarPanelProduction.toFixed(0))} kWh`,
      isVisible: true,
    },
    {
      label: "Verbrauch",
      value: `${formatNumberByLang(household?.consumption.toFixed(0))} kWh`,
      isVisible: true,
    },
    {
      label: "Photovoltaikanlage",
      value: `${solarStorage ? formatNumberByLang(solarStorage.toFixed(0)) : 0} kWp`,
      isVisible: true,
    },
    {
      label: "Batterie",
      value: `${
        cheapestbatteries
          ? formatNumberByLang(Number(cheapestbatteries?.applicableMaxCapacity / 1000).toFixed(0))
          : 0
      } kWh`,
      isVisible: isBatteryAdded,
    },
    {
      label: "Warmwasser",
      value: `Heizstab`,
      isVisible: project?.components?.waterHeating,
    },
    {
      label: "Dachneigung",
      value: `${energyProdParams?.roofPitch}°`,
      isVisible: true,
    },
  ];

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <PageHeading heading="Investition und Ertrag im Vergleich" />
      <View style={styles.mainContainer}>
        <HeadingWithLogo
          heading={`Detailberechnung: Laufzeit ${project.financialDetails.expectedReturnTime} ${t(
            "YEARS",
          )}`}
        />
        <View style={styles.makeItFlex}>
          <View style={styles.table_left}>
            <InvestMentChart lossPosition={19} profitPossition={14} />
          </View>
          <View style={styles.table_right}>
            <InvestMentDetails />
          </View>
        </View>
        <View>
          <HeadingWithLogo heading="Berechnungsgrundlagen" />
          <View style={styles.makeItFlex2}>
            <View style={styles.table_left_below}>
              <View style={styles.monthlyChartScreenShot}>
                <View
                  style={{ display: "flex", flexDirection: "row", gap: 8, alignItems: "center" }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      backgroundColor: "#ffbf1b",
                    }}
                  />
                  <Text style={{ ...commanStyles.static_text }}>Jahresgewinn</Text>
                </View>
                {investMentChartScreenShot && <Image src={investMentChartScreenShot} />}
              </View>
            </View>
            <View style={styles.table_right_below}>
              <View style={styles.box}>
                {totalSystemConfigurationList.map((data, index) => {
                  if (!data.isVisible) return <></>;
                  return (
                    <View
                      key={data.label}
                      style={{
                        ...styles.boxDetails,
                        borderBottom: totalSystemConfigurationList.length - 1 === index ? 0 : 0.74,
                      }}
                    >
                      <View style={{ width: "50%" }}>
                        <Text style={styles.boxDetailsText}>{data.label}</Text>
                      </View>
                      <View style={styles.boxDetailsConsumption}>
                        <Text style={styles.static_text}>{data.value}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
        <View>
          <HeadingWithLogo heading="Produktionskosten" />
        </View>
        <View style={{ width: "70%" }}>
          <Text style={styles.static_text2}>
            1 kWh Solarstrom von der eigenen Anlage kostet dich:
          </Text>
          <View style={styles.flexRow}>
            <Text style={styles.static_text3}>Dein Solarstrompreis / kWh</Text>
            <Text style={styles.static_text23}>
              {formatNumberByLang(calculatedCost.toFixed(2))} €-Cent
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.lastContainer}>
        <Text style={{ ...commanStyles.static_text, paddingBottom: 5 }}>Was Bedeutet Ertrag?</Text>
        <Text style={commanStyles.static_text_light}>
          Erträge sind die gesamten Einsparungen durch Eigenstromproduktion und Einnahmen aus dem
          Stromverkauf, abzüglich Betriebskosten über Nutzungsdauer der Photovoltaikanlage / des
          Batteriespeichers
        </Text>
      </View>
      <Footer pageNumber={4} />
    </View>
  );
};

export default InvestMentOverview;
