import { Delete, Pause, Rotate90DegreesCcw } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { IconButton, LinearProgress, Switch } from "@mui/material";
import Slider from "@mui/material/Slider";
import { GoogleMap, Polygon, LoadScript, OverlayView, GroundOverlay } from "@react-google-maps/api";
import { Popover } from "antd";
import axios from "axios";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ThirdPartyApis } from "src/api";

import { useWindowDimensions } from "src/hooks";

import { parseReverseGeoGoogle } from "src/utils";

import { LayerId } from "./solar";
import { MapContainer } from "./SolarLayout.styles";
import { getLayer } from "./SolarLayoutHelper";

import { ProjectActions } from "src/redux/actionCreators";
import { getAddress, getSolarPanel } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";
import { setSolarAPIData } from "src/redux/solarAPI/action/action.creators";
import { getSolarAPIData } from "src/redux/solarAPI/selector";
import { toast } from "react-toastify";

interface SolarLayoutProps {
  height?: number;
  width?: number;
  center: {
    lat: number;
    lng: number;
  };
  setFluxMonth: (key: number) => void;
  fluxMonth: number;
  selectedOption: string;
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function convertLatLng(
  latLngList: google.maps.LatLng[],
  i: number,
): Array<{ lat: number; lng: number }> {
  return latLngList.map((latLng, index) => ({
    lat: latLng.lat(),
    lng: latLng.lng(),
  }));
  // _id: `${index * i + 2}`, // Generate a unique _id for each object
}

const SolarLayout: React.FC<SolarLayoutProps> = ({
  setFluxMonth,
  selectedOption,
  fluxMonth,
  ...props
}) => {
  const { height, width } = useWindowDimensions();
  const containerStyle = {
    height: props.height || height,
    width: props.width || width,
  };
  const prevCenter: any = useRef(null);
  const panelCountRef: any = useRef();
  const mapRef: any = useRef(null);
  const dispatch = useDispatch();
  const solarAPIData = useSelector(getSolarAPIData);
  const solarPanels = useSelector(getSolarPanel);
  const quoteAddress = useSelector(getAddress);

  const [selectedPolygonIndex, setSelectedPolygonIndex] = useState<any>(null);
  const [center, setCenter] = useState<any>({});
  const [buildingCenter, setBuildingCenter] = useState<any>(null);

  const [solar_panels, setSolar_panels] = useState([]);
  const data = solar_panels;

  const [path, setPath] = useState<any>(data);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const [fluxCanvas, setFluxCanvas] = useState<any>(null);
  const [showRoofOnly, setShowRoofOnly] = useState<boolean>(true);
  const [render, setRender] = useState(0);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (solar_panels?.length && !path?.length) {
      const data = solar_panels.map((elem: any, index: number) => {
        // elem.id = index;
        return elem;
      });
      setPath(data);
    }
  }, [solar_panels]);

  const polygonRef: any = useRef(Array.from({ length: solar_panels?.length }, () => null));

  const [solarPotential, setSolarPotential] = useState(null);
  const [yearlyEnergyDcKwhValue, setYearlyEnergyDcKwh] = useState(0);
  const [projection, setProjection] = useState<any>(null);

  const [map, setMap] = React.useState(null);
  const [start, setStart] = useState<boolean>(false);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const onMapLoad = (map: any) => {
    setMap(map);
    mapRef.current = map;
    window.google.maps.event.addListenerOnce(map, "projection_changed", function () {
      setProjection(map?.getProjection());
    });

    window.google.maps.event.addListener(map, "bounds_changed", onBoundsChanged);

    // Function to handle bounds_changed event
    function onBoundsChanged() {
      const mapDiv = map.getDiv().firstChild;
      if (mapDiv.clientHeight === window.innerHeight && mapDiv.clientWidth === window.innerWidth) {
        setIsFullscreen(true);
      } else {
        setIsFullscreen(false);
      }
    }
  };

  useEffect(() => {
    if (
      projection != null &&
      (prevCenter.current == null ||
        (prevCenter?.current.lat != props.center.lat &&
          prevCenter?.current.lng != props.center.lng))
    ) {
      prevCenter.current = props.center;
      getBuildingInsights(props.center)
        .then((buildingInsights: any) => {
          setBuildingCenter(buildingInsights.center);

          setCenter(buildingInsights.center);
          dispatch(setSolarAPIData(buildingInsights));
          const action = ProjectActions.updateSolarPanel({
            quantity: buildingInsights.solarPotential.maxArrayPanelsCount,
          });
          const actionUpdateSolarPanelQuantity = ProjectActions.updateModuleQuantity(
            buildingInsights.solarPotential.maxArrayPanelsCount,
          );
          dispatch(action);
          dispatch(actionUpdateSolarPanelQuantity);
          plotSolar(
            buildingInsights,
            projection,
            buildingInsights.solarPotential.maxArrayPanelsCount,
          );
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }, [projection, props.center]);

  useEffect(() => {
    if (
      projection != null &&
      (panelCountRef.current === null || panelCountRef.current?.solar_count != solarPanels.quantity)
    ) {
      panelCountRef.current = solarPanels.quantity;
      if (!solarAPIData) return;

      plotSolar(solarAPIData, projection, solarPanels.quantity);
    }
  }, [solarPanels.quantity, projection]);

  const getBoundingBoxAroundCoordinate = (
    center: { latitude: number; longitude: number },
    widthMeters: number,
    heightMeters: number,
  ) => {
    const earthRadius = 6371000;

    const centerLatRadians = (center.latitude * Math.PI) / 180;
    const centerLngRadians = (center.longitude * Math.PI) / 180;

    const latOffset = (heightMeters / earthRadius) * (180 / Math.PI);
    const lngOffset = (widthMeters / (earthRadius * Math.cos(centerLatRadians))) * (180 / Math.PI);

    const rectangle = [
      { lat: center.latitude + latOffset / 2, lng: center.longitude - lngOffset / 2 },
      { lat: center.latitude + latOffset / 2, lng: center.longitude + lngOffset / 2 },
      { lat: center.latitude - latOffset / 2, lng: center.longitude + lngOffset / 2 },
      { lat: center.latitude - latOffset / 2, lng: center.longitude - lngOffset / 2 },
    ];

    return rectangle;
  };

  const rotatePolygon = (
    polygonCoords: any[],
    center: { lat: any; lng: any },
    angle: number,
    projection: { fromLatLngToPoint: (arg0: any) => any; fromPointToLatLng: (arg0: any) => any },
  ) => {
    const centerPixel = projection.fromLatLngToPoint(center);
    const polygonPixels = polygonCoords.map((coord) => projection.fromLatLngToPoint(coord));

    const rotatedPolygonPixels = polygonPixels.map((pixel) => {
      const x =
        centerPixel.x +
        (pixel.x - centerPixel.x) * Math.cos(angle * (Math.PI / 180)) -
        (pixel.y - centerPixel.y) * Math.sin(angle * (Math.PI / 180));
      const y =
        centerPixel.y +
        (pixel.x - centerPixel.x) * Math.sin(angle * (Math.PI / 180)) +
        (pixel.y - centerPixel.y) * Math.cos(angle * (Math.PI / 180));
      return new window.google.maps.Point(x, y);
    });

    const rotatedPolygonCoords = rotatedPolygonPixels.map((pixel) =>
      projection.fromPointToLatLng(pixel),
    );
    return rotatedPolygonCoords;
  };

  const plotSolar = async (
    buildingInsights: {
      name?: string;
      center?: { latitude: number; longitude: number };
      imageryDate?: { year: number; month: number; day: number };
      regionCode?: string;
      solarPotential: any;
      boundingBox?: {
        sw: { latitude: number; longitude: number };
        ne: { latitude: number; longitude: number };
      };
      imageryQuality?: string;
      imageryProcessedDate?: { year: number; month: number; day: number };
    },
    projection: any,
    LIMIT_COUNT: number,
  ) => {
    setSolar_panels([]);
    const solarPotential = buildingInsights.solarPotential;
    setSolarPotential(solarPotential);
    const placed_panels: any[] = [];
    const widthMeters = solarPotential.panelHeightMeters;
    const heightMeters = solarPotential.panelWidthMeters;
    let count = 0;

    let yearlyEnergyDcKwh = 0;
    // const yearlyEnergyDcKwhMAX =
    //   solarPotential.solarPanelConfigs[solarPotential.solarPanelConfigs.length - 1]
    //     .yearlyEnergyDcKwh;
    let panelsArray: any = [];
    solarPotential.solarPanelConfigs.forEach(
      (
        config: { panelsCount: number; yearlyEnergyDcKwh: number; roofSegmentSummaries: any[] },
        i: any,
      ) => {
        if (config.panelsCount === LIMIT_COUNT) {
          yearlyEnergyDcKwh = config.yearlyEnergyDcKwh;
        }
        config.roofSegmentSummaries.forEach((s) => {
          const segmentIndex = s.segmentIndex;
          const panelsCount = s.panelsCount;
          const panels = solarPotential.solarPanels.filter(
            (p: any) => p.segmentIndex === segmentIndex,
          );
          for (let i = 0; i < panels.length; i++) {
            if (count >= LIMIT_COUNT) return;
            if (i < panelsCount) {
              const objectMerged = { ...panels[i], ...s };
              if (!placed_panels.some((e: any) => e.center === objectMerged.center)) {
                placed_panels.push(objectMerged);
                let boundBox;
                if (objectMerged.orientation === "PORTRAIT") {
                  const heightMeters = solarPotential.panelHeightMeters;
                  const widthMeters = solarPotential.panelWidthMeters;
                  boundBox = getBoundingBoxAroundCoordinate(
                    objectMerged.center,
                    widthMeters,
                    heightMeters,
                  );
                } else {
                  boundBox = getBoundingBoxAroundCoordinate(
                    objectMerged.center,
                    widthMeters,
                    heightMeters,
                  );
                }
                const rotationCenter = {
                  lat: objectMerged.center.latitude,
                  lng: objectMerged.center.longitude,
                };
                const finalCoords: any = rotatePolygon(
                  boundBox,
                  rotationCenter,
                  objectMerged.azimuthDegrees,
                  projection,
                );
                objectMerged.paths = convertLatLng(finalCoords, i);
                panelsArray.push(objectMerged);
                count++;
              }
            }
          }
        });
      },
    );
    setYearlyEnergyDcKwh(yearlyEnergyDcKwh);

    panelsArray = panelsArray.map((el: any, index: any) => {
      el.angle = 0;
      el.fakeAngle = 0;
      el.quoteId = quoteId;
      return el;
    });

    // const data: any = await createBulkPanels(panelsArray);
    // if (data.data.message === "Panel already exists") {
    // const updatedBakeModel: any = await handleBulkUpdate(panelsArray);
    const newPaths = panelsArray.map((elem: any, index: number) => {
      elem._id = index;
      // elem._id = data.data.createdPanels[index]._id;
      // elem.paths = data.data.createdPanels[index].paths;
      return elem;
    });

    setPath(newPaths);
    setSolar_panels(newPaths);
    // } else {
    // setSolar_panels(data.data.createdPanels);
    // }
  };

  async function createBulkPanels(panelsArray: any) {
    try {
      let data = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/solar-panel/bulk-panel-create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ panelsArray, quoteId }),
        },
      );
      data = await data.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async function getBuildingInsights(homeLocation: { lat: any; lng: any }) {
    const endpoint = `https://solar.googleapis.com/v1/buildingInsights:findClosest?location.latitude=${
      homeLocation.lat
    }&location.longitude=${homeLocation.lng}&requiredQuality=MEDIUM&key=${
      process.env.REACT_APP_GOOGLE_MAP_API_KEY as string
    }`;
    return await new Promise((resolve, reject) => {
      try {
        fetch(endpoint)
          .then(async (response) => await response.json())
          .then((result) => {
            if (result.error) {
              reject(result.error);
            } else {
              resolve(result);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (err) {
        reject(err);
      }
    });
  }

  const listenersRef: any = useRef<any>([]);
  const [buttonsPosition, setButtonsPosition] = useState<any>(null);

  const calculateCentroid = (polygonPath: any) => {
    const centroid = polygonPath.reduce(
      (acc: any, point: any) => {
        acc.x += point.lat;
        acc.y += point.lng;
        acc.z += 1;
        return acc;
      },
      { x: 0, y: 0, z: 0 },
    );
    return new window.google.maps.LatLng(centroid.x / centroid.z, centroid.y / centroid.z);
  };

  const onPolygonClick = (panelId: number) => {
    const selectedPolygon: any = solar_panels.find((p: any) => p._id === panelId);
    setCenter(selectedPolygon.center);
    const centroid = calculateCentroid(selectedPolygon.paths);
    setButtonsPosition(centroid);
  };

  const onEdit = (index: any, draggedElement: any) => {
    if (polygonRef.current[index]) {
      const nextPath = polygonRef.current[index]
        .getPath()
        .getArray()
        .map((latLng: any) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      let polyData;
      if (solar_panels.length > 0 && solar_panels) polyData = solar_panels;
      else polyData = path;
      const centroid = nextPath.reduce(
        (acc: any, point: any) => {
          return { lat: acc.lat + point.lat, lng: acc.lng + point.lng };
        },
        { lat: 0, lng: 0 },
      );

      centroid.lat /= nextPath.length;
      centroid.lng /= nextPath.length;

      let updatedObj: any = {};
      const updatedData = polyData.map((el: any) => {
        if (el._id === draggedElement?._id) {
          el.paths = nextPath;
          el.center.latitude = centroid.lat;
          el.center.longitude = centroid.lng;
          updatedObj = el;
        }
        return el;
      });
      handleUpdatePolygon(updatedObj, quoteId, updatedObj?._id).then((res) =>
        console.log(res, "THIS IS UPDATED"),
      );
      setPath(updatedData);
    }
  };

  const handleUpdatePolygon = async (updatedPanel: any, quoteId: any, panelId: any) => {
    try {
      let data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/solar-panel/update-panel`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ updatedPanel, quoteId, panelId }),
      });

      data = await data.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const handleBulkUpdate = async (panels: any) => {
    try {
      let data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/solar-panel/bulk-update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ panels }),
      });

      data = await data.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const onLoad = useCallback(
    (index: string | number, solar_panels: any, loadedPolygon: any) => (polygon: any) => {
      polygonRef.current[index] = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", () => onEdit(index, loadedPolygon)),
        path.addListener("insert_at", () => onEdit(index, loadedPolygon)),
        path.addListener("remove_at", () => onEdit(index, loadedPolygon)),
      );
    },
    [onEdit],
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
  }, []);

  const rotateSelectedPolygon = (angle: any, direction: any, toUpdate: any) => {
    if (selectedPolygonIndex !== null) {
      let updatedSolarPanels: any = [...path];

      const selectedPolygon: any = updatedSolarPanels.find(
        (item: any) => item._id === selectedPolygonIndex,
      );
      const rotationCenter = {
        lat: selectedPolygon?.center?.lat || selectedPolygon?.center?.latitude,
        lng: selectedPolygon?.center?.lng || selectedPolygon?.center?.longitude,
      };
      const adjustedAngle = direction === "clockwise" ? selectedPolygon?.angle + angle : -angle;
      const rotatedPaths = rotatePolygon(
        selectedPolygon?.paths,
        rotationCenter,
        adjustedAngle,
        projection,
      );
      selectedPolygon.paths = rotatedPaths;
      selectedPolygon.fakeAngle = angle;
      if (toUpdate) {
        selectedPolygon.angle = angle;
      }

      updatedSolarPanels = updatedSolarPanels.map((elem: any) => {
        if (elem._id === selectedPolygonIndex) return selectedPolygon;
        else return elem;
      });

      setSolar_panels(updatedSolarPanels);
      setPath(updatedSolarPanels);

      if (polygonRef.current?.[selectedIndex]) {
        polygonRef.current[selectedIndex].setPath(rotatedPaths);
      }

      return selectedPolygon;
    }
  };

  const [degree, setDegree] = useState<number>(0);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    let interval: any;
    if (start) {
      interval = setInterval(() => {
        setDegree((prevDegree) => prevDegree + 1);
      }, 100);
    }
    return () => clearInterval(interval);
  }, [start]);

  useEffect(() => {
    const updatedObj = rotateSelectedPolygon(1, "clockwise", true);
    if (!start && clicked) {
      handleUpdatePolygon(updatedObj, quoteId, updatedObj?._id).then((res) =>
        console.log(res, "THIS IS UPDATED"),
      );
    }
  }, [degree, start]);

  const handleDeletePanel = async (index: number) => {
    try {
      const solarPanelsColone = cloneDeep(solar_panels)?.filter(
        (p: any) => p._id !== selectedPolygonIndex,
      );
      setSolar_panels(solarPanelsColone);
      setPath(solarPanelsColone);
      setSelectedPolygonIndex(null);
      const action = ProjectActions.updateSolarPanel({
        quantity: Number(solarPanelsColone.length),
      });
      const actionUpdateSolarPanelQuantity = ProjectActions.updateModuleQuantity(
        Number(solarPanelsColone.length),
      );
      dispatch(action);
      dispatch(actionUpdateSolarPanelQuantity);
      return solarPanelsColone;
      // let deletePanel: any = await fetch(
      //   `${process.env.REACT_APP_BACKEND_URL}/api/solar-panel/delete-panel`,
      //   {
      //     method: "DELETE",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ panelId: selectedPolygonIndex, quoteId }),
      //   },
      // );

      // deletePanel = await deletePanel.json();

      // setSolar_panels(deletePanel.data.remainingPanels);
      // setPath(deletePanel.data.remainingPanels);
      // setSelectedPolygonIndex(null);
      // const action = ProjectActions.updateSolarPanel({
      //   quantity: Number(deletePanel.data.remainingPanels.length),
      // });
      // const actionUpdateSolarPanelQuantity = ProjectActions.updateModuleQuantity(
      //   Number(deletePanel.data.remainingPanels.length),
      // );
      // dispatch(action);
      // dispatch(actionUpdateSolarPanelQuantity);
      // return deletePanel.data;
    } catch (err) {
      console.log(err);
    }
  };

  const [polygonSize, setPolygonSize] = useState<number>(100);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPolygonSize(parseInt(event.target.value));
    updatePolygonSize(event.target.value);
  };

  const updatePolygonSize = (newSize: any) => {
    const updatedPath = data.map((el: any) => {
      const centroid = el.paths.reduce(
        (acc: any, point: any) => {
          acc.lat += point.lat;
          acc.lng += point.lng;
          return acc;
        },
        { lat: 0, lng: 0 },
      );
      centroid.lat /= el.paths.length;
      centroid.lng /= el.paths.length;

      const distances = el.paths.map((coord: any) => ({
        lat: coord.lat - centroid.lat,
        lng: coord.lng - centroid.lng,
      }));

      const scaledDistances = distances.map((dist: any) => ({
        lat: dist.lat * (newSize / 100),
        lng: dist.lng * (newSize / 100),
      }));

      const scaledCoords = scaledDistances.map((scaledDist: any) => ({
        lat: centroid.lat + scaledDist.lat,
        lng: centroid.lng + scaledDist.lng,
      }));

      return { ...el, paths: scaledCoords };
    });
    setPath(updatedPath);
  };

  const elementRef = useRef<any>(null);
  const [dynamicHeight, setDynamicHeight] = useState<any>(0);
  const [dynamicWidth, setDynamicWidth] = useState<any>(0);

  useEffect(() => {
    setTimeout(() => {
      if (elementRef.current && !dynamicHeight) {
        const distanceFromTop = elementRef.current.getBoundingClientRect().top + window.scrollY;
        const distanceFromRight =
          window.innerWidth -
          (elementRef.current.getBoundingClientRect().left + elementRef.current.offsetWidth);
        setDynamicHeight(distanceFromTop - 20);
        setDynamicWidth(distanceFromRight - 400);
      }
    }, 500);
  }, [isFullscreen]);

  function toRadians(degrees: any) {
    return (degrees * Math.PI) / 180;
  }

  function haversineDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2 - lat1);
    const Δλ = toRadians(lon2 - lon1);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters
    return distance;
  }

  const getLayers = useCallback(async () => {
    const respose = await axios.get(
      ThirdPartyApis.googleMapsAutocompleteQuery(quoteAddress.complete),
    );
    const {
      data: { predictions },
    } = respose.data;

    if (predictions.length === 0) return;

    if (predictions[0].place_id && solarAPIData) {
      const buildingInsights = solarAPIData;
      const ne = buildingInsights?.boundingBox.ne;
      const sw = buildingInsights?.boundingBox.sw;
      const diameter = haversineDistance(ne.latitude, ne.longitude, sw.latitude, sw.longitude);
      const radius = Math.ceil(diameter / 2);

      const mapboxAddr = await parseReverseGeoGoogle(predictions[0].place_id);
      const solarUrl = `https://solar.googleapis.com/v1/dataLayers:get?location.latitude=${mapboxAddr?.geometry.coordinates[1]}&location.longitude=${mapboxAddr?.geometry.coordinates[0]}&radiusMeters=${radius}&view=FULL_LAYERS&requiredQuality=HIGH&pixelSizeMeters=0.5&key=AIzaSyB4NpPwTSm3pChudxdAky_J2qBdGMlR0G8`;

      const response = await fetch(solarUrl);
      const images = await response.json();
      console.log({ selectedOption }, "inside layer");
      const layer = await getLayer(
        selectedOption === "ANNUAL" ? "annualFlux" : "monthlyFlux",
        {
          monthlyFluxUrl: images.monthlyFluxUrl,
          annualFluxUrl: images.annualFluxUrl,
          maskUrl: images.maskUrl,
          imageryQuality: "HIGH",
        },
        "AIzaSyB4NpPwTSm3pChudxdAky_J2qBdGMlR0G8",
      );
      return layer;
    }

    return null;
  }, [haversineDistance, quoteAddress.complete, selectedOption, solarAPIData]);

  const renderTiff = useCallback(async () => {
    try {
      if (selectedOption === "HIDE") {
        return null;
      }

      setLoader(true);
      const layer = await getLayers();
      if (!layer) {
        return null;
      }
      const temp = layer.render(showRoofOnly, 0, 0);

      await setFluxCanvas({ bounds: layer.bounds, data: temp });
      setRender((prev) => prev + 1);
    } catch (error) {
      toast.info("No solar data available for this location");
      console.log(error);
    } finally {
      setLoader(false);
    }
  }, [getLayers, selectedOption, showRoofOnly]);

  useEffect(() => {
    void renderTiff();
  }, [quoteAddress, selectedOption, solarAPIData]);

  return (
    <>
      <MapContainer>
        <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyB4NpPwTSm3pChudxdAky_J2qBdGMlR0G8"
          language="en"
          region="us"
        >
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            mapContainerClassName="map-container"
            center={{
              lat: center?.latitude || 50.19554053545494,
              lng: center?.longitude || 8.590209225857638,
            }}
            zoom={19}
            options={{
              mapTypeId: "satellite",
              tilt: 0,
              streetViewControl: false,
              rotateControl: false,
            }}
            onLoad={onMapLoad}
          >
            {path?.map((el: any, index: number) => {
              return (
                <>
                  <Popover
                    content={<div>Selected Polygon: {el._id}</div>}
                    trigger="click"
                    visible={selectedPolygonIndex === el._id}
                    key={index}
                  >
                    <Polygon
                      draggable
                      path={el?.paths}
                      onDragEnd={(e) => {
                        onEdit(index, el);
                      }}
                      onLoad={onLoad(index, solar_panels, el)}
                      onUnmount={onUnmount}
                      options={{
                        strokeColor: "black",
                        strokeOpacity: 0.6,
                        strokeWeight: 1,
                        fillColor: "#81daf6",
                        fillOpacity: 0.8,
                      }}
                      onDrag={() => {
                        setSelectedPolygonIndex(null);
                      }}
                      onClick={(e) => {
                        setSelectedPolygonIndex(el?._id);
                        onPolygonClick(el?._id);
                        setSelectedIndex(index);
                      }}
                    />
                  </Popover>
                  {selectedPolygonIndex && (
                    <OverlayView
                      position={buttonsPosition}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        style={{
                          padding: "10px",
                          width: "fit-content",
                          transform: "translate(-50px, 10px)",
                          display: "flex",
                          gap: "6px",
                        }}
                      >
                        <IconButton
                          size="small"
                          style={{
                            background: "white",
                          }}
                          onClick={async (e: any) => {
                            e.stopPropagation();
                            await handleDeletePanel(index);
                          }}
                        >
                          <Delete />
                        </IconButton>
                        <IconButton
                          size="small"
                          style={{
                            background: "white",
                          }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setStart(!start);
                            setClicked(true);
                          }}
                        >
                          {!start ? <Rotate90DegreesCcw /> : <Pause />}
                        </IconButton>
                      </div>
                    </OverlayView>
                  )}
                </>
              );
            })}
            {fluxCanvas && selectedOption === "ANNUAL" && (
              <GroundOverlay
                key={`${showRoofOnly ? "roof" : "solar"}_${selectedOption}_${render}`}
                bounds={fluxCanvas?.bounds}
                url={fluxCanvas?.data?.[0]?.toDataURL()}
              ></GroundOverlay>
            )}
            {fluxCanvas && selectedOption === "MONTHLY" && (
              <GroundOverlay
                key={`${showRoofOnly ? "roof" : "solar"}_${fluxMonth}_${render}`}
                bounds={fluxCanvas?.bounds}
                url={fluxCanvas?.data?.[fluxMonth]?.toDataURL()}
              ></GroundOverlay>
            )}
            <OverlayView
              position={{
                lat: buildingCenter?.latitude,
                lng: buildingCenter?.longitude,
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div
                style={{
                  padding: "1px",
                  width: "fit-content",
                  // transform: "translate(-50%, 0px)",
                  display: "flex",
                  position: "fixed",
                  // gap: '6px',
                  zIndex: 1000,
                  background: "white",
                  borderRadius: "5px",
                  marginTop: "350px",
                }}
              >
                <IconButton
                  size="small"
                  disableRipple
                  style={{
                    zIndex: 1000,
                    // width: "100px",
                    margin: "5px 5px",
                  }}
                  title="Toggle solar flux visibility"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowRoofOnly((prev) => !prev);
                    if (fluxMonth === 6) {
                      setFluxMonth(5);
                    } else {
                      setFluxMonth(6);
                    }
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {showRoofOnly ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
                </IconButton>
              </div>
            </OverlayView>
          </GoogleMap>
        </LoadScript>
      </MapContainer>
      {loader && <LinearProgress />}
    </>
  );
};

export default SolarLayout;
