import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Image, Text, View } from "@react-pdf/renderer";

import PageHeading from "../../components/pageHeading/pageHeading";
import Footer from "../../components/Footer/Footer";

import { getImages } from "../../../redux/project/selectors";
import commanStyles from "../../comanStyles/commanStyles";
import styles from "./BuildingsDetailsStyles";

const BuildingsDetails = () => {
  const images = useSelector(getImages);

  const combinedImaes = useMemo(() => {
    const imageData = [
      ...images?.houseTop?.imageURLs,
      ...images?.houseBottom?.imageURLs,
      ...images?.electricMeter?.imageURLs,
    ]

    return  imageData.map((image) => {
      if(typeof image === "string"){
        return image;
      }
      if(typeof image === "object") {
        return image?.url;
      }
      return image;
    })
},[images]);



  const combinedDescription =
    images?.houseTop?.description +
    " " +
    images?.houseBottom?.description +
    " " +
    images?.electricMeter?.description;

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <PageHeading heading="Details zum Gebäude" />
      <View style={styles.mainContainer}>
        <View style={{ ...styles.images, marginTop: 10 }}>
          <Text style={{ ...commanStyles.static_text, padding: 10 }}>
            Fotos vom Gebäude / Stromkasten
          </Text>
          <View style={styles.imagesContainer}>
            <View
              style={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {combinedImaes.map((image) => {
                return (
                  <Image
                    style={styles.img}
                    src={`${image}?${Math.random() * 1000}${new Date()}`}
                    key={image?.id}
                    source={{ header: { "Access-Control-Allow-Origin": "*" } }}
                    cache={false}
                  />
                );
              })}
            </View>
          </View>
        </View>
        <Text style={{ ...commanStyles.static_text, marginTop: 10 }}>Beschreibung</Text>
        <View style={styles.description}>
          <Text style={commanStyles.static_text_light}>{combinedDescription}</Text>
        </View>
      </View>
      <Footer pageNumber={6} />
    </View>
  );
};

export default BuildingsDetails;
