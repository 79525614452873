import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";

export const WrapperOfMaterialUiSelect = styled("div")`
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
    padding-right: 0px !important;
  }
`;

export const useStyles = makeStyles(() => ({
  selectHalper: {
    height: "45px",
    zIndex: "0",
    paddingLeft: "15px",
    margin: "0px",
    width: "90px",
    border: "none",
    borderRadius: "0px",
    marginTop: "0px",
    "@media (max-width: 560px)": {
      margin: "0px",
    },
  },
  image: {
    width: "50px",
    height: "auto",
  },
}));
