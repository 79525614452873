import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        paddingLeft: 30,
        paddingRight: 30
    },
    backGround: {
        backgroundColor: "#FFD75D",
        padding: 10
    },
    backGround2: {
        backgroundColor: "#DFDFDF",
        padding: 10,
        marginTop: 10,
        opacity: 0.5 
    },
    backGround4: {
        backgroundColor: "#DFDFDF",
        padding: 10,
        marginTop: 10
    },
    backGround3: {
        padding: 10,
        paddingBottom: 15,
        paddingHorizontal: 20,
        display: "flex",
        flexDirection: "row",
        width: 320,
        justifyContent: "space-between"
    },
    sameContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    sameContainerTextSmall: {
        fontWeight: 700,
        fontSize: 13,
        paddingBottom: 5,
        color: "#2D4764"
    },
    sameContainerTextSmallLight: {
        fontWeight: 700,
        fontSize: 11,
        maxWidth: 90,
        color: "#666666"  
    },
    sameContainerTextGrey: {
        fontWeight: 700,
        fontSize: 14,
        color: "#666666" 
    },
    sameContainerText: {
        fontWeight: 700,
        fontSize: 16,
        color: "#2D4764"
    },
    description: {
      border: 0.76,
      borderColor: colors.greyLight,
      borderRadius: 5,
      height: "30%"
    },
    images: {
      height: "50%",
      border: 0.76,
      borderRadius: 5,
      borderColor: colors.greyLight,
    },
    imagesContainer: {
      display: "flex",
      flexDirection:"row",
      flexWrap: "wrap"
    }
})

export default styles;