import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

import { useIsComponentAdded } from "../../../hooks/useIsComponentAdded";
import { useYearlySystemProfit } from "../../../hooks/useYearlySystemProfit";
import { formatNumberByLang } from "../../../hooks/useNumberFormat";

import { getFinancialDetails } from "../../../redux/project/selectors";
import { projectStyles } from "../../components/ProjectDetail/ProjectDetail";
import { useTranslation } from "react-i18next";

const InvestMentDetails = () => {
  const financialDetails = useSelector(getFinancialDetails);
  const { areCarsAdded, isHeatpumpAdded } = useIsComponentAdded();
  const { t } = useTranslation();
  const { profitByYear } = useYearlySystemProfit();
  const {
    profit,
    electricityBillSavingsTillNow,
    feedInSavingsTillNow,
    mobilitySavingTillNow,
    gasOilBillSavingsTillNow,
    evGridCostTillNow,
    heatpumpGridCostTillNow,
  } = profitByYear(financialDetails.expectedReturnTime);

  return (
    <View style={projectStyles.mainContainer2}>
      <View style={projectStyles.subContainer91}>
        <View style={projectStyles.subContainer95}>
          <View style={projectStyles.subContainer96}>
            <View style={projectStyles.subContainer92} />
            <Text style={projectStyles.subContainer93}>{t("FEED IN TARIFF")}</Text>
          </View>
          <Text style={projectStyles.subContainer94}>
            + {formatNumberByLang(feedInSavingsTillNow.toFixed(0))}€
          </Text>
        </View>
        <View style={projectStyles.subContainer95}>
          <View style={projectStyles.subContainer96}>
            <View style={projectStyles.subContainer921} />
            <Text style={projectStyles.subContainer93}>{t("SAVING IN BILL")}</Text>
          </View>
          <Text style={projectStyles.subContainer94}>
            + {formatNumberByLang(electricityBillSavingsTillNow.toFixed(0))}€
          </Text>
        </View>
        {areCarsAdded && (
          <View style={projectStyles.subContainer95}>
            <View style={projectStyles.subContainer96}>
              <View style={projectStyles.subContainer922} />
              <Text style={projectStyles.subContainer93}>{t("Savings - mobility bill")}</Text>
            </View>
            <Text style={projectStyles.subContainer94}>
              + {formatNumberByLang(mobilitySavingTillNow.toFixed(0))}€
            </Text>
          </View>
        )}
        {isHeatpumpAdded && (
          <View style={projectStyles.subContainer95}>
            <View style={projectStyles.subContainer96}>
              <View style={projectStyles.subContainer923} />
              <Text style={projectStyles.subContainer93}>{t("Savings - heat bill")}</Text>
            </View>
            <Text style={projectStyles.subContainer94}>
              + {formatNumberByLang(gasOilBillSavingsTillNow.toFixed(0))}€
            </Text>
          </View>
        )}
      </View>
      {areCarsAdded && (
        <View style={projectStyles.subContainer9115}>
          <View style={projectStyles.subContainer95}>
            <View style={projectStyles.subContainer96}>
              <View style={projectStyles.subContainer925} />
              <Text style={projectStyles.subContainer93}>{t("Grid electricity cost- EV")}</Text>
            </View>
            <Text style={projectStyles.subContainer94}>
              - {formatNumberByLang(evGridCostTillNow.toFixed(0))}€
            </Text>
          </View>
        </View>
      )}
      {isHeatpumpAdded && (
        <View style={projectStyles.subContainer9115}>
          <View style={projectStyles.subContainer95}>
            <View style={projectStyles.subContainer96}>
              <View style={projectStyles.subContainer925} />
              <Text style={projectStyles.subContainer93}>
                {t("Grid electricity cost- heat pump")}
              </Text>
            </View>
            <Text style={projectStyles.subContainer94}>
              - {formatNumberByLang(heatpumpGridCostTillNow.toFixed(0))}€
            </Text>
          </View>
        </View>
      )}
      <View style={projectStyles.subContainer9111}>
        <View style={projectStyles.subContainer95}>
          <View style={projectStyles.subContainer96}>
            <View style={projectStyles.subContainer924} />
            <Text style={projectStyles.subContainer93}>
              {!financialDetails?.isFinancing
                ? t("Investment / Baukosten")
                : t("Financing / building cost")}
            </Text>
          </View>
          <Text style={projectStyles.subContainer94}>
            - {formatNumberByLang(financialDetails.costs.appliedProjectCost.toFixed(0))}€
          </Text>
        </View>
      </View>
      <View style={projectStyles.subContainer9112}>
        <View style={projectStyles.subContainer96}>
          <View style={projectStyles.subContainer9241} />
          <Text style={projectStyles.subContainer931}>{t("PROFIT WITH SOLAR SYSTEM")}</Text>
        </View>
        <Text style={projectStyles.subContainer941}>{formatNumberByLang(profit.toFixed(0))}€</Text>
      </View>
      <View style={projectStyles.subContainer9113}>
        <Text style={projectStyles.subContainer932}>{t("AMORTISATION TIME")}</Text>
        <Text style={projectStyles.subContainer942}>
          {formatNumberByLang(financialDetails.amortizationTime.toFixed(0))} {t("YEARS")}
        </Text>
      </View>
    </View>
  );
};

export default InvestMentDetails;
