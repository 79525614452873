import { Select } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors } from "src/redux/selectors";

import { ParameterStyles } from "src/shared/StyledComponents";

const MeasuringOperation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { measuringPoint } = useSelector(ProjectSelectors.getHousehold);

  const handleChange = useCallback(
    (value: string): void => {
      dispatch(
        ProjectActions.updateHousehold({
          measuringPoint: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <ParameterStyles.Line
      hasSlider
      style={{ paddingInline: "10px", width: "93%" }}
    >
      <ParameterStyles.Label style={{ color: "black" }}>
        {t("MEASURING POINT OPERATION")}
      </ParameterStyles.Label>
      <Select
        defaultValue={measuringPoint}
        style={{ width: 150 }}
        onChange={handleChange}
        options={[
          { value: "Metergrid rental", label: "Metergrid rental " },
          { value: "Metergrid buying", label: "Metergrid buying" },
          { value: "own system", label: "own system" },
        ]}
      />
    </ParameterStyles.Line>
  );
};

export default MeasuringOperation;
