import { Slider, Tooltip } from "antd";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useYearlySystemProfit } from "src/hooks";

import { EditIcon } from "src/assets/svgs";
import { InfoIcon } from "src/shared/components";
import { ParameterStyles } from "src/shared/StyledComponents";

import * as S from "../../../../components/ApplicationStep3/components/UploadCard/components/CardHeader/CardHeader.styles";

import { hoverReducer, InitialState } from "./hover.reducer";
import MainBody, { ReturnOnInvestCalculations } from "./MainBody/MainBody";
import { Header, HeaderText, MainContainer } from "./styles";

import { ProjectActions } from "src/redux/actionCreators";
import { ProjectSelectors, UserSelectors } from "src/redux/selectors";
import { getRentingData } from "src/redux/user/selectors";

const defaultCalculations: ReturnOnInvestCalculations = {
  buildingCost: 0,
  financingCost: 0,
  feedInTariff: 0,
  mobilitySavings: 0,
  gasSavings: 0,
  EVGridCost: 0,
  heatpumpGridCost: 0,
  cars: [],
  totalCarsCost: 0,
  profit: 0,
  loss: 0,
  savingsElectricityBill: 0,
};
interface IMonthlyElectricityCost {
  isRenting: boolean;
}

const ReturnOnInvest: React.FC<IMonthlyElectricityCost> = ({
  isRenting = false,
}: IMonthlyElectricityCost) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sliderSwitch, setSliderSwitch] = useState(false);
  const [calculations, setCalculations] = useState<ReturnOnInvestCalculations>(defaultCalculations);

  const [hoverStore, hoverDispatch] = useReducer(hoverReducer, InitialState);

  const { expectedReturnTime } = useSelector(ProjectSelectors.getFinancialDetails);
  const cars = useSelector(ProjectSelectors.getCars);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const rentingData = useSelector(getRentingData);
  const simplify = useSelector(UserSelectors.getSimplify);

  const {
    costs: { totalFinancingCost, totalProjectCost },
  } = useSelector(ProjectSelectors.getFinancialDetails);

  const { profitByYear } = useYearlySystemProfit();

  const text: string = `${t("DIE GESETZLICHE")} 20 ${t("JAHRE FEST")} 21 ${t("WEITERHIN")}`;

  useEffect(() => {
    const data = profitByYear(expectedReturnTime, isRenting);

    const calc: ReturnOnInvestCalculations = { ...defaultCalculations };
    if (!isRenting) {
      calc.buildingCost = totalProjectCost;
    } else {
      calc.buildingCost =
        Number(rentingData?.rate_with_vat) *
        12 *
        (expectedReturnTime > 20 ? 20 : expectedReturnTime);
    }
    calc.financingCost = Math.max(totalFinancingCost - totalProjectCost, 0);
    calc.feedInTariff = data.feedInSavingsTillNow;
    calc.savingsElectricityBill = data.electricityBillSavingsTillNow;
    calc.mobilitySavings = data.mobilitySavingTillNow;
    calc.gasSavings = data.gasOilBillSavingsTillNow;

    calc.EVGridCost = data.evGridCostTillNow;
    calc.heatpumpGridCost = data.heatpumpGridCostTillNow;
    calc.cars = [];
    cars?.forEach((doc) => {
      const car = doc.item;
      const isNotOnLease = !doc.isLeasing;
      if (car && isNotOnLease) {
        const priceKey = projectType ? "priceB2b" : "priceB2c";
        const cost = Math.round(car[priceKey] * 25 * 12);

        calc.cars.push({
          name: car.name,
          cost,
        });
      }
    });
    calc.totalCarsCost = data.carBuyingCost;

    calc.profit = data.profit;
    calc.loss = calc.savingsElectricityBill;

    setCalculations(calc);
  }, [
    cars,
    expectedReturnTime,
    profitByYear,
    projectType,
    totalFinancingCost,
    totalProjectCost,
    isRenting,
  ]);

  const expectedYearsSliderHandler = (value: number): void => {
    dispatch(
      ProjectActions.updateFinancialDetails({
        expectedReturnTime: value,
      }),
    );
  };

  return (
    <MainContainer>
      {sliderSwitch ? (
        <ParameterStyles.Line hasSlider>
          <ParameterStyles.Label hasSlider>{t("TIME FRAME")}</ParameterStyles.Label>
          <ParameterStyles.Input>
            <Slider onChange={expectedYearsSliderHandler} value={expectedReturnTime} max={35} />
          </ParameterStyles.Input>
          <ParameterStyles.Value hasSlider>
            {expectedReturnTime} {t("YEARS")}
          </ParameterStyles.Value>
        </ParameterStyles.Line>
      ) : (
        !simplify && (
          <Header>
            <HeaderText>{`${t("TIME FRAME")}: ${expectedReturnTime} ${t("YEARS")}`}</HeaderText>
            <div
              onClick={() => {
                setSliderSwitch(true);
              }}
            >
              <EditIcon />
            </div>
            <Tooltip placement="topRight" title={text}>
              <S.MainText>
                <InfoIcon />
              </S.MainText>
            </Tooltip>
          </Header>
        )
      )}
      <MainBody
        calculations={calculations}
        hoverState={hoverStore}
        hoverDispatch={hoverDispatch}
        isRenting={isRenting}
      />
    </MainContainer>
  );
};

export default ReturnOnInvest;
