import { MouseEventHandler, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  ConsumptionProfileLine,
  ElectricalWaterHeatingLine,
  EnergyConsumptionPerEmployee,
  Heading,
  HomeType,
  HouseholdSupplyInputLine,
} from "./components";
import AmountOfPersonLine from "./components/AmountOfPersonLine/AmountOfPersonLine";
import BeHouseHold from "./components/BeHouseHold/BeHouseHold";
import ElectricityPrice from "./components/ElectricityPrice/ElectricityPrice";
import ExpectedHardware from "./components/ExpectedHardware/ExpectedHardware";
import ExpectedSoftware from "./components/ExpectedSoftware/ExpectedSoftware";
import ExpectedSolar from "./components/ExpectedSolar/ExpectedSolar";
import ExpectedTenants from "./components/ExpectedTenants/ExpectedTenants";
import GeneralElectricityConsumption from "./components/GeneralElectricityConsumption/GeneralElectricityConsumption";
import MeasuringOperation from "./components/MeasuringOperation/MeasuringOperation";
import SystemConsept from "./components/SystemConcept/SystemConsept";
import { ElectricityConsumptionWrapper } from "./ElectricityConsumptionEditor.styles";

import { ProjectSelectors } from "src/redux/selectors";

interface ElectricityConsumptionEditorProps {
  onCloseBtnClick: MouseEventHandler;
}
const ElectricityConsumptionEditor: React.FC<ElectricityConsumptionEditorProps> = (
  props: ElectricityConsumptionEditorProps,
) => {
  const { onCloseBtnClick } = props;

  const projectType = useSelector(ProjectSelectors.getProjectType);
  const { buildingType } = useSelector(ProjectSelectors.getHousehold);

  const [heading, setHeading] = useState("");

  useEffect(() => {
    if (projectType === "b2c") setHeading("BASIC ELECTRICITY CONSUMPTION");
    else setHeading("Consumption configuration");
  }, [projectType]);

  return (
    <ElectricityConsumptionWrapper>
      <div className="main_container">
        <Heading text={heading} onCloseIconClick={onCloseBtnClick} />
        <ConsumptionProfileLine />
        <HomeType />
        <HouseholdSupplyInputLine />
        {buildingType === "multi" && (
          <>
            <SystemConsept />
            <BeHouseHold />
            <ExpectedTenants />
            <ElectricityPrice />
            <ExpectedSolar />
            {/* <ExpectedHardware />
            <ExpectedSoftware /> */}
            <MeasuringOperation /> <GeneralElectricityConsumption />
          </>
        )}

        {buildingType !== "multi" && <AmountOfPersonLine />}
        {projectType === "b2c" && <ElectricalWaterHeatingLine />}
        {projectType === "b2b" && <EnergyConsumptionPerEmployee />}
      </div>
    </ElectricityConsumptionWrapper>
  );
};

export default ElectricityConsumptionEditor;
