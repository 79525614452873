import { StyleSheet } from "@react-pdf/renderer";
import colors from "../../Utils/colors";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    padding: 30,
    paddingRight: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "white"
  },
  solarhubLogo: {
    width: 160,
    height: 60,
    marginBottom: 20
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  containerHeaderLeftPart: {
    display: "flex",
    flexDirection: "column"
  },
  containerHeaderLeftPartTextContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 1
  },
  flexBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginTop: 60
  },
  same_css: {
    display: "flex",
    flexDirection: "column"
  },
  same_css_two: {
    display: "flex",
    flexDirection: "column",
    marginRight: 40
  },
  textContainerText: {
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: 23,
    color: colors.primaryBlue,
    paddingLeft: 10,
    zIndex: 0
  },
    textContainerText2: {
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: 23,
    marginLeft: 26,
    color: colors.primaryBlue,
    marginTop: 4,
    paddingLeft: 10,
    zIndex: 0
  },
  flotingBox: {
    position: "absolute",
    top: 17,
    backgroundColor: colors.primaryYellow,
    width: 240,
    height: 20.47,
    zIndex: 0
  },
  secoundFlotingBox: {
    position: "absolute",
    top: 47,
    marginLeft: 25,
    marginTop: 4,
    backgroundColor: colors.primaryYellow,
    width: 260,
    height: 20.47,
    zIndex: 0
  },
  rightContainer: {
    display: "flex",
    flexDirection:"row"
  },
  address: {
    display: "flex",
    marginRight: 20,
    alignItems: "flex-end"
  },
  addressHeading: {
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 16,
    color: colors.primaryBlue,
  },
  addressText: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
    marginTop: 10
  },
    addressText2: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
    marginTop: 5
  },
  static_text: {
    fontFamily: "Nunito",
    fontWeight: 600,
    fontSize: 14,
    color: colors.primaryBlue,
  },
  dotImage: {
    width: 60,
    height: 150
  },
  textWrapper: {
    width: 200, 
    flexWrap: 'wrap',
  }
});

export default styles;
