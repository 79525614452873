import axios from "axios";
import earcut from "earcut";
import { toast } from "react-toastify";
import SunCalc from "suncalc";
import { TextureLoader } from "three";
import { MTLLoader } from "three/addons/loaders/MTLLoader.js";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import {
  CSS2DObject,
  // CSS2DRenderer,
} from "three/examples/jsm/renderers/CSS2DRenderer";

import panelObj from "src/assets/solar_panel.glb.meshopt.glb";
import solarModule from "src/assets/step3/solarmodule.glb";
import {
  Planet,
  Light,
  HomeYellow,
  PlanetYellow,
  MesureYellow,
  DroneYellow,
  PopupYellow,
  SolarPanelYellow,
  BlueSolarPanel,
  SolarIcon,
  PaintBucket,
  Lock,
  plusIcon,
  deleteIcon,
  RotateIcon,
  MoveIcon,
  SolarMtl,
  SolarObj,
  textureFile,
} from "src/assets/step33dViwer";
import CabelingYellowIcon from "src/assets/step33dViwer/cabelingyellow.svg";
import CloseIcon from "src/assets/step33dViwer/Close.svg";
import DeleteIcon from "src/assets/step33dViwer/DeleteIcon.svg";
import DownIcon from "src/assets/step33dViwer/downIcon.svg";
import DragIcon from "src/assets/step33dViwer/DragIcon.svg";
import UpIcon from "src/assets/step33dViwer/UpIcon.svg";
import { MeshBVH, acceleratedRaycast } from "src/js/three-mesh-bvh.js";

import { update3dQuote } from "./api/backend-endpoints";
import { BackendApis } from "./api/index";
import { ApiAxiosClient } from "./axios";
import Environment from "./js/Environment.js";
import CanvasRecorder from "./js/lib/CanvasRecorder.js";
import Renderer3 from "./js/lib/renderer3.js";
import showLoaderProgress from "./js/loadprogress.js";
import PanelGrid from "./js/panelgrid.js";
import { ProjectActions } from "./redux/actionCreators";
import { panelIcons, rightPanelIcons } from "./utils/threeDViewer";

const url =
  "https://re.jrc.ec.europa.eu/api/PVcalc?lat=49.19627939999999&lon=9.2292092&peakpower=0.408&loss=2.5&aspect=176&angle=30&outputformat=json&pvtechchoice=crystSi";

export default function createProto({
  threejsEl,
  popupRef,
  linesGroupRef,
  dragControls,
  mtlUrl,
  objUrl,
  quoteDetails,
  address,
  project,
  capacity,
  dispatch,
  from3DViwer,
  maxWidth3DViewer,
  maxWidth3DViewerWithImages,
  t,
  productObjFiles,
}) {
  const app = new Renderer3(threejsEl);
  let offsetX;
  // let offsetY;
  let deleteObject;
  let plusObject;
  const isDraggingDiv = false;
  let lines = [];
  const distanceObjects = [];
  let cylinder;
  let clickOccurred = false;
  let clickTimer;
  const DOUBLE_CLICK_THRESHOLD = 300;
  let terrainGLB;
  let mapName;
  let drawingLine = false;

  let panelLayoutActive = false;
  const panelGrids = [];

  const measurementLabels = {};
  let startPoint1;
  const lineId = 0;
  let line;
  const polygons = [];
  const shapeMeshes = [];
  const polygonLabelsArray = [];
  const popupContainer = document.createElement("div");
  const solargisDataPopupContainer = document.createElement("div");
  const popupContent = document.createElement("div");
  const popupContent2 = document.createElement("div");
  const smallContainer = document.createElement("div");
  const smallContainer2 = document.createElement("div");
  const closeButton = document.createElement("img");
  const closeButton2 = document.createElement("img");
  const editModeRadio = document.createElement("input");
  const createModeRadio = document.createElement("input");
  const dragButton = document.createElement("img");
  let mode = "";
  const header = document.createElement("h2");
  popupContainer.className = "popup-container";
  popupContainer.style.cssText =
    "position: absolute; bottom: 5px; right: 10px; background-color: #fff; border: 1px solid #ccc; box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); border-radius: 10px; width: 300px; gap: 5px; z-index: 999; max-height: 300px; overflow-y: scroll;";
  popupContent.className = "popup-content";
  popupContent.style.cssText =
    "display: flex; flex-direction: column; align-items: center; padding: 0 0 10px 0; gap: 10px; width: 100%; position: relative;";
  smallContainer.style.cssText =
    "position: sticky; top: 5px; background-color: #fff; z-index: 9999;";
  dragButton.src = DragIcon;
  dragButton.alt = "DragIcon";
  dragButton.id = "DragIcon";
  dragButton.style.cssText =
    "height: 25px; width: 25px; margin: 5px auto; cursor: pointer; position: relative;";
  header.innerText = "Measurement Details";
  header.style.cssText = "color: #333; font-size: 16px; font-weight: bold; text-align: center;";
  closeButton.src = CloseIcon;
  closeButton.alt = "CloseIcon";
  closeButton.id = "CloseIcon";
  closeButton.style.cssText =
    "height: 25px; width: 25px; margin: 5px; cursor: pointer; position: absolute; right:5px; top:10px;";

  smallContainer.appendChild(closeButton);
  smallContainer.appendChild(dragButton);
  smallContainer.appendChild(header);

  popupContainer.appendChild(smallContainer);
  const handleClosePopup = () => {
    if (popupContainer && threejsEl.current.contains(popupContainer)) {
      threejsEl.current.removeChild(popupContainer);
    }
  };
  closeButton.addEventListener("click", handleClosePopup);

  const headerSolargis = document.createElement("h2");
  solargisDataPopupContainer.className = "popup-container";
  solargisDataPopupContainer.style.cssText =
    "position: absolute; bottom: 5px; right: 10px; background-color: #fff; border: 1px solid #ccc; box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); border-radius: 10px; width: 300px; gap: 5px; z-index: 999; max-height: 300px; overflow-y: scroll;";
  popupContent2.className = "popup-content2";
  popupContent2.style.cssText =
    "display: flex; flex-direction: column; align-items: center; padding: 0 0 10px 0; gap: 10px; width: 100%; position: relative;";
  smallContainer2.style.cssText =
    "position: sticky; top: 5px; background-color: #fff; z-index: 9999;";
  dragButton.src = DragIcon;
  dragButton.alt = "DragIcon";
  dragButton.id = "DragIcon";
  dragButton.style.cssText =
    "height: 25px; width: 25px; margin: 5px auto; cursor: pointer; position: relative;";
  headerSolargis.innerText = t("Solar Panel Datas");
  headerSolargis.style.cssText =
    "color: #333; font-size: 16px; font-weight: bold; text-align: center;";
  closeButton2.src = CloseIcon;
  closeButton2.alt = "CloseIcon";
  closeButton2.id = "CloseIcon";
  closeButton2.style.cssText =
    "height: 25px; width: 25px; margin: 5px; cursor: pointer; position: absolute; right:5px; top:10px;";

  smallContainer2.appendChild(closeButton2);
  smallContainer2.appendChild(dragButton);
  smallContainer2.appendChild(headerSolargis);

  solargisDataPopupContainer.appendChild(smallContainer2);
  const handleSolargisClosePopup = () => {
    if (solargisDataPopupContainer && threejsEl.current.contains(solargisDataPopupContainer)) {
      threejsEl.current.removeChild(solargisDataPopupContainer);
    }
  };
  closeButton2.addEventListener("click", handleSolargisClosePopup);

  const draggableDiv = document.createElement("div");
  draggableDiv.style.backgroundColor = "#fbc35a";
  draggableDiv.style.color = "#000000";
  draggableDiv.style.padding = "5px";
  draggableDiv.style.borderRadius = "10px";
  draggableDiv.style.position = "absolute";
  draggableDiv.style.left = "50px";
  draggableDiv.style.top = "50px";
  draggableDiv.style.opacity = "0";

  const {
    THREE,
    // ThreeBSP,
    // physics,
    // gui,
    scene,
    camera,
    controls,
    // listen,
    // nv3,
    io,
    glbLoader,
    // buttons,
    renderer,
    labelRenderer,
    flow,
  } = app;
  const {
    Color,
    Vector3,
    Box3,
    Raycaster,
    SphereGeometry,
    BoxGeometry,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Float32BufferAttribute,
    MOUSE,
    AdditiveBlending,
    DirectionalLight,
  } = THREE;

  const raycaster = new Raycaster();
  /*
  let sphereMesh = null;
  const sphereGeometry = new SphereGeometry(0.4, 32, 32);
  const sphereMaterial = new MeshBasicMaterial({
    color: 0xffffff,
    opacity: 0.3,
    transparent: true,
    depthTest: false,
  });
  */
  const { abs, random, min, max, PI } = Math;

  const destroy = () => {
    destroyed = true;
    if (started) {
      scene.clear();
      renderer.forceContextLoss();
      renderer.domElement.parentElement.removeChild(renderer.domElement);
      // labelRenderer.domElement.parentElement.removeChild(labelRenderer.domElement);
      console.log("prototype created");
    }
  };
  const environment = new Environment(app);
  let destroyed = false;
  let started = false;
  const start = async () => {
    io.startRendering();
    showLoaderProgress(THREE, 2);

    await environment.setupEnvironment(scene);
    scene.background = new Color("lightblue");

    // CanvasRecorder(renderer.domElement, threejsEl.current);

    renderer.domElement.style.cursor = "crosshair";
    // let terrainGLB = await glbLoader.loadAsync('./assets/terrainGLB.glb.meshopt.glb')

    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();
    scene.add(linesGroupRef.current);

    const loadOBJ = async (url, onProgress) => {
      try {
        // Load materials and set them to the OBJ loader
        const materials = await mtlLoader.loadAsync(mtlUrl);
        objLoader.setMaterials(materials);

        // Load the OBJ model
        const scene = await objLoader.loadAsync(objUrl);

        // If onProgress callback is provided, execute it
        if (onProgress) {
          onProgress();
        }

        // Return the loaded scene
        return { scene };
      } catch (error) {
        console.error("Error loading OBJ model:", error);
        throw error;
      }
    };

    const changeIconColor = (createModeButton, icon, isResetIcon) => {
      if (isResetIcon) {
        createModeButton.src = icon;
        createModeButton.classList.remove("activeIcon");
        return;
      }
      createModeButton.className = "activeIcon";
    };

    const resetIcons = (key) => {
      panelIcons.forEach((icon) => {
        const iconElement = document.getElementById(icon.key);
        if (iconElement && icon.key !== key) {
          changeIconColor(iconElement, icon.key === "planet" ? Planet : icon.image, true); // Assume White is a constant representing the white color
        }
      });
    };

    //   const handlePaning = (event) => {
    //     if (isShiftPressed && event.button === 0) {
    //       controls.enablePan = true;
    //       controls.update();
    //     }
    //   };

    const hidePopup = () => {
      if (document.querySelector(".manage_point_popup")) {
        threejsEl.current.removeChild(popupRef.current);
        popupRef.current = null;
      }
    };

    const handleModeChange = (newMode) => {
      hidePopup();
      clickOccurred = true;
      mode = newMode;
    };

    const createRadioContainer = () => {
      const radioContainer = document.createElement("div");
      radioContainer.className = "radio-container";
      radioContainer.style.cssText =
        "position: absolute; top: 10px; left: 50%; transform: translateX(-50%); color: white; border-radius: 20px; background: rgba(0, 0, 0, 0.60); padding: 10px; z-index: 9999";

      const createRadio = (mode, label) => {
        const radio = document.createElement("input");
        radio.type = "radio";
        radio.name = "modeRadio";
        radio.value = mode;
        radio.style.cssText = "color: black; position: relative; top: 1px;";
        radio.addEventListener("change", () => handleModeChange(mode));
        return radio;
      };

      const createLabel = (text) => {
        const label = document.createElement("label");
        label.innerHTML = text;
        label.style.cssText = "margin-left: 3px; color: white;";
        label.addEventListener("click", () => {
          handleModeChange(text);
          createModeRadio.checked = text === "Create mode";
          editModeRadio.checked = text === "Edit mode";
        });
        return label;
      };

      const createSeparator = () => {
        const separator = document.createElement("span");
        separator.style.cssText = "border-left: 1px solid white; height: 20px; margin: 0 10px;";
        return separator;
      };

      const createModeRadio = createRadio("Create mode", "Create mode");
      const createModeLabel = createLabel("Create mode");
      const editModeRadio = createRadio("Edit mode", "Edit mode");
      const editModeLabel = createLabel("Edit mode");

      createModeLabel.appendChild(createSeparator());

      radioContainer.append(createModeRadio, createModeLabel, editModeRadio, editModeLabel);

      threejsEl.current.appendChild(radioContainer);

      handleModeChange("Create mode");
      createModeRadio.checked = true;
      editModeRadio.checked = false;
    };

    const toggleFullScreen = () => {
      const element = renderer.domElement;

      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          void document.exitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          threejsEl.current.requestFullscreen();
        }
      }
    };

    function isFullScreen() {
      return document.fullscreenElement;
    }
    function getIntersections(event) {
      // const rect = document.body.getBoundingClientRect();
      const rect = threejsEl.current.getBoundingClientRect();

      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      const mouse = new THREE.Vector2(x, y);

      raycaster.setFromCamera(mouse, camera);
      if (terrainGLB?.scene) {
        const intersects = raycaster.intersectObjects(terrainGLB.scene.children, true);

        return intersects;
      }
    }

    function createOrUpdateCylinder(startPoint1, endPoint) {
      if (cylinder) {
        scene.remove(cylinder);
      }

      if (!startPoint1 && !endPoint) {
        return;
      }

      const height = startPoint1?.distanceTo(endPoint);

      cylinder = addLine(startPoint1, endPoint);
      measurementLabels[lineId].element.innerText = height.toFixed(2) + "m";
      measurementLabels[lineId].position.lerpVectors(startPoint1, endPoint, 0.5);
      scene.add(cylinder);
    }

    const handleDragStart = () => {
      clickOccurred = true;
      controls.enabled = false;
    };

    const handleDragEnd = (event) => {
      controls.enabled = true;
      clickOccurred = true;

      const draggedPoint = event.object;
      const newPosition = draggedPoint.position.clone();

      const intersects = raycaster.intersectObject(terrainGLB.scene);

      if (intersects.length > 0) {
        const surfaceNormal = intersects[0].face.normal;

        const offsetDistance = 0.1;
        newPosition
          .copy(intersects[0].point)
          .add(surfaceNormal.clone().multiplyScalar(offsetDistance));
      }
      draggedPoint.position.copy(newPosition);

      updateLineGeometry();
      calculateAndShowPopup(polygons);
    };
    let lastMouseMoveEvent;
    function handleMouse(event) {
      if (drawingLine) {
        if (startPoint1) {
          // Calculate mouse position in normalized device coordinates (-1 to +1)
          const intersects = getIntersections(event);

          // Check if there's an intersection
          if (intersects.length > 0) {
            // endPoint = intersects[0].point.clone();
            createOrUpdateCylinder(startPoint1, intersects[0].point.clone());
            return;
          }
        }
      }

      const isInFullScreen = !!isFullScreen();
      const rect = threejsEl.current.getBoundingClientRect();

      const x1 = event.clientX - rect.left;
      const y1 = event.clientY - rect.top;

      if (isDraggingDiv) {
        const x = isInFullScreen ? event.clientX - offsetX : x1;
        const y = isInFullScreen ? event.clientY + 20 : y1;
        draggableDiv.style.left = `${x}px`;
        draggableDiv.style.top = `${y}px`;
      }

      if (mode === "Edit mode" && dragControls.current) {
        dragControls.current.addEventListener("dragstart", handleDragStart);
        dragControls.current.addEventListener("dragend", handleDragEnd);
        dragControls.current.addEventListener("drag", updateLineGeometry);
      }
      // if (false)
      if (terrainGLB?.scene) {
        const intersect = raycaster.intersectObject(terrainGLB.scene, true);

        if (terrainGLB.scene && intersect.length === 0) {
          if (cylinder) {
            scene.remove(cylinder);
            scene.remove(measurementLabels[lineId]);
          }
        }
      }
    }
    flow.start(function* (event) {
      while (true) {
        if (lastMouseMoveEvent) {
          handleMouse(lastMouseMoveEvent);
          lastMouseMoveEvent = false;
        }
        yield 0;
      }
    });
    const onDocumentMouseMove = (event) => {
      // if(panelLayoutActive){
      //   if(!event.buttons)
      //    return;
      // if(!inDrag)
      //  return;
      // }
      event.preventDefault();
      lastMouseMoveEvent = event;
    };
    let measurementPoints = [];

    const clearPoints = () => {
      measurementPoints = [];
    };

    const calculateDistance = (point1, point2) => {
      return point1.distanceTo(point2);
    };

    const calculatePolygonArea = (points) => {
      const filterPoints = points.filter((_, index) => index % 2 === 0);
      const flatVertices = filterPoints?.map((vertex) => [vertex.x, vertex.z]).flat();

      const triangles = earcut(flatVertices);

      let totalArea = 0;
      for (let i = 0; i < triangles.length; i += 3) {
        const v0 = new THREE.Vector3(
          filterPoints[triangles[i]].x,
          filterPoints[triangles[i]].y,
          filterPoints[triangles[i]].z,
        );
        const v1 = new THREE.Vector3(
          filterPoints[triangles[i + 1]].x,
          filterPoints[triangles[i + 1]].y,
          filterPoints[triangles[i + 1]].z,
        );
        const v2 = new THREE.Vector3(
          filterPoints[triangles[i + 2]].x,
          filterPoints[triangles[i + 2]].y,
          filterPoints[triangles[i + 2]].z,
        );
        totalArea += computeTriangleArea(v0, v1, v2);
      }

      return totalArea;
    };

    function computeTriangleArea(v0, v1, v2) {
      const e1 = v1.clone().sub(v0);
      const e2 = v2.clone().sub(v0);
      const crossProduct = e1.cross(e2);
      return crossProduct.length() / 2;
    }

    const calculatePolygonPerimeter = (points) => {
      let perimeter = 0;
      const n = points.length;

      for (let i = 0; i < n; i++) {
        const j = (i + 1) % n;

        perimeter += calculateDistance(points[i], points[j]);
      }

      return perimeter;
    };

    const lineMeshGeometry = new THREE.CylinderGeometry(0.07, 0.07, 1, 6);
    lineMeshGeometry.rotateX(-PI * 0.5);
    const lineMeshMaterial = new THREE.MeshBasicMaterial({
      color: 0x22d94a,
      depthTest: false,
    });
    const lineTemplate = new THREE.Mesh(lineMeshGeometry, lineMeshMaterial);
    lineTemplate.renderOrder = 5;

    const addLine = (point1, point2) => {
      const distance = point1.distanceTo(point2);
      const direction = new THREE.Vector3().copy(point2).sub(point1);
      // Create a mesh using the geometry and material
      const line = lineTemplate.clone();
      line.scale.z = distance;
      // Position the line at the midpoint between the two points
      line.position.copy(point1).lerp(point2, 0.5);
      line.quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction.normalize());
      // Align the line with the direction between the two points
      line.lookAt(point2);

      return line;
    };

    const createLine = (
      point1,
      point2,
      closedLoop = false,
      points,
      polygonIndex,
      index,
      point3,
    ) => {
      const pointsPositions = points;
      const distanceLabel = document.createElement("div");
      distanceLabel.className = "measurementLabel";
      distanceLabel.style.fontWeight = "bold";
      distanceLabel.style.color = "#fff";
      distanceLabel.style.borderRadius = "10px";
      distanceLabel.style.padding = "3px 7px";
      distanceLabel.style.background = "rgba(0, 0, 0, 0.60)";
      distanceLabel.style.zIndex = "999999";
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(pointsPositions.length * 3);

      for (let i = 0; i < pointsPositions.length; i++) {
        positions[i * 3] = pointsPositions[i].x;
        positions[i * 3 + 1] = pointsPositions[i].y;
        positions[i * 3 + 2] = pointsPositions[i].z;
      }

      geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
      geometry.setFromPoints(pointsPositions);

      const indexArray = [];

      if (closedLoop && pointsPositions.length > 2) {
        for (let i = 0; i < pointsPositions.length; i++) {
          indexArray.push(i, (i + 1) % pointsPositions.length);
        }
        geometry.setIndex(indexArray);

        if (!polygonLabelsArray[polygonIndex]) {
          createLabels(polygonIndex, pointsPositions);
        } else {
          const labels = polygonLabelsArray[polygonIndex];
          labels.areaLabel.innerText = `Area: ${calculatePolygonArea(pointsPositions).toFixed(
            2,
          )} m²`;
          labels.perimeterLabel.innerText = `Perimeter: ${calculatePolygonPerimeter(
            pointsPositions,
          ).toFixed(2)} m`;
        }

        const removedShapeMesh = shapeMeshes.splice(polygonIndex, 1)[0];
        scene.remove(removedShapeMesh);

        const points2d = pointsPositions.map((v3) => new THREE.Vector2(v3.x, v3.z));

        const shapePositions = new THREE.Shape(points2d);

        shapePositions.autoClose = true;

        const geomShape = new THREE.ShapeGeometry(shapePositions);

        const pos = geomShape.attributes.position;

        const pointMap = [];
        for (let i = 0; i < pos.count; i++) {
          const p = new THREE.Vector2(pos.getX(i), pos.getY(i));
          let nearestDist = Infinity;
          let nearestIndex;
          for (let j = 0; j < points2d.length; j++) {
            // pos.setZ(i, -pointsPositions[pos.count - 1 - i].y);
            const d = points2d[j].distanceTo(p);
            if (d < nearestDist) {
              nearestIndex = j;
              nearestDist = d;
            }
          }
          pointMap.push(nearestIndex);
        }

        for (let i = 0; i < pos.count; i++) pos.setZ(i, -pointsPositions[pos.count - 1 - i].y);

        for (let i = 0; i < pos.count; i++) pos.setZ(i, -pointsPositions[pointMap[i]].y);
        pos.needsUpdate = true;
        geomShape.computeVertexNormals();

        const matShape = new THREE.MeshBasicMaterial({
          color: 0x22d94a,
          side: THREE.DoubleSide,
          transparent: true,
          opacity: 0.2,
          depthTest: false,
        });
        const shape = new THREE.Mesh(geomShape, matShape);
        shape.userData = { index: editor.document.polygons.length - 1 };
        shape.rotation.x = PI * 0.5;

        scene.add(shape);

        shapeMeshes.push(shape);
        shapeMeshes.splice(polygonIndex, 0, shape);
      } else {
        scene.remove(shapeMeshes[polygonIndex]);
        scene.remove(distanceObjects[polygonIndex]);
        scene.remove(polygonLabelsArray[polygonIndex]);

        shapeMeshes.splice(polygonIndex, 1);
        distanceObjects.splice(polygonIndex, 1);
        polygonLabelsArray.splice(polygonIndex, 1);
      }
      if (point1 && point2) {
        // const textDistance = point1.distanceTo(point2);
        const textDistance = point1.distanceTo(closedLoop ? point3 : point2);
        const cylindermidPoint = point1.clone().lerp(point2, 0.5);
        const line = addLine(point1, point2);
        editor.addMeasurementLine(line);

        if (index % 2 === 0) {
          distanceLabel.innerText = `${textDistance.toFixed(2)}m`;

          const distanceLabelObject = new CSS2DObject(distanceLabel);
          // Set the position of the label to the midpoint between point1 and point2
          // const midPoint = new THREE.Vector3().copy(point1).lerp(point2, 0.5);
          distanceLabelObject.position.copy(cylindermidPoint);
          editor.addMeasurementLine(distanceLabelObject);

          // linesGroupRef.current.add(distanceLabelObject);
        }
      }
    };

    const updateLineGeometry = (dragging) => {
      editor.clearMeasurementPointsandLines(false);

      if (dragging) {
        updatePointBetweenPoints(editor.document.polygons);
      }
      if (editor.document.polygons?.length > 0) {
        editor.document.polygons?.forEach((el, index) => {
          const points1 = el.map((point) => point.position);

          if (points1.length > 1) {
            for (let i = 0; i < points1.length; i++) {
              createLine(
                points1[i],
                points1[(i + 1) % points1.length],
                true,
                points1,
                index,
                i,
                points1[(i + 2) % points1.length],
              );
            }
          }
        });
      }
      if (measurementPoints.length) {
        const points1 = measurementPoints.map((point) => point.position);
        // if (points1.length > 1) {
        for (let i = 0; i < points1.length; i++) {
          createLine(
            points1[i],
            points1[i + 1],
            false,
            points1,
            editor.document.polygons.length,
            0,
          );
        }
        // }
      }
    };

    const createLabels = (polygonIndex, pointsPositions) => {
      const distanceLabel = document.createElement("div");
      distanceLabel.className = "measurementLabel";
      distanceLabel.style.fontWeight = "bold";
      distanceLabel.style.color = "#fff";
      distanceLabel.style.borderRadius = "10px";
      distanceLabel.style.padding = "3px 7px";
      distanceLabel.style.background = "rgba(0, 0, 0, 0.60)";
      distanceLabel.style.zIndex = "999999";

      const areaLabel = document.createElement("div");
      areaLabel.className = "measurementLabel";
      areaLabel.innerText = `Area: ${calculatePolygonArea(pointsPositions).toFixed(2)} m²`;

      const perimeterLabel = document.createElement("div");
      perimeterLabel.className = "measurementLabel";
      perimeterLabel.style.fontWeight = "bold";
      perimeterLabel.innerText = `Perimeter: ${calculatePolygonPerimeter(pointsPositions).toFixed(
        2,
      )} m`;

      distanceLabel.appendChild(areaLabel);
      distanceLabel.appendChild(perimeterLabel);

      const middlePoint = findMiddlePoint(pointsPositions);
      const distanceLabelObject = new CSS2DObject(distanceLabel);
      distanceLabelObject.position.copy(middlePoint);
      distanceObjects.push(distanceLabelObject);
      scene.add(distanceLabelObject);

      if (!polygonLabelsArray[polygonIndex]) {
        polygonLabelsArray[polygonIndex] = {};
      }

      polygonLabelsArray[polygonIndex] = {
        areaLabel,
        perimeterLabel,
        distanceLabelObject,
      };
    };

    const updatePointBetweenPoints = (polygons) => {
      polygons.forEach((polygon) => {
        // Iterate over each point in the polygon
        polygon.forEach((point, index) => {
          // Check if the index is even and not the last point in the polygon
          if (index % 2 === 0) {
            const point1 = point;
            const point2 = polygon[(index + 1) % polygon.length];
            const point3 = polygon[(index + 2) % polygon.length];

            // Calculate the midpoint between two consecutive points
            const midpoint = new THREE.Vector3()
              .copy(point1.position)
              .add(point3.position)
              .multiplyScalar(0.5);

            // Update the position of the current point
            point2.position.copy(midpoint);
          }
        });
      });
    };

    function onClick(event) {
      clearTimeout(clickTimer);
      if (polygons.flat(2).length > 0) {
        // const intersectPoint = raycaster.intersectObjects(polygons.flat(2), true);
        // startPoint = intersectPoint[0].object.position;
      }
      clickTimer = setTimeout(() => {
        // Single click logic
        handleMouseDownDocument(event);
      }, DOUBLE_CLICK_THRESHOLD);
    }

    const calculateAndShowPopup = (polygons) => {
      const element = document.querySelector(".popup-content");
      if (element) {
        element.innerHTML = "";
      }
      const areas = [];
      const perimeters = [];
      polygons.map((ele, index) => {
        const points = ele.map((point) => point.position).filter((_, i) => i % 2 === 0);

        const area = calculatePolygonArea(ele.map((point) => point.position));

        areas.push(area);
        const perimeter = calculatePolygonPerimeter(ele.map((point) => point.position));
        perimeters.push(perimeter);

        const heading = document.createElement("div");
        heading.style.display = "flex";
        heading.style.alignItems = "center";
        heading.style.gap = "10px";
        heading.style.position = "relative";

        const header = document.createElement("h2");
        header.innerText = `Measurement ${index + 1}`;
        header.style.color = "#333";
        header.style.fontSize = "16px";
        header.style.fontWeight = "bold";

        heading.appendChild(header);

        const downIcon = document.createElement("img");
        downIcon.src = DownIcon;
        downIcon.alt = "DownIcon";
        downIcon.id = "DownIcon";
        downIcon.style.cssText = "height: 25px; width: 25px; margin: 5px; cursor: pointer;";

        const upIcon = document.createElement("img");
        upIcon.src = UpIcon;
        upIcon.alt = "UpIcon";
        upIcon.id = "UpIcon";
        upIcon.style.cssText = "height: 20px; width: 20px; margin: 5px; cursor: pointer;";

        const deleteIcon = document.createElement("img");
        deleteIcon.src = DeleteIcon;
        deleteIcon.alt = "DeleteIcon";
        deleteIcon.id = "DeleteIcon";
        deleteIcon.style.cssText =
          "height: 20px; width: 20px; margin: 5px; cursor: pointer; margin-left:10px";

        heading.appendChild(downIcon);
        heading.appendChild(deleteIcon);

        const distancesParagraph = document.createElement("p");
        distancesParagraph.innerHTML = "<strong>Distances:</strong>";
        distancesParagraph.id = `${index + 1}`;
        points.forEach((point, index) => {
          const nextIndex = (index + 1) % points.length; // Use modulo to connect last point to the first point
          const distance = calculateDistance(point, points[nextIndex]);
          distancesParagraph.innerHTML += `<br>Point ${index + 1} to Point ${
            nextIndex + 1
          }: ${distance.toFixed(2)} meters`;
        });

        const areaParagraph = document.createElement("p");
        areaParagraph.innerHTML = `<strong>Area:</strong> ${area.toFixed(
          2,
        )}             "square meters",
          `;

        const perimeterParagraph = document.createElement("p");
        perimeterParagraph.innerHTML = `<strong>Perimeter:</strong> ${perimeter.toFixed(2)} meters`;

        let measurementsVisible = true;

        const measurementsContainer = document.createElement("div");
        measurementsContainer.style.display = "flex";
        measurementsContainer.style.flexDirection = "column";

        measurementsContainer.appendChild(distancesParagraph);
        measurementsContainer.appendChild(areaParagraph);
        measurementsContainer.appendChild(perimeterParagraph);

        popupContent.appendChild(heading);
        popupContent.appendChild(measurementsContainer);

        popupContainer.appendChild(popupContent);

        deleteIcon.addEventListener("click", () => {
          scene.remove(shapeMeshes[index]);
          scene.remove(distanceObjects[index]);
          scene.remove(polygonLabelsArray[index]);

          polygons[index].forEach((e) => {
            scene.remove(e);
          });
          shapeMeshes.splice(index, 1);
          distanceObjects.splice(index, 1);
          polygonLabelsArray.splice(index, 1);
          polygons.splice(index, 1);
          updateLineGeometry();

          calculateAndShowPopup(polygons);
        });
        //   dispatch(
        //     ProjectActions.updateProject({
        //       threeDObjDetails: {
        //         createdLines: [],
        //         createdPoints: polygons.map((ele) => ele.map((point) => point.position)),
        //         areas,
        //         perimeters,
        //         createdLineLoops: {},
        //         panelsQuantity,
        //       },
        //     }),
        //   );
        upIcon.addEventListener("click", (event) => {
          heading.removeChild(upIcon);
          heading.removeChild(deleteIcon);

          // Append the correct icon based on the new state
          heading.appendChild(downIcon);
          measurementsVisible = !measurementsVisible;
          heading.appendChild(deleteIcon);

          // Show or hide the measurements container
          heading.style.display = "flex";
        });

        downIcon.addEventListener("click", (event) => {
          heading.removeChild(downIcon);

          heading.removeChild(deleteIcon);

          // Append the correct icon based on the new state
          heading.appendChild(upIcon);
          measurementsVisible = !measurementsVisible;
          heading.appendChild(deleteIcon);

          // Show or hide the measurements container
          heading.style.display = "none";
        });
      });
    };

    const calculateAndShowPopup2 = (solargisData, panelGrids) => {
      const currentDate = new Date();
      const latitude = quoteDetails.lat; // London, UK (replace with your actual location)
      const longitude = quoteDetails.long;

      // Calculate sun position
      const sunPosition = SunCalc.getPosition(currentDate, latitude, longitude);

      solargisData.forEach((data, index) => {
        let measurementsVisible = true;
        const calculatedMonthlySolargisData = data?.outputs?.monthly?.fixed?.reduce((a, c) => {
          return a + c.E_m;
        }, 0);

        const calculatedtotalsSolargisData = data?.outputs?.totals?.fixed?.E_y;
        const counts = panelGrids?.[index]?.children?.filter(
          (ele) => ele.name === "supported" && !ele?.isNotVisible,
        )?.length;

        const heading = document.createElement("div");
        heading.style.display = "flex";
        heading.style.alignItems = "center";
        heading.style.flexDirection = "column";
        heading.style.gap = "10px";
        heading.style.margin = "10px 0";
        heading.style.position = "relative";

        const headerName = document.createElement("div");
        headerName.style.display = "flex";
        headerName.style.alignItems = "center";
        headerName.style.gap = "10px";
        headerName.style.position = "relative";

        const name = document.createElement("h2");
        name.innerText = `${t("Panel Grid")} ${index + 1}`;
        name.style.color = "#333";
        name.style.fontSize = "16px";
        name.style.fontWeight = "bold";

        headerName.appendChild(name);

        const downIcon = document.createElement("img");
        downIcon.src = DownIcon;
        downIcon.alt = "DownIcon";
        downIcon.id = "DownIcon";
        downIcon.style.cssText = "height: 25px; width: 25px; margin: 5px; cursor: pointer;";

        const upIcon = document.createElement("img");
        upIcon.src = UpIcon;
        upIcon.alt = "UpIcon";
        upIcon.id = "UpIcon";
        upIcon.style.cssText = "height: 20px; width: 20px; margin: 5px; cursor: pointer;";

        const deleteIcon = document.createElement("img");
        deleteIcon.src = DeleteIcon;
        deleteIcon.alt = "DeleteIcon";
        deleteIcon.id = "DeleteIcon";
        deleteIcon.style.cssText =
          "height: 20px; width: 20px; margin: 5px; cursor: pointer; margin-left:10px";

        headerName.appendChild(downIcon);
        headerName.appendChild(deleteIcon);

        const header2 = document.createElement("h2");
        header2.innerText = `${t("Yearly Totals")}:  ${(
          calculatedtotalsSolargisData * counts
        ).toFixed(2)} kWh`;
        header2.style.color = "#333";
        header2.style.fontSize = "16px";
        header2.style.fontWeight = "bold";

        const panelCounts = document.createElement("h2");
        panelCounts.innerText = `${t("Panel Count")}:  ${counts} `;
        panelCounts.style.color = "#333";
        panelCounts.style.fontSize = "16px";
        panelCounts.style.fontWeight = "bold";

        const aziMuth = document.createElement("h2");
        aziMuth.innerText = `${t("Azimuth")}:  ${data?.details?.azimuth.toFixed(2)}°`;
        aziMuth.style.color = "#333";
        aziMuth.style.fontSize = "16px";
        aziMuth.style.fontWeight = "bold";

        // const tilt = document.createElement("h2");
        // tilt.innerText = `${t("Tilt")}:   ${data?.details?.tilt.toFixed(2)}°`;
        // tilt.style.color = "#333";
        // tilt.style.fontSize = "16px";
        // tilt.style.fontWeight = "bold";

        heading.appendChild(header2);
        heading.appendChild(panelCounts);
        heading.appendChild(aziMuth);
        // heading.appendChild(tilt);

        upIcon.addEventListener("click", (event) => {
          headerName.removeChild(upIcon);
          headerName.removeChild(deleteIcon);

          // Append the correct icon based on the new state
          headerName.appendChild(downIcon);
          measurementsVisible = !measurementsVisible;
          headerName.appendChild(deleteIcon);

          // Show or hide the measurements container
          heading.style.display = "flex";
        });

        deleteIcon.addEventListener("click", () => {
          const grid = panelGrids[index];
          removePanelGrid(grid);
          grid?.parent?.remove(grid);
        });

        downIcon.addEventListener("click", (event) => {
          headerName.removeChild(downIcon);

          headerName.removeChild(deleteIcon);

          // Append the correct icon based on the new state
          headerName.appendChild(upIcon);
          measurementsVisible = !measurementsVisible;
          headerName.appendChild(deleteIcon);

          // Show or hide the measurements container
          heading.style.display = "none";
        });

        popupContent2.appendChild(headerName);
        popupContent2.appendChild(heading);

        solargisDataPopupContainer.appendChild(popupContent2);
      });
    };

    const findMiddlePoint = (points) => {
      if (points.length === 0) {
        console.error("No points provided.");
        return null;
      }

      const sum = points.reduce(
        (acc, point) => ({
          x: acc.x + point.x,
          y: acc.y + point.y,
          z: acc.z + point.z,
        }),
        { x: 0, y: 0, z: 0 },
      );

      const average = {
        x: sum.x / points.length,
        y: sum.y / points.length,
        z: sum.z / points.length,
      };

      return average;
    };
    function createSpherePointWithStroke(position, size, mainColor) {
      const pointMaterial = new THREE.MeshBasicMaterial({
        color: mainColor,
        depthTest: false,
        transparent: true,
        opacity: 1,
      });

      const sphereGeometry = new THREE.SphereGeometry(size, 32, 32);
      const mainSphere = new THREE.Mesh(sphereGeometry, pointMaterial);
      mainSphere.position.copy(position);
      mainSphere.rotateX(30);
      mainSphere.userData = {
        visible: true,
      };

      measurementPoints.push(mainSphere);
      scene.add(mainSphere);

      editor.addMeasurementPoint(mainSphere);
      setTimeout(() => {
        updateLineGeometry();
      });
    }
    const addPointBetweenConsecutivePoints = (points) => {
      const newPoints = [];
      // editor.clearMeasurementPointsandLines(true);
      for (let i = 0; i < points.length; i++) {
        const point1 = points[i];
        const point2 = points[(i + 1) % points.length];

        const midpoint = new THREE.Vector3()
          .copy(point1.position)
          .add(point2.position)
          .multiplyScalar(0.5);

        const pointMaterial = new THREE.MeshBasicMaterial({
          color: 0xffffff,
          depthTest: false,
          transparent: true,
          opacity: 1,
        });

        const sphereGeometry = new THREE.SphereGeometry(0.15, 32, 32);
        const mainSphere = new THREE.Mesh(sphereGeometry, pointMaterial);
        mainSphere.position.copy(midpoint);
        mainSphere.userData = {
          visible: false,
        };

        editor.addMeasurementPoint(mainSphere);
        newPoints.push(point1, mainSphere);
      }
      return newPoints;
    };

    const handleMouseDownDocument = (event) => {
      drawingLine = false;
      scene.remove(measurementLabels[lineId]);
      if (line) {
        scene.remove(line);
      }

      if (cylinder) {
        scene.remove(cylinder);
      }

      if (clickOccurred) {
        clickOccurred = false;
        return;
      }

      const intersect = raycaster.intersectObjects(shapeMeshes, true);

      if (intersect.length > 0) {
        if (mode === "Create mode") return;
      }

      if (mode === "Edit mode") {
        const dragablePoints = [...editor.document.polygons.flat(2), ...measurementPoints];
        dragControls.current = new DragControls(dragablePoints, camera, renderer.domElement);
        return;
      }
      const intersects = getIntersections(event);

      if (intersects?.length > 0 && mode === "Create mode") {
        const clickPoint = intersects[0].point;
        const surfaceNormal = intersects[0].face.normal;
        const offsetDistance = 0.1; // Adjust this value as needed
        const adjustedPoint = clickPoint
          .clone()
          .add(surfaceNormal.clone().multiplyScalar(offsetDistance));

        const intersect = raycaster.intersectObjects(measurementPoints, true);

        if (intersect.length > 0) {
          const clickedPoint = intersect[0].object;
          const clickedPointIndex = measurementPoints.indexOf(clickedPoint);
          const pointsPosition = measurementPoints.map((point) => point.position);
          if (clickedPointIndex === 0 && pointsPosition.length > 2) {
            const pointsWithMidpoints = addPointBetweenConsecutivePoints(measurementPoints);
            editor.document.polygons.push(pointsWithMidpoints);
            editor.document.oldPolygons.push(pointsWithMidpoints);

            // const dragablePoints = [...polygons.flat(2)];

            // dragControls.current = new DragControls(dragablePoints, camera, renderer.domElement);
            clearPoints();
            handleModeChange("Edit mode");
            createModeRadio.checked = false;
            editModeRadio.checked = true;
            updateLineGeometry();
            calculateAndShowPopup(editor.document.polygons);

            // const areas = [];
            // const perimeters = polygons.map((ele) => {
            //   const points = ele.map((point) => point.position);
            //   const area = calculatePolygonArea(points);
            //   areas.push(area);
            //   const perimeter = calculatePolygonPerimeter(points);
            //   return perimeter;
            // });
            // dispatch(
            //   ProjectActions.updateProject({
            //     threeDObjDetails: {
            //       createdLines: [],
            //       createdPoints: polygons.map((ele) =>
            //         ele.map((point) => point.position)
            //       ),
            //       areas,
            //       perimeters,
            //       panelsQuantity,
            //       createdLineLoops: {},
            //     },
            //   })
            // );
            return;
          }

          return;
        }

        createSpherePointWithStroke(adjustedPoint, 0.2, 0xffffff);

        if (intersects.length > 0) {
          startPoint1 = intersects[0].point.clone();
          const distanceLabel = document.createElement("div");

          distanceLabel.className = "measurementLabel";
          distanceLabel.style.cssText =
            "font-weight: bold; color: #fff; border-radius: 10px; padding: 3px 7px; background: rgba(0, 0, 0, 0.60); z-index: 999999;";
          distanceLabel.innerText = "0.0m";

          const measurementLabel = new CSS2DObject(distanceLabel);
          measurementLabel.position.copy(startPoint1);

          measurementLabels[lineId] = measurementLabel;
          scene.add(measurementLabel);
          drawingLine = true;
        }
      }
    };

    // document.addEventListener("pointermove", handleHover, false);
    threejsEl.current.addEventListener("mousemove", onDocumentMouseMove, false);

    let solargisData = [];

    const fitCameraToCenteredObject = (camera, terrainGLB) => {
      terrainGroup.add(terrainGLB.scene);
      const box = new Box3().setFromObject(terrainGLB.scene);
      terrainGLB.scene.position.y -= box.min.y;
      terrainGLB.scene.updateMatrixWorld();
      box.setFromObject(terrainGLB.scene);
      const sz = box.getSize(new vec3());

      camera.position.copy(box.max);
      camera.position.y += sz.y;
      box.getCenter(controls.target);
    };

    let points = [];
    let allPoints = [];
    let allLines = [];
    let startPoint = null;
    let endPoint = null;

    const clearPointsAndLines = () => {
      points.forEach((point) => scene.remove(point));
      points = [];

      allPoints.flat().forEach((point) => scene.remove(point));
      allPoints = [];

      allLines.flat().forEach((point) => scene.remove(point));
      allLines = [];

      lines.forEach((line) => scene.remove(line));
      lines = [];
    };

    const cables = [];
    let hoveredPoints = [];

    const calculatePanelCenter = (panelGrid) => {
      const box = new THREE.Box3().setFromObject(panelGrid);
      const center = new THREE.Vector3();
      box.getCenter(center);
      return center;
    };

    const handleAutoCabeling = () => {
      editor.autoCabling(panelGrids, calculatePanelCenter);
    };

    const addPointAtPosition = (position, cabelingType, addToCmd) => {
      const geometry = new THREE.SphereGeometry(0.1, 32, 32); // Small sphere
      const material = new THREE.MeshBasicMaterial({ color: 0xffffff }); // White color
      const point = new THREE.Mesh(geometry, material);
      point.position.copy(position);
      if (cabelingType !== "auto" && addToCmd) {
        editor.addPoint(point); // Updated to use editor
      }
      return point; // Return the point mesh for further use
    };

    const addLineBetweenPoints = (point1, point2, cabelingType, addToCmd) => {
      const material = new THREE.LineBasicMaterial({
        color: 0x22d94a,
        depthTest: false,
      }); // Green color

      const geometry = new THREE.BufferGeometry().setFromPoints([point1.position, point2.position]);
      const line = new THREE.Line(geometry, material);
      if (cabelingType !== "auto" && addToCmd) {
        editor.addLine(line); // Updated to use editor
      }
      return line; // Return the line for further use
    };

    const v0 = new THREE.Vector3();
    const v1 = new THREE.Vector3();
    const updateBoxFrame = (fromGrid) => {
      // let sscl = fromGrid.selectionMesh.scale;
      if (!boxFrame) {
        boxFrame = new THREE.Group();
        for (let i = 0; i < 4; i++) boxFrame.add(lineTemplate.clone());
        scene.add(boxFrame);
      }
      // boxFrame.position.copy(sscl).multiplyScalar(0.5)
      // fromGrid.localToWorld(boxFrame.position);
      // fromGrid.position)
      // boxFrame.rotation.copy(fromGrid.rotation);
      const setLine2 = (line, x1, y1, x2, y2) => {
        v0.set(x1, y1, 0);
        v1.set(x2, y2, 0);
        fromGrid.selectionMesh.localToWorld(v0);
        fromGrid.selectionMesh.localToWorld(v1);
        line.position.copy(v0).lerp(v1, 0.5);
        const dist = v0.distanceTo(v1);
        line.lookAt(v1);
        line.scale.z = dist;
      };
      const sx = 1; // sscl.x
      const sy = 1; // sscl.y
      setLine2(boxFrame.children[0], 0, 0, sx, 0);
      setLine2(boxFrame.children[1], 0, sy, sx, sy);
      setLine2(boxFrame.children[2], 0, 0, 0, sy);
      setLine2(boxFrame.children[3], sx, 0, sx, sy);
      // boxFrame.setSize(sscl.x * 0.5, sscl.y * 0.5, sscl.z * 0.5);
    };

    const showHelpersBetweenPoints = (startIndex, endIndex, hoveredPoints, addToCmd = false) => {
      clearPointsAndLines();
      editor.clearPointsAndLines(); // Clear existing points and lines
      for (let i = 0; i <= hoveredPoints.length - 1; i++) {
        const pgModule = hoveredPoints[i];
        const pgBox = new THREE.Box3().setFromObject(pgModule);
        const pgCenter = new THREE.Vector3();
        pgBox.getCenter(pgCenter);

        const point = addPointAtPosition(pgCenter, "manual", addToCmd);

        points.push(point);
        scene.add(point);

        if (i > 0) {
          const line = addLineBetweenPoints(points[i - 1], points[i], "manual", addToCmd);
          scene.add(line);
          lines.push(line);
        }
      }

      editor.document.cables.forEach((cable) => {
        const previousPoints = [];
        const previousLines = [];
        for (let i = 0; i < cable.length; i++) {
          const pgModule = cable[i];
          const pgBox = new THREE.Box3().setFromObject(pgModule);
          const pgCenter = new THREE.Vector3();
          pgBox.getCenter(pgCenter);

          const point = addPointAtPosition(pgCenter, "manual", addToCmd);
          scene.add(point);
          previousPoints.push(point);

          if (i > 0) {
            const line = addLineBetweenPoints(
              previousPoints[i - 1],
              previousPoints[i],
              "manual",
              addToCmd,
            );
            previousLines.push(line);
            scene.add(line);
          }
        }
        allPoints.push(previousPoints);
        allLines.push(previousLines);
      });
    };

    const throttle = (func, limit) => {
      let inThrottle;
      return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    };
    let clickedPoint = false;
    const handleMouseMove = throttle(() => {
      if (startPoint && !endPoint) {
        const hits = io.raycaster.intersectObjects(
          panelGrids.map((pg) => pg.selectionMesh),
          false,
        );

        if (hits.length) {
          const hoveredGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);

          if (hoveredGrid) {
            const childHits = io.raycaster.intersectObjects(hoveredGrid.children.slice(1), true);

            if (childHits.length) {
              let currentPoint = childHits[0].object;

              let supportedParent = currentPoint;
              while (supportedParent && supportedParent.name !== "supported") {
                supportedParent = supportedParent.parent;
              }

              if (supportedParent && supportedParent.name === "supported") {
                currentPoint = supportedParent;
              }

              const startIndex = hoveredGrid.children.slice(1).indexOf(startPoint);
              const existingIndex = hoveredPoints.findIndex(
                (point) => point.id === currentPoint.id,
              );

              const connectionExists = editor.document.cables.some((cableArray) =>
                cableArray.some((cable) => cable.id === currentPoint.id),
              );

              if (existingIndex !== -1) {
                hoveredPoints.splice(existingIndex + 1, 1);
              } else if (!connectionExists) {
                hoveredPoints.push(currentPoint);
              }

              const hoveredIndex = hoveredGrid.children.slice(1).indexOf(currentPoint);

              if (hoveredPoints.length > 0) {
                showHelpersBetweenPoints(startIndex, hoveredIndex, hoveredPoints);
                // addTextToPoints(hoveredPoints); // <-- Added this line
              }
            }
          }
        }
      }
    }, 100);

    const handlePanelClick = () => {
      const hits = io.raycaster.intersectObjects(allPoints.flat(), false);
      if (hits?.length > 0) {
        controls.enabled = false;
        return;
      }

      if (clickedPoint) {
        clickedPoint = false;
        return;
      }

      if (panelGrids.length > 0) {
        const hits = io.raycaster.intersectObjects(
          panelGrids.map((pg) => pg.selectionMesh),
          false,
        );

        if (hits.length) {
          const clickedGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);

          if (clickedGrid) {
            const childHits = io.raycaster.intersectObjects(clickedGrid.children.slice(1), true);

            if (childHits.length > 0) {
              let currentPoint = childHits[0].object;

              let supportedParent = currentPoint;
              while (supportedParent && supportedParent.name !== "supported") {
                supportedParent = supportedParent.parent;
              }

              if (supportedParent && supportedParent.name === "supported") {
                currentPoint = supportedParent;
              }

              const connectionExists = editor.document.cables.some((cableArray) =>
                cableArray.some((cable) => cable.id === currentPoint.id),
              );

              if (!startPoint && currentPoint.name === "supported" && !connectionExists) {
                startPoint = currentPoint;
                hoveredPoints.push(currentPoint);
                document.addEventListener("mousemove", handleMouseMove);
              } else if (!endPoint) {
                endPoint = currentPoint;
                hoveredPoints.push(currentPoint);

                const startIndex = clickedGrid.children.slice(1).indexOf(startPoint);
                const endIndex = clickedGrid.children.slice(1).indexOf(endPoint);
                if (hoveredPoints.length > 1) {
                  editor.document.cables.push([...hoveredPoints]);
                  hoveredPoints = [];
                  showHelpersBetweenPoints(startIndex, endIndex, [], true);
                }

                document.removeEventListener("mousemove", handleMouseMove);

                startPoint = null;
                endPoint = null;
              }
            }
          }
        }
      }
    };

    function addTextToPoints(panels) {
      let textIndex = 1;
      panels.forEach((panel) => {
        // Remove old text geometries if they exist
        if (panel.textAdded) {
          const oldTextMesh = panel.children.find(
            (child) => child.isMesh && child.geometry.type === "TextGeometry",
          );
          if (oldTextMesh) {
            panel.remove(oldTextMesh);
            oldTextMesh.geometry.dispose();
            oldTextMesh.material.dispose();
          }
        }
      });
      panels.forEach((panel) => {
        if (!panel.textAdded) {
          if (fonts) {
            const textGeometry = new TextGeometry(textIndex.toString(), {
              font: fonts,
              size: 0.3,
              height: 0.05,
            });
            const textMaterial = new THREE.MeshStandardMaterial({
              color: "white",
            });
            // let pgBox = new THREE.Box3().setFromObject(panel);

            // Determine the left bottom corner of the bounding box
            // const leftBottomCorner = new THREE.Vector3(
            //   pgBox.min.x,
            //   pgBox.min.y,
            //   pgBox.min.z
            // );
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);
            // textMesh.position.set(leftBottomCorner);
            textIndex++;
            panel.textAdded = true; // Mark panel as having text added
            panel.add(textMesh);
          }
        }
      });
    }

    let selectedPoint = null;
    const deleteButton = document.createElement("img");
    deleteButton.src = DeleteIcon;
    deleteButton.alt = "DeleteIcon";
    deleteButton.style.position = "absolute";
    deleteButton.style.height = "30px";
    deleteButton.style.width = "30px";
    deleteButton.style.display = "none";
    deleteButton.style.zIndex = 1000;
    deleteButton.style.backgroundColor = "white";
    deleteButton.style.padding = "3px";
    deleteButton.style.borderRadius = "5px";
    deleteButton.style.boxShadow = "2px 2px 5px rgba(0, 0, 0, 0.2)";
    threejsEl.current.appendChild(deleteButton);

    let indices;

    const findElementIndices = (nestedArray, targetElement) => {
      for (let mainIndex = 0; mainIndex < nestedArray.length; mainIndex++) {
        const innerIndex = nestedArray[mainIndex].indexOf(targetElement);
        if (innerIndex !== -1) {
          return { mainIndex, innerIndex };
        }
      }
      return null; // Element not found
    };

    const handlePointClick = () => {
      const hits = io.raycaster.intersectObjects(allPoints.flat(2), false);

      if (hits.length) {
        clickedPoint = true;
        indices = findElementIndices(allPoints, hits[0].object);
        selectedPoint = hits[0].object;
        const position = selectedPoint.position.clone().project(camera);
        const x = (position.x * 0.5 + 0.5) * threejsEl.current.clientWidth;
        const y = (-position.y * 0.5 + 0.5) * threejsEl.current.clientHeight;
        deleteButton.style.left = `${x}px`;
        deleteButton.style.top = `${y}px`;
        deleteButton.style.display = "block";
        controls.enabled = false;
      } else {
        controls.enabled = true;
        deleteButton.style.display = "none";
      }
    };

    const handleDeleteButtonClick = () => {
      if (selectedPoint) {
        const index = points.findIndex((ele) => ele.object === selectedPoint);
        if (indices) {
          editor.document.cables[indices.mainIndex].splice(indices.innerIndex, 1);
          showHelpersBetweenPoints(0, 0, []);
        }
        clickedPoint = true;
        selectedPoint = null;
        deleteButton.style.display = "none";
      }
    };

    let previousHoveredPoint = null;

    const handlePointMove = (event) => {
      const hits = io.raycaster.intersectObjects([...allPoints.flat(2)], false);

      if (hits.length) {
        const hoveredPoint = hits[0].object;

        if (previousHoveredPoint && previousHoveredPoint !== hoveredPoint) {
          // Reset the scale of the previously hovered point
          previousHoveredPoint.scale.set(1, 1, 1);
        }

        // Scale the hovered point
        hoveredPoint.scale.set(2, 2, 2);
        previousHoveredPoint = hoveredPoint;
      } else if (previousHoveredPoint) {
        // Reset the scale if no point is hovered
        previousHoveredPoint.scale.set(1, 1, 1);
        previousHoveredPoint = null;
      }
    };

    let previousHoveredLineGroup = null;

    const handleLineMove = (event) => {
      const hits = io.raycaster.intersectObjects(allLines.flat(2), false);
      const hitsPoints = io.raycaster.intersectObjects(allPoints.flat(2), false);

      if (hits.length && hitsPoints.length === 0) {
        const hoveredLine = hits[0].object;
        const hoveredLineGroup = allLines.find((lineGroup) => lineGroup.includes(hoveredLine));

        if (previousHoveredLineGroup && previousHoveredLineGroup !== hoveredLineGroup) {
          // Reset the color of the previously hovered line group
          previousHoveredLineGroup.forEach((line) => {
            line.material.color.set(line.originalColor);
          });
        }

        // Change the color of the hovered line group
        hoveredLineGroup.forEach((line) => {
          if (!line.originalColor) {
            line.originalColor = line.material.color.getHex();
          }
          line.material.color.set(0xff0000); // Change to desired hover color
        });

        previousHoveredLineGroup = hoveredLineGroup;
      } else if (previousHoveredLineGroup) {
        // Reset the color if no line is hovered
        previousHoveredLineGroup.forEach((line) => {
          line.material.color.set(line.originalColor);
        });
        previousHoveredLineGroup = null;
      }
    };

    function createDeletePopup() {
      const deletePopup = document.createElement("div");
      deletePopup.id = "deletePopup";
      deletePopup.style.display = "none";
      deletePopup.style.position = "absolute";
      deletePopup.style.zIndex = "1000";
      deletePopup.style.backgroundColor = "white";
      deletePopup.style.border = "1px solid black";
      deletePopup.style.padding = "10px";

      const deleteYes = document.createElement("button");
      deleteYes.id = "deleteYes";
      deleteYes.textContent = "Yes";
      deletePopup.appendChild(deleteYes);

      const deleteNo = document.createElement("button");
      deleteNo.id = "deleteNo";
      deleteNo.textContent = "No";
      deletePopup.appendChild(deleteNo);

      threejsEl.current.appendChild(deletePopup);

      return deletePopup;
    }

    const deletePopup = createDeletePopup();

    let selectedLineGroup = null;
    let selectedLineGroupIndex = null;

    const handleLineClick = (event) => {
      const hits = io.raycaster.intersectObjects(allLines.flat(2), false);
      const hitsPoints = io.raycaster.intersectObjects(allPoints.flat(2), false);

      if (hits.length && hitsPoints.length === 0) {
        clickedPoint = true;
        const clickedLine = hits[0].object;
        selectedLineGroupIndex = allLines.findIndex((lineGroup) => lineGroup.includes(clickedLine));

        if (selectedLineGroupIndex !== -1) {
          selectedLineGroup = allLines[selectedLineGroupIndex];

          // Position the popup near the clicked line
          const position = clickedLine.geometry.boundingSphere.center.clone().project(camera);
          const x = (position.x * 0.5 + 0.5) * threejsEl.current.clientWidth;
          const y = (-position.y * 0.5 + 0.5) * threejsEl.current.clientHeight;

          deletePopup.style.left = `${x}px`;
          deletePopup.style.top = `${y}px`;
          deletePopup.style.display = "flex";
          deletePopup.style.gap = "20px";
        }
      }
    };

    document.addEventListener("click", handleLineClick);

    const deleteYes = document.getElementById("deleteYes");
    const deleteNo = document.getElementById("deleteNo");

    deleteYes.addEventListener("click", () => {
      clickedPoint = true;

      if (selectedLineGroup && selectedLineGroupIndex !== null) {
        // Remove the selected line group from the scene and allLines array
        selectedLineGroup.forEach((line) => {
          scene.remove(line);
        });

        allLines.splice(selectedLineGroupIndex, 1);
        editor.document.cables.splice(selectedLineGroupIndex, 1);
        showHelpersBetweenPoints([], [], [], false);
        selectedLineGroup = null;
        selectedLineGroupIndex = null;

        // Hide the popup
        deletePopup.style.display = "none";
      }
    });

    deleteNo.addEventListener("click", () => {
      // Hide the popup without doing anything
      clickedPoint = true;
      deletePopup.style.display = "none";
      selectedLineGroup = null;
      selectedLineGroupIndex = null;
    });

    document.addEventListener("click", handlePointClick);
    deleteButton.addEventListener("click", handleDeleteButtonClick);
    let cabelingMode;

    const popup = document.createElement("div");
    const cabelpopup = () => {
      // if (threejsEl.current.contains(popup)) threejsEl.current.removeChild(popup);

      popup.className = "popup";
      popup.style.position = "absolute";
      popup.style.display = "flex";
      popup.style.gap = "20px";
      popup.style.top = "50%";
      popup.style.left = "50%";
      popup.style.transform = "translate(-50%, -50%)";
      popup.style.backgroundColor = "#2D4764";
      popup.style.padding = "20px";
      popup.style.cursor = "pointer";
      popup.style.zIndex = 1001;
      popup.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.5)";
      popup.innerHTML = "";

      const manualButton = document.createElement("div");
      manualButton.innerText = "Manual";
      manualButton.style.marginRight = "10px";
      manualButton.style.color = "#fff";
      manualButton.style.transition = "color 0.3s";
      manualButton.addEventListener("click", () => {
        popup.remove();
        clickOccurred = true;
        document.addEventListener("click", handlePanelClick);
        cabelingMode = "manual";
        clickedPoint = true;
      });
      manualButton.addEventListener("mouseover", () => {
        manualButton.style.color = "#FFD75D";
      });
      manualButton.addEventListener("mouseout", () => {
        manualButton.style.color = "#fff";
      });

      const autoButton = document.createElement("div");
      autoButton.innerText = "Auto";
      autoButton.style.color = "#fff";
      autoButton.style.transition = "color 0.3s";
      autoButton.addEventListener("click", () => {
        popup.remove();
        handleAutoCabeling();
        cabelingMode = "auto";
      });
      autoButton.addEventListener("mouseover", () => {
        autoButton.style.color = "#FFD75D";
      });
      autoButton.addEventListener("mouseout", () => {
        autoButton.style.color = "#fff";
      });

      popup.appendChild(manualButton);
      popup.appendChild(autoButton);
      threejsEl.current.appendChild(popup);
    };
    let selectedPanelGrid = null; // To keep track of the selected panel grid

    const mouseOver = () => {
      if (panelLayoutActive) {
        const hits = io.raycaster.intersectObjects(
          panelGrids.map((pg) => pg.selectionMesh),
          false,
        );
        if (hits.length) {
          const clickedGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);
          selectedPanelGrid = clickedGrid;
          const childHits = io.raycaster.intersectObjects(clickedGrid.children.slice(1), true);

          // Hide all delete icons initially
          panelGrids.forEach((pg) => {
            pg.children.forEach((child) => {
              if (child.name === "supported") {
                child.children.forEach((c) => {
                  if (c.name === "deleteIcon") {
                    c.visible = false;
                  }
                });
              }
            });
          });

          if (childHits.length) {
            // Show the delete icon for the intersected panel
            const intersectedPanel = childHits[0].object.parent;
            intersectedPanel.children.forEach((c) => {
              if (!intersectedPanel.children.find((ele) => ele.name === "add-icon")?.visible) {
                if (c.name === "deleteIcon") {
                  c.visible = true;
                }
              }
            });

            controls.enabled = true;
            inDrag = false;
          }
        }
      }
    };

    const solarProductionData = () => {
      threejsEl.current.appendChild(solargisDataPopupContainer);
      solargisData.length = 0;
      solargisData = [];
      const data = getPanelData();

      console.log(data, "data");

      data.forEach((e) => {
        const url = `https://staging.backend-v2.solarhub24.de/api/comman/pvcalc?lat=${quoteDetails.lat}&lon=${quoteDetails?.long}&peakpower=0.408&loss=2.5&aspect=${e.azimuth}&angle=${e.tilt}&outputformat=json&pvtechchoice=crystSi`;

        axios
          .get(url)
          .then((response) => {
            const data = response.data;
            solargisData.push({ outputs: response.data.outputs, details: e });
          })
          .catch((error) => {
            console.error("Error making the API call:", error);
          });
      });

      setTimeout(async () => {
        const element = document.querySelector(".popup-content2");

        if (element) {
          element.innerHTML = "";
        }
        calculateAndShowPopup2(solargisData, panelGrids);
      }, 1000);
    };

    let modules;
    let dimentions;
    const container = document.createElement("div");
    container.id = "panel-container";
    container.style.cssText = `
    display: flex; 
    flex-direction: column; 
    gap: 15px; 
    justify-content: center; 
    align-items: center; 
    background: #FFF; 
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.40); 
    z-index: 9999; 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
  `;

    const closeButton = document.createElement("img");
    closeButton.src = CloseIcon;
    closeButton.alt = "CloseIcon";
    closeButton.id = "CloseIcon";
    closeButton.style.cssText = `
    height: 20px; 
    width: 20px; 
    margin: 5px; 
    cursor: pointer; 
    position: absolute; 
    right: 5px; 
    top: 5px
  `;
    closeButton.addEventListener("click", () => {
      threejsEl.current.removeChild(container);
    });

    // Create label element
    const label = document.createElement("label");
    label.textContent = t("Select Panel");
    label.style.cssText = `
      font-size: 16px; 
      font-weight: 700; 
      color: #000000;
    `;

    const container1 = document.createElement("div");
    container1.style.cssText = `
      display: flex; 
      justify-content: center; 
      flex-direction: column; 
      gap: 10px; 
      align-items: center;
    `;

    container.appendChild(label);
    container.appendChild(closeButton);
    container.appendChild(container1);

    const selectPanel = () => {
      if (modules?.length > 0) return;

      // Clear container1 before adding new elements
      while (container1.firstChild) {
        container1.removeChild(container1.firstChild);
      }

      productObjFiles.forEach((ele) => {
        const fileName = ele.obj.split("/").pop();
        const div = document.createElement("div");
        div.textContent = fileName || "file.obj";
        div.style.cssText = `
          font-size: 16px; 
          font-weight: 700; 
          color: #2D4764; 
          cursor: pointer;
        `;
        div.addEventListener("click", async () => {
          // selectedPanel = fileName;
          try {
            mtlLoader.setResourcePath(
              "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/products/6695142e0a9684f59079b41e/",
            );

            const materials = await mtlLoader.loadAsync(ele.mtl);
            materials.preload();
            objLoader.setMaterials(materials);
            const obj = await objLoader.loadAsync(ele.obj);
            console.log(obj, "obj");

            const panel = obj;
            dimentions = {
              height: ele.height ? +ele.height / 1000 : 2,
              width: ele.width ? +ele.width / 1000 : 1,
            };
            scene.add(panel);

            if (panel) {
              console.log(panel, "panel");
              // eslint-disable-next-line no-lone-blocks
              {
                modules = [panel];
                // modules.forEach((m) => m.updateMatrix() & m.updateMatrixWorld());
              }
            }
            console.log("Panel added to the scene:", panel);
          } catch (error) {
            console.error("Error loading panel:", error);
          }
          threejsEl.current.removeChild(container);
        });
        container1.appendChild(div);
      });

      // const button = document.createElement("button");
      // button.textContent = t("Confirm");
      // button.style.cssText = `
      //   background: #FFBF1B;
      //   color: #2D4764;
      //   border: none;
      //   padding: 4px 10px;
      //   border-radius: 100px;
      // `;

      // button.addEventListener("click", () => {
      //   // Do something with the selectedPanels array
      //   console.log("Selected Panels:", selectedPanels);
      //   threejsEl.current.removeChild(container);
      // });

      // container.appendChild(button);
      threejsEl.current.appendChild(container);
    };

    const handleClick = (key, createModeButton, e) => {
      clickOccurred = true;
      e.stopPropagation();
      removeAllActionContainers();
      document.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("wheel", handleWheel);
      threejsEl.current.removeEventListener("mousemove", handleLineMove);
      threejsEl.current.removeEventListener("mousemove", handlePointMove);
      threejsEl.current.removeEventListener("mouseout", () => {
        if (previousHoveredPoint) {
          previousHoveredPoint.scale.set(1, 1, 1);
          previousHoveredPoint = null;
        }
      });
      const existingRadioContainer = threejsEl.current?.querySelector(".radio-container");
      mode = "";

      panelLayoutActive = false;

      handleModeChange("");
      handleClosePopup();

      resetIcons(key);
      existingRadioContainer?.remove();

      const actions = {
        home: () => {
          changeIconColor(createModeButton, HomeYellow, false);
          fitCameraToCenteredObject(camera, terrainGLB);
        },
        light: () => {
          changeIconColor(createModeButton, Light, false);
          controls.mouseButtons.LEFT = MOUSE.PAN;
          controls.enablePan = true;
          controls.update();
        },
        planet: () => {
          changeIconColor(createModeButton, PlanetYellow, false);
        },
        drone: () => {
          changeIconColor(createModeButton, DroneYellow, false);
        },
        cabeling: () => {
          changeIconColor(createModeButton, CabelingYellowIcon, false);
          cabelpopup();

          threejsEl.current.addEventListener("mousemove", handlePointMove);
          threejsEl.current.addEventListener("mousemove", handleLineMove);
          threejsEl.current.addEventListener("mouseout", () => {
            if (previousHoveredPoint) {
              previousHoveredPoint.scale.set(1, 1, 1);
              previousHoveredPoint = null;
            }
          });
        },
        polygon: () => {
          threejsEl.current.appendChild(popupContainer);
          changeIconColor(createModeButton, MesureYellow, false);
          document.querySelectorAll(".measurementLabels").forEach((label) => {
            label.style.display = "block";
          });
          createRadioContainer();
          handlePolygonDrawing();
        },
        solarpanel: () => {
          changeIconColor(createModeButton, SolarPanelYellow, false);
          // controls.enablePan = false;
          // controls.update();
          selectPanel();
          panelGrids.map((grid) => {
            addIconsToPanelGrid(grid);
          });
          panelLayoutActive = true;
          threejsEl.current.addEventListener("mousemove", mouseOver);

          // if (polygons.length === 0) {
          //   createRadioContainer();
          // } else {
          //   updateDragControls();
          // }
        },
        FullScreenIcon: toggleFullScreen,
        MaxWidth: () => {
          dispatch(
            ProjectActions.updateProject({
              maxWidth3DViewer: from3DViwer ? !maxWidth3DViewer : maxWidth3DViewer,
              maxWidth3DViewerWithImages: from3DViwer
                ? maxWidth3DViewerWithImages
                : !maxWidth3DViewerWithImages,
            }),
          );
        },
        Undo: () => {
          editor.document.undo();
        },
        popup: () => {
          changeIconColor(createModeButton, PopupYellow, false);

          // document.body.appendChild(popupContainer);
          // calculateAndShowPopup(polygons);
        },
        screenshot: () => {
          handleScreenShot();
        },
        ZoomIn: () => {
          const scale = terrainGLB.scene.scale.x + 0.1;
          terrainGLB.scene.scale.set(scale, scale, scale);
          scene.add(terrainGLB.scene);
        },
        ZoomOut: () => {
          const scale = terrainGLB.scene.scale.x - 0.1;
          terrainGLB.scene.scale.set(scale, scale, scale);
          scene.add(terrainGLB.scene);
        },
      };

      if (actions[key]) {
        actions[key]();
      }

      if (key !== "solarpanel") {
        document.removeEventListener("mousemove", mouseOver);
        if (threejsEl.current.contains(container)) threejsEl.current.removeChild(container);
      }
      if (key !== "polygon") {
        mode = "";
      }

      if (key !== "cabeling") {
        document.removeEventListener("click", handlePanelClick);
        if (threejsEl.current.contains(popup)) threejsEl.current.removeChild(popup);
      }

      if (key !== "light") {
        controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
        controls.enableRotate = true;
        controls.update();
      }
    };

    const handlePolygonDrawing = () => {
      const cursorLabel = document.createElement("div");
      cursorLabel.textContent = "Starting Point";
      cursorLabel.style.position = "absolute";
      cursorLabel.style.backgroundColor = "white";
      cursorLabel.style.padding = "5px";
      // cursorLabel.style.border = "1px solid black";
      cursorLabel.style.borderRadius = "5px";
      cursorLabel.style.pointerEvents = "none";

      const closeLabel = document.createElement("div");
      closeLabel.textContent = "Close Polygon";
      closeLabel.style.position = "absolute";
      closeLabel.style.backgroundColor = "white";
      closeLabel.style.padding = "5px";
      // closeLabel.style.border = "1px solid black";
      closeLabel.style.display = "none";

      let polygonPoints = [];

      let isDrawing = true;

      const updateCursorPosition = (event) => {
        const intersects = getIntersections(event);
        const offsetX = 10; // Horizontal offset from cursor
        const offsetY = 10; // Vertical offset from cursor
        if (intersects.length === 0) {
          if (document.body.contains(cursorLabel)) document.body.removeChild(cursorLabel);
          if (document.body.contains(closeLabel)) document.body.removeChild(closeLabel);
          return;
        } else {
          if (polygonPoints.length === 0) {
            document.body.appendChild(cursorLabel);
            document.body.appendChild(closeLabel);
          }
        }
        cursorLabel.style.left = `${event.clientX + offsetX}px`;
        cursorLabel.style.top = `${event.clientY + offsetY}px`;
        if (polygonPoints.length > 1) {
          const firstPoint = polygonPoints[0];
          const distance = Math.sqrt(
            Math.pow(event.clientX - firstPoint.x, 2) + Math.pow(event.clientY - firstPoint.y, 2),
          );
          if (distance < 10) {
            closeLabel.style.left = `${firstPoint.x + offsetX}px`;
            closeLabel.style.top = `${firstPoint.y - 20}px`;
            closeLabel.style.display = "block";
          } else {
            closeLabel.style.display = "none";
          }
        }
      };

      const addPolygonPoint = (event) => {
        const intersects = getIntersections(event);
        if (intersects.length === 0) return;

        if (!isDrawing) return;

        const point = { x: event.clientX, y: event.clientY };
        polygonPoints.push(point);

        if (polygonPoints.length === 1) {
          cursorLabel.remove();
        }

        if (closeLabel.style.display === "block") {
          isDrawing = false;
          closeLabel.style.display = "none";
          cursorLabel.style.display = "none";
          finalizePolygon();
        }
      };

      const finalizePolygon = () => {
        // Add your polygon finalization logic here
        polygonPoints = [];
        polygonPoints.length = 0;
        document.removeEventListener("mousemove", updateCursorPosition);
        document.removeEventListener("click", addPolygonPoint);
      };

      document.addEventListener("mousemove", updateCursorPosition);
      document.addEventListener("click", addPolygonPoint);
    };

    const handleSaveSolarProductionData = async () => {
      if (solargisData.length > 0) {
        const s = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0,
          11: 0,
          12: 0,
        };
        const monthly = solargisData.map((ele, index) => {
          return ele.outputs.monthly.fixed;
        });

        const total = solargisData.map((ele, index) => {
          return ele.outputs.totals.fixed;
        });

        const calculatedMonthlySolargisData = monthly?.map((ele, index) => {
          return ele.map((ele) => {
            s[ele.month] =
              s[ele.month] +
              ele.E_m *
                panelGrids?.[index]?.children
                  .slice(1)
                  .filter((ele) => ele.name === "supported" && !ele?.isNotVisible).length;
            return (
              ele.E_m *
              panelGrids?.[index]?.children
                .slice(1)
                .filter((ele) => ele.name === "supported" && !ele?.isNotVisible).length
            );
          });
        });

        const panelCounts = panelGrids.reduce((ele, a, index) => {
          return (
            ele +
            panelGrids?.[index]?.children
              .slice(1)
              .filter((ele) => ele.name === "supported" && !ele?.isNotVisible).length
          );
        }, 0);

        const monthlySolarData = {
          "January (kWh)": s["1"],
          "February (kWh)": s["2"],
          "March (kWh)": s["3"],
          "April (kWh)": s["4"],
          "May (kWh)": s["5"],
          "June (kWh)": s["6"],
          "July (kWh)": s["7"],
          "Augest (kWh)": s["8"],
          "September (kWh)": s["9"],
          "Octomber (kWh)": s["10"],
          "November (kWh)": s["11"],
          "December (kWh)": s["12"],
        };

        const totalAnnualProduction = Object.values(monthlySolarData).reduce((el, c) => {
          return el + c;
        }, 0);

        const calculatedtotalsSolargisData = total?.flat(2).reduce((a, c) => {
          return a + c.E_y;
        }, 0);

        await ApiAxiosClient.put(`${update3dQuote.url}`, {
          monthlySolarData,
          productionCapacity: capacity * panelCounts,
          moduleQuantity: panelCounts,
          annualSolarGeneration: totalAnnualProduction,
          id: project.quote.id,
        });
        dispatch(
          ProjectActions.updateProject({
            ...project,
            quote: {
              ...project.quote,
              profiles: {
                ...project.quote.profiles,
                green: {
                  ...project.quote.profiles.green,
                  monthlySolarData,
                  productionCapacity: capacity * panelCounts,
                  moduleQuantity: panelCounts,
                  annualSolarGeneration: totalAnnualProduction,
                },
              },
            },
          }),
        );

        toast.success(t("Your Information Saved Successfully!!"));
      } else {
        toast.warn(t("Please Complete the Panel Placement first then you can Save it!!"));
      }
    };
    let directionalLight = null;

    function createContainerWithChildDivs() {
      const container = document.createElement("div");
      container.style.position = "absolute";
      container.style.top = "10px";
      container.style.left = "10px";
      container.style.display = "flex";
      container.style.flexDirection = "column";
      container.style.gap = "10px";

      const childDivs = [
        {
          textContent: t("Assistance"),
          backgroundColor: "#FFFFFFCC",
          color: "#2D4764",
          fontSize: 18,
          textAlign: "center",
          width: "fit-content",
          borderRadius: "100px",
          padding: "4px 10px",
        },
        {
          textContent: t("Show radiation"),
          backgroundColor: "#FFFFFFCC",
          color: "#2D4764",
          fontSize: 18,
          textAlign: "center",
          width: "fit-content",
          borderRadius: "100px",
          padding: "4px 10px",
        },
        {
          textContent: t("Save"),
          backgroundColor: "#FFFFFFCC",
          color: "#2D4764",
          fontSize: 16,
          textAlign: "center",
          width: "fit-content",
          borderRadius: "100px",
          padding: "4px 10px",
        },
      ];

      const label = document.createElement("label");
      label.classList.add("switch");
      const switchInput = document.createElement("input");
      switchInput.type = "checkbox";
      switchInput.addEventListener("change", (e) => {
        if (directionalLight) {
          terrainGroup.remove(directionalLight);
          scene.remove(directionalLight);
          directionalLight = null;
          return;
        }
        directionalLight = new DirectionalLight(0xea1601, 50);

        directionalLight.position.set(-5, 3, 2);
        terrainGroup.add(directionalLight);
        scene.add(directionalLight);
      });

      const sliderSpan = document.createElement("span");
      sliderSpan.classList.add("slider", "round");
      label.appendChild(switchInput);
      label.appendChild(sliderSpan);

      childDivs.forEach((childProps) => {
        const childDiv = document.createElement("div");
        childDiv.style.backgroundColor = childProps.backgroundColor;
        childDiv.textContent = childProps.textContent;
        childDiv.style.color = childProps.color;
        childDiv.style.textAlign = childProps.textAlign;
        childDiv.style.width = childProps.width;
        childDiv.style.borderRadius = childProps.borderRadius;
        childDiv.style.padding = childProps.padding;
        childDiv.style.fontSize = childProps.fontSize;
        childDiv.style.cursor = "pointer";
        childDiv.style.display = "flex";
        childDiv.style.alignItems = "center";
        childDiv.style.flexDirection = "row-reverse";
        childDiv.style.gap = "10px";
        childDiv.style.boxShadow = "1px 1px 10px 0px #2D476480";

        const childDiv1 = document.createElement("img");
        if (childProps.textContent === t("Show radiation")) {
          childDiv.appendChild(label);
        }

        if (childProps.textContent === t("Assistance")) {
          childDiv1.src = "";
          childDiv.appendChild(childDiv1);
        }

        if (childProps.textContent === t("Save")) {
          childDiv1.src = Lock;
          childDiv.appendChild(childDiv1);
        }

        childDiv.addEventListener("click", () => {
          if (childProps.textContent === t("Save")) {
            handleSaveSolarProductionData();
          }
        });

        container.appendChild(childDiv);
      });

      threejsEl.current.appendChild(container);
    }

    const calculateSolarIrradiation = (object, scene) => {
      // Assuming a simple model where solar panels are on the roof
      // You would need to determine the positions of solar panels in your actual model
      const solarPanels = object.children.filter((child) => child.name === "supported");

      // Get current date and time
      const currentDate = new Date();
      const latitude = 51.5074; // London, UK (replace with your actual location)
      const longitude = -0.1278;

      // Calculate sun position
      const sunPosition = SunCalc.getPosition(currentDate, latitude, longitude);

      // Calculate solar irradiation for each solar panel
      solarPanels.forEach((panel) => {
        // Calculate solar irradiation based on panel orientation and position
        const irradiation = calculateIrradiationForPanel(panel, sunPosition, currentDate);

        // Apply the irradiation color based on intensity
        let irradiationColor;
        if (irradiation > 0) {
          // Higher irradiation, closer to yellow
          irradiationColor = new THREE.Color(1, 1, 0); // Yellow
        } else {
          // Lower or no irradiation, closer to red
          irradiationColor = new THREE.Color(1, 0, 0); // Red
        }

        // Create a new material with the irradiation color
        const material = new THREE.MeshBasicMaterial({
          color: irradiationColor,
        });

        // Create a new mesh with the irradiation material
        const irradiationMesh = new THREE.Mesh(panel, material);

        // Position the irradiation mesh slightly above the original panel
        irradiationMesh.position.copy(panel.position);
        irradiationMesh.position.y += 0.1; // Adjust offset as needed

        // Add the irradiation mesh to the scene (optional for stacking effect)
        scene.add(irradiationMesh);
      });
    };

    // Function to calculate solar irradiation for a single solar panel
    const calculateIrradiationForPanel = (panel, sunPosition, currentDate) => {
      // Placeholder calculation (replace with your actual logic)
      // This assumes a perfect angle between the sun and the panel for maximum irradiation
      const isFacingSun = Math.abs(panel.rotation.y) < Math.PI / 4; // Check if panel faces the sun (within 45 degrees)
      const irradiation = isFacingSun ? 1 : 0; // Maximum irradiation if

      // Error handling (optional, replace with more robust calculations)
      if (!panel.geometry || !panel.material) {
        console.warn("Panel geometry or material not found, skipping irradiation calculation");
        return 0;
      }

      // Placeholder calculation (replace with more accurate calculation)
      // You would need to calculate the actual irradiation based on the panel's orientation
      return irradiation * 5; // Assuming units of kWh/m² and scaling by a factor of 5
    };

    function onProgress() {
      const modeButtonContainer = document.createElement("div");
      modeButtonContainer.className = "left-icons";
      modeButtonContainer.id = "left-icon";
      modeButtonContainer.style.cssText =
        "position: absolute; top: 10px; z-index:999999; transform: translateY(40%); left:10px; background: rgba(45, 71, 100, 0.8); padding: 10px 5px; border-radius: 20px; z-index:99999; display: flex; flex-direction:column; align-items:center; justify-content: center";

      panelIcons.map((ele) => {
        const createModeButton = document.createElement("img");
        createModeButton.src = ele.image;
        createModeButton.alt = ele.key;
        createModeButton.id = ele.key;
        createModeButton.className = ele.key === "planet" ? "activeIcon" : "";

        createModeButton.style.cssText =
          ele.key === "screenshot"
            ? "height: 25px; width: 40px; margin: 5px; cursor: pointer;"
            : "height: 25px; width: 25px; margin: 5px; cursor: pointer;";
        createModeButton.addEventListener("click", (e) =>
          handleClick(ele.key, createModeButton, e),
        );

        createModeButton.addEventListener("mouseover", () => {
          createModeButton.src = ele.hoverImage;
        });

        createModeButton.addEventListener("mouseout", () => {
          const element = createModeButton.className === "activeIcon";
          if (element) {
            createModeButton.src = ele.hoverImage;
            return;
          }
          createModeButton.src = ele.key === "planet" ? Planet : ele.image;
        });

        return modeButtonContainer.appendChild(createModeButton);
      });

      threejsEl.current.appendChild(modeButtonContainer);
      createContainerWithChildDivs();

      const modeButtonContainer2 = document.createElement("div");
      modeButtonContainer2.className = "right-icons";
      modeButtonContainer2.id = "right-icon";

      modeButtonContainer2.style.cssText =
        "position: absolute; top: 10px; right:10px; background:rgba(45, 71, 100, 0.8); padding: 10px 5px; border-radius: 20px; z-index:99999";

      rightPanelIcons.map((ele) => {
        const createModeButton = document.createElement("img");
        createModeButton.src = ele.image;
        createModeButton.alt = ele.key;
        createModeButton.style.cssText =
          "height: 25px; width: 25px; margin: 10px; cursor: pointer;";
        createModeButton.addEventListener("click", (e) =>
          handleClick(ele.key, createModeButton, e),
        );

        return modeButtonContainer2.appendChild(createModeButton);
      });

      threejsEl.current.appendChild(modeButtonContainer2);

      labelRenderer.setSize(renderer.domElement.clientWidth, renderer.domElement.clientHeight);
      labelRenderer.domElement.style.position = "absolute";
      // labelRenderer.domElement.style.top = "0px";
      labelRenderer.domElement.style.pointerEvents = "none";
      // labelRenderer.domElement.style.height = `${renderer.domElement.clientHeight}px`;
      labelRenderer.domElement.className = "measurementLabels";

      threejsEl.current.appendChild(labelRenderer.domElement);
    }
    const vec3 = Vector3;

    /** ** MODEL LOADING ****/

    const modelURLs = [
      "./art/3D_Models/Baumschulenweg  Heidelberg/3D_Model",
      "./art/3D_Models/Sudetenstraße 51, 35581 Wetzlar/3D_Model",
      "./art/3D_Models/Wetzbachstraße 17, 35641 Schöffengrund/Model",
      "./art/3D_Models/Model_024/Model_024",
      "./art/3D_Models/Model_025/Model_025",
      "./art/3D_Models/Model_026/Model_026",
    ];

    const arnd = (a) => a[(random() * a.length) | 0];
    // let path = arnd(modelURLs);
    const path = modelURLs[0]; //

    const loadModel = async (path) => {
      // consol.log(path,'path')
      mapName = path.split("/").slice(-2)[0];
      document.title = "SolarHub:" + mapName;
      terrainGLB = await loadOBJ(path, onProgress);

      // let meshes = []
      terrainGLB.scene.traverse(function (child) {
        if (child.isMesh) {
          // Opt-in to use the BVH accelerated raycast
          child.raycast = acceleratedRaycast;
          const geometry = child.geometry;
          geometry.boundsTree = new MeshBVH(geometry, {
            lazyGeneration: false,
          });
          // meshes.push(child)

          // child.material = new THREE.MeshStandardMaterial();
          child.material = new THREE.MeshStandardMaterial({
            //          child.material = new THREE.MeshBasicMaterial({
            map: child.material.map,
          });
          // child.material = new THREE.MeshStandardMaterial({map:child.material.map});
          // child.material = new THREE.MeshStandardMaterial();
        }
      });

      fitCameraToCenteredObject(camera, terrainGLB);
      calculateSolarIrradiation(terrainGLB.scene, scene);
      threejsEl.current.addEventListener("mousedown", onClick);
    };

    const terrainGroup = new THREE.Group();
    scene.add(terrainGroup);

    await loadModel(path);

    /** ** END MODEL LOADING ****/

    // io.addFrameTask((time,dt)=>{
    //    flow.updateAll(time);
    // }
    // )

    // ---Pointer hover over the map flow
    const v = new vec3();
    const vx = new vec3();
    const vy = new vec3();
    const vz = new vec3();
    let validHover;

    let dragStart;
    let dragEnd;
    let inDrag;
    const p0 = new vec3();
    const p1 = new vec3();
    const p2 = new vec3();
    const ah = new THREE.Mesh(
      new THREE.CylinderGeometry(1, 1, 0.1, 16),
      new THREE.MeshBasicMaterial({
        opacity: 0.5,
        transparent: true,
        color: "teal",
        blending: THREE.AdditiveBlending,
        depthTest: false,
      }),
    );
    ah.geometry.rotateX(PI * 0.5);
    scene.add(ah);

    function* hoverFlow() {
      while (1) {
        const hits = io.raycaster.intersectObjects(terrainGroup.children, true);
        validHover = false;
        if (hits.length) {
          ah.visible = true;
          ah.position.copy(hits[0].point);
          ah.lookAt(v.copy(hits[0].normal).multiplyScalar(-1).add(ah.position));
          validHover = true;
        } else ah.visible = false;
        yield 0;
      }
    }

    const toJSON = () => {
      const out = [];
      panelGrids.forEach((pg) => {
        const q = pg.quaternion;
        out.push({
          size: pg.size.clone(),
          position: pg.position.clone(),
          selectionSize: pg.selectionSize.clone(),
          quaternion: {
            x: q.x,
            y: q.y,
            z: q.z,
            w: q.w,
          },
        });
      });
      return out;
    };
    const toJSONMeasurement = () => {
      const polygons = editor.document.polygons.map((pg) => {
        const points = pg.map((ele) => ele.position.clone());
        return points.filter((p, index) => index % 2 === 0);
      });
      return polygons;
    };

    const toJSONCabeling = () => {
      let cabelings = editor.document.cables.map((c) => c.map((ele) => ele.position.clone()));
      cabelings =
        cabelingMode === "auto"
          ? panelGrids.map((panelGrid) => {
              return panelGrid.children
                .slice(1)
                .filter((ele) => ele.name)
                .map((ele) => ele.position.clone());
            })
          : cabelings;

      return { cabelings, mode: cabelingMode };
    };

    const fromJSON = (json) => {
      let out;
      try {
        out = JSON.parse(json);
      } catch {
        out = undefined;
      }
      if (!out) {
        return alert("Failed to load JSON.");
      }

      panelGrids.forEach((pg) => pg.disposeOccupancy());
      panelGrids.forEach(
        (pg) => pg.selectionMesh && pg.selectionMesh.parent.remove(pg.selectionMesh),
      );
      panelGrids.length = 0;

      // return
      out.forEach((pg) => {
        const grid = new PanelGrid({
          scene,
          modules,
          deleteObject,
          plusObject,
          dimentions,
        });
        grid.size.copy(pg.size);
        grid.position.copy(pg.position);
        grid.quaternion.copy(pg.quaternion);
        grid.selectionSize.copy(pg.selectionSize);
        grid.selectionMesh.scale.copy(grid.selectionSize);
        grid.selectionMesh.visible = true;
        grid.recomputeOccupancy({
          targets: terrainGLB.scene.children,
        });
        grid.redrawOccupancy(scene);

        scene.add(grid);
        panelGrids.push(grid);
      });
    };

    const fromJSONCabeling = (json) => {
      let out;
      try {
        out = JSON.parse(json);
      } catch {
        out = undefined;
      }
      if (!out) {
        return alert("Failed to load JSON.");
      }

      // out.cabelings.forEach((pg) => {
      //   // Create an array to store the matched points
      //   let matchedPoints = [];

      //   pg.forEach((pointData) => {
      //     const { x, y, z } = pointData;
      //     // Check every child of the panelGrid for a matching point
      //     panelGrids.forEach((panelGrid) => {
      //       panelGrid.children.forEach((child) => {
      //         const childPosition = child.position;

      //         if (
      //           childPosition.x === x &&
      //           childPosition.y === y &&
      //           childPosition.z === z &&
      //           child.name === "supported"
      //         ) {
      //           matchedPoints.push(child);
      //         }
      //       });
      //     });
      //   });

      // if (matchedPoints.length === 0) {
      //   console.warn(`No matching points found for pg:`, pg);
      //   return;
      // }
      // });
      // showHelpersBetweenPoints([], [], [], false);

      if (out.mode === "auto") {
        panelGrids.map((ele) => {
          editor.document.cables.push(ele.children.filter((e) => e.name === "supported"));
        });
      } else {
        // cables.push(matchedPoints);
      }
      setTimeout(() => {
        editor.document.cables.forEach((cable) => {
          const previousPoints = [];
          for (let i = 0; i < cable.length; i++) {
            const pgModule = cable[i];
            const pgBox = new THREE.Box3().setFromObject(pgModule);
            const pgCenter = new THREE.Vector3();
            pgBox.getCenter(pgCenter);

            const point = addPointAtPosition(pgCenter, "manual", false);
            scene.add(point);
            previousPoints.push(point);

            if (i > 0) {
              const line = addLineBetweenPoints(
                previousPoints[i - 1],
                previousPoints[i],
                "manual",
                false,
              );
              lines.push(line);
              scene.add(line);
            }
          }
          allPoints.push(previousPoints);
        });
      }, 500);
    };

    const fromJSONMeasurement = (json) => {
      let out;
      try {
        out = JSON.parse(json);
      } catch {
        out = undefined;
      }
      if (!out) {
        return alert("Failed to load JSON.");
      }

      out.forEach((pg) => {
        // Create original points
        pg.forEach((pointData) => {
          const adjustedPoint = new THREE.Vector3(pointData.x, pointData.y, pointData.z);
          createSpherePointWithStroke(adjustedPoint, 0.2, 0xffffff);
        });

        const pointsWithMidpoints = addPointBetweenConsecutivePoints(measurementPoints);

        editor.document.polygons.push(pointsWithMidpoints);
        editor.document.oldPolygons.push(pointsWithMidpoints);

        clearPoints();
        updateLineGeometry();
        calculateAndShowPopup(editor.document.polygons);
      });
    };

    const defaultLoadingPanelPlacements = () => {
      fromJSON(project.threeDObjDetails.panelPlacement);
    };

    const moveAmount = new THREE.Vector3(1, 0, 0);
    const moveupAmount = new THREE.Vector3(0, 1, 0);
    const rotationAmount = THREE.MathUtils.degToRad(0.05);

    const lastMoveTime = 0;
    const moveInterval = 100;

    const handleStopMeasurement = () => {
      drawingLine = false;
      scene.remove(measurementLabels[lineId]);
      if (line) {
        scene.remove(line);
      }

      if (cylinder) {
        scene.remove(cylinder);
      }

      // if (measurementPoints.legnth > 0) {
      const pointsWithMidpoints = addPointBetweenConsecutivePoints(measurementPoints);
      editor.document.polygons.push(pointsWithMidpoints);
      editor.document.oldPolygons.push(pointsWithMidpoints);

      handleModeChange("Edit mode");
      createModeRadio.checked = false;
      editModeRadio.checked = true;

      clearPoints();
      updateLineGeometry();
      calculateAndShowPopup(editor.document.polygons);
      // }
    };

    const handleStopCabeling = () => {
      if (startPoint) {
        handlePanelClick();
      }
    };

    const handleStopActions = (event) => {
      switch (event.key) {
        case "Escape":
          handleStopMeasurement();
          handleStopCabeling();
          threejsEl.current.removeEventListener("mousedown", onMouseDown);
          threejsEl.current.removeEventListener("mousemove", handleRotateMouseMove);
          // handleStopPanelPlacement();
          break;
      }
    };
    document.addEventListener("keydown", handleStopActions);

    const movePanelGrid = (direction) => {
      if (!selectedPanelGrid) return;
      const move = new THREE.Vector3();
      switch (direction) {
        case "top":
          move.y += moveupAmount.y;
          break;
        case "bottom":
          move.y -= moveupAmount.y;
          break;
        case "left":
          move.x -= moveAmount.x;
          break;
        case "right":
          move.x += moveAmount.x;
          break;
      }
      move.applyQuaternion(selectedPanelGrid.quaternion);
      selectedPanelGrid.position.add(move);

      selectedPanelGrid.disposeOccupancy();

      selectedPanelGrid.recomputeOccupancy({
        targets: terrainGLB.scene.children,
      });
      selectedPanelGrid.redrawOccupancy(scene);
    };

    const rotatePanelGrid = (direction) => {
      if (!selectedPanelGrid) return;

      const pivot = new THREE.Object3D();
      pivot.position.copy(selectedPanelGrid.selectionSize).multiplyScalar(0.5);
      selectedPanelGrid.add(pivot);
      pivot.updateMatrixWorld(true);
      selectedPanelGrid.parent.attach(pivot);
      pivot.attach(selectedPanelGrid);
      switch (direction) {
        case "left":
          pivot.rotation.z += rotationAmount; // Rotate counterclockwise
          break;
        case "right":
          pivot.rotation.z -= rotationAmount; // Rotate clockwise
          break;
      }
      pivot.updateMatrix(true);
      pivot.updateMatrixWorld(true);
      pivot.parent.attach(selectedPanelGrid);
      pivot.parent.remove(pivot);

      // selectedPanelGrid.position.add(center);

      selectedPanelGrid.disposeOccupancy();

      selectedPanelGrid.recomputeOccupancy({
        targets: terrainGLB.scene.children,
      });
      selectedPanelGrid.redrawOccupancy(scene);
    };

    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey) {
        if (e.code === "KeyS") {
          localStorage["panelLayout_" + mapName] = JSON.stringify(toJSON());
          localStorage["measurement" + mapName] = JSON.stringify(toJSONMeasurement());
          localStorage["cabeling" + mapName] = JSON.stringify(toJSONCabeling());
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.code === "KeyO") {
          fromJSON(localStorage["panelLayout_" + mapName]);
          fromJSONMeasurement(localStorage["measurement" + mapName]);
          setTimeout(() => {
            fromJSONCabeling(localStorage["cabeling" + mapName]);
          }, 2000);
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.code === "KeyY") {
          editor.document.redo();
        }
        if (e.code === "KeyZ") {
          if (e.shiftKey) editor.document.redo();
          else editor.document.undo();
        }
      } else {
        if (e.code === "Delete") {
          if (panelGrid) {
            editor.document.doCommand({
              name: "deleteGrid",
              grid: panelGrid,
              do: function () {
                this.gridIndex = panelGrids.indexOf(this.grid);
                if (this.gridIndex < 0) console.error("Panel grid not in list!!");
                this.grid.parent.remove(this.grid);
                panelGrids.splice(this.gridIndex, 1);
                panelGrid = null;
              },
              undo: function () {
                panelGrids.splice(this.gridIndex, 0, this.grid);
                scene.add(this.grid);
                panelGrid = this.grid;
              },
            });
          }
        }
      }
    });

    // ---Pointerdrag drag a new PanelGrid
    let panelGrid;
    let editingPanelGrid;
    let boxFrame;

    let isDragging = false;
    const mouse = new THREE.Vector2();

    // Helper function to convert screen coordinates to normalized device coordinates
    const getMousePosition = (event) => {
      const rect = threejsEl.current.getBoundingClientRect();

      const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      mouse.x = x;
      mouse.y = y;
    };

    // Event listener for mouse down
    const onMouseDown = (event) => {
      getMousePosition(event);

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(
        panelGrids.map((pg) => pg.selectionMesh),
        false,
      );

      controls.enabled = false;
      controls.enableRotate = false;

      if (intersects.length) {
        const intersectedGrid = intersects[0].object.parent;
        selectedPanelGrid = intersectedGrid;
        isDragging = true;

        // Calculate the drag offset
        const intersectPoint = intersects[0].point;
        // dragOffset.copy(intersectedGrid.position).sub(intersectPoint);
        dragStart = intersectPoint.clone();
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      }
    };

    // Event listener for mouse move
    const onMouseMove = (event) => {
      if (!isDragging) return;

      getMousePosition(event);

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects([terrainGLB.scene], true);

      if (intersects.length) {
        const intersectPoint = intersects[0].point.clone();
        const dragCurrent = intersectPoint.clone();
        if (!selectedPanelGrid.userData.beforeDragPosition)
          selectedPanelGrid.userData.beforeDragPosition = selectedPanelGrid.position.clone();
        const dragDelta = dragCurrent.sub(dragStart);
        selectedPanelGrid.position
          .copy(selectedPanelGrid.userData.beforeDragPosition)
          .add(dragDelta);
        // selectedPanelGrid.position.copy(intersectPoint).add({...dragOffset});

        selectedPanelGrid.disposeOccupancy();
        selectedPanelGrid.recomputeOccupancy({
          targets: terrainGLB.scene.children,
        });

        selectedPanelGrid.redrawOccupancy(scene);
      }
    };

    // Event listener for mouse up
    const onMouseUp = () => {
      isDragging = false;
      controls.enabled = true;
      controls.enableRotate = true;
      solarProductionData();

      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    const transparentMaterial = new THREE.MeshBasicMaterial({
      opacity: 0.1,
      transparent: true,
    });
    function setParentInvisibleExceptTarget(parent, targetNames) {
      parent.traverse((child) => {
        if (child.isMesh) {
          if (targetNames.includes(child.name)) {
            // Restore original material for target children
            if (child.userData.originalMaterial) {
              child.material = child.userData.originalMaterial;
            }
            child.visible = true;
          } else {
            // Set transparent material for non-target children

            child.userData.originalMaterial = child.material;
            child.material = transparentMaterial;
            child.visible = false; // Optionally set to false to hide it completely
          }
        }
      });
      parent.isNotVisible = true;
    }

    function restoreParentMaterial(parent) {
      parent.traverse((child) => {
        if (child.isMesh && child.userData.originalMaterial) {
          child.material = child.userData.originalMaterial;
          delete child.userData.originalMaterial;
          child.visible = true;
        }
      });
      parent.isNotVisible = false;
    }

    const resetSelection = () => {
      controls.enabled = true;
      inDrag = false;
      if (boxFrame) {
        boxFrame?.parent?.remove(boxFrame);
        boxFrame = null;
      }
      editingPanelGrid = null;
    };

    function removePanelGrid(panelGrid) {
      // Find the index of the panelGrid to remove
      const index = panelGrids.indexOf(panelGrid);
      if (index > -1) {
        // Remove the panelGrid from the array

        const panelsToRemove = panelGrid.children.slice(1); // Assuming panelGrid has a panels property that is an array of panels

        // Loop through the cables array and remove any cables associated with the panels to be removed
        for (let i = 0; i < editor.document.cables.length; i++) {
          for (let j = 0; j < editor.document.cables[i].length; j++) {
            const cable = editor.document.cables[i][j];

            if (panelsToRemove.find((ele) => ele.id === cable.id)) {
              editor.document.cables[i].splice(j, 1);
              j--; // Adjust index after removal
            }
          }
        }

        editor.document.cables = editor.document.cables.filter((subArray) => subArray.length > 0);
        showHelpersBetweenPoints([], [], [], false);
        resetSelection();
        panelGrids.splice(index, 1);
        solarProductionData();
      }
    }

    const handleWheel = (event) => {
      if (!selectedPanelGrid) return;
      controls.enabled = false;
      event.preventDefault();
      if (event.deltaY < 0) {
        rotatePanelGrid("left"); // Scroll up to rotate counterclockwise
      } else {
        rotatePanelGrid("right"); // Scroll down to rotate clockwise
      }
    };

    function createIcon(id, src) {
      const icon = document.createElement("img");
      icon.id = id;
      icon.src = src;
      icon.style.height = "25px"; // Add some margin to space the icons vertically
      icon.style.width = "25px"; // Add some margin to space the icons vertically
      return icon;
    }

    let moveGrid;
    let rotateGrid;

    function removeAllActionContainers() {
      // Find all div elements with the class name "action-container"
      const actionContainers = threejsEl.current.querySelectorAll("div.action-container");
      // Iterate through the NodeList and remove each element from the DOM
      actionContainers.forEach((container) => {
        container.parentNode.removeChild(container);
      });
    }
    let isRotating = false;
    let lastMouseX = 0;

    const handleRotateMouseMove = (event) => {
      if (!isRotating || !selectedPanelGrid) return;
      controls.enabled = false;
      controls.enableRotate = false;
      event.preventDefault();
      if (event.clientX > lastMouseX) {
        rotatePanelGrid("right"); // Mouse move to the right to rotate clockwise
      } else {
        rotatePanelGrid("left"); // Mouse move to the left to rotate counterclockwise
      }
      lastMouseX = event.clientX;
    };

    const handleRotateMouseUp = () => {
      controls.enableRotate = true;
      isRotating = false;
      lastMouseX = 0;
      threejsEl.current.removeEventListener("mousemove", handleRotateMouseMove);
      threejsEl.current.removeEventListener("mouseup", handleRotateMouseUp);
      solarProductionData();

      // handleRotationStop();
    };
    function addIconsToPanelGrid(panelGrid) {
      removeAllActionContainers();
      const deleteContainer = document.createElement("div");
      deleteContainer.style.position = "absolute";
      deleteContainer.style.display = "none";
      deleteContainer.className = "action-container";
      deleteContainer.style.zIndex = 1000;
      deleteContainer.style.backgroundColor = "rgba(45, 71, 100, 0.8)";
      deleteContainer.style.padding = "5px";
      deleteContainer.style.borderRadius = "5px";
      deleteContainer.style.boxShadow = "2px 2px 5px rgba(0, 0, 0, 0.2)";
      controls.enabled = true;
      controls.enableRotate = true;

      threejsEl.current.removeEventListener("mousedown", onMouseDown);
      threejsEl.current.removeEventListener("mousemove", handleRotateMouseMove);
      const position = panelGrid.position.clone().project(camera);
      const x = (position.x * 0.5 + 0.5) * threejsEl.current.clientWidth;
      const y = (-position.y * 0.5 + 0.5) * threejsEl.current.clientHeight;
      deleteContainer.style.right = `${threejsEl.current.clientWidth - x}px`;
      deleteContainer.style.top = `${y}px`;
      deleteContainer.style.display = "flex";
      deleteContainer.style.justifyContent = "center";
      deleteContainer.style.alignItems = "center";

      deleteContainer.innerHTML = "";

      const deleteButton1 = createIcon("deleteButton", DeleteIcon);
      const rotateButton = createIcon("rotateButton", RotateIcon);
      const moveButton = createIcon("moveButton", MoveIcon);
      threejsEl.current.appendChild(deleteContainer);

      deleteButton1.addEventListener("click", () => {
        const grid = panelGrids.find((e) => e.id === panelGrid.id);
        removePanelGrid(panelGrid);
        grid?.parent?.remove(grid);
        deleteContainer.style.display = "none";
        // solargisData.splice(index, 1);
        // solarProductionData();
      });

      moveButton.addEventListener("click", () => {
        threejsEl.current.removeEventListener("mousemove", handleRotateMouseMove);
        threejsEl.current.addEventListener("mousedown", onMouseDown);
      });

      rotateButton.addEventListener("click", () => {
        threejsEl.current.addEventListener("mousedown", (event) => {
          isRotating = true;
          lastMouseX = event.clientX;
          threejsEl.current.addEventListener("mousemove", handleRotateMouseMove);
          threejsEl.current.addEventListener("mouseup", handleRotateMouseUp);
        });
      });

      deleteContainer.appendChild(deleteButton1);
      deleteContainer.appendChild(rotateButton);
      deleteContainer.appendChild(moveButton);

      controls.enabled = false;
    }

    const abortAction = () => {
      if (editingPanelGrid) {
        editingPanelGrid.disposeOccupancy();
        editingPanelGrid.parent.remove(editingPanelGrid);
        editingPanelGrid.selectionMesh && editingPanelGrid.remove(editingPanelGrid.selectionMesh);
      }
      resetSelection();
    };

    function* dragFlow() {
      inDrag = true;
      dragStart = ah.position.clone();
      dragEnd = dragStart.clone();
      let escapePressed = false;

      const onKeyDown = (event) => {
        if (event.key === "Escape") {
          escapePressed = true;
          abortAction();
          document.removeEventListener("keydown", onKeyDown);
        }
      };

      document.addEventListener("keydown", onKeyDown);

      const createPanelGrid = () => {
        const pg = new PanelGrid({
          scene,
          modules,
          deleteObject,
          plusObject,
          dimentions,
        });
        pg.up = pg.up.clone();
        ah.matrix.decompose(pg.position, pg.quaternion, pg.scale);
        pg.updateMatrix();
        scene.add(pg);
        return pg;
      };

      const firstClick = dragStart.clone();

      while (io.buttons && !escapePressed) {
        dragStart.copy(ah.position);

        if (firstClick.distanceTo(dragStart) > 1) {
          abortAction();
          document.removeEventListener("keydown", onKeyDown);
          return;
        }

        p0.copy(ah.position);
        yield 0;
      }

      if (escapePressed) return;

      const hits = io.raycaster.intersectObjects(
        panelGrids.map((pg) => pg.selectionMesh),
        false,
      );

      if (hits.length) {
        const clickedGrid = panelGrids.find((pg) => pg.selectionMesh.id === hits[0].object.id);

        selectedPanelGrid = clickedGrid;
        const childHits = io.raycaster.intersectObjects(clickedGrid.children.slice(1), true);

        if (childHits.length) {
          if (panelGrid !== clickedGrid) {
            panelGrid = clickedGrid;
            updateBoxFrame(panelGrid);
            addIconsToPanelGrid(panelGrid);
          } else {
            updateBoxFrame(panelGrid);
            addIconsToPanelGrid(panelGrid);

            if (childHits[0].object.name === "deleteIcon") {
              const module = childHits[0].object;

              const iconVisible = module.parent.children.find((el) => el.name === "add-icon");

              setParentInvisibleExceptTarget(module.parent, ["add-icon"]);

              iconVisible.visible = true;
              module.visible = false;

              controls.enabled = true;
              inDrag = false;
              document.removeEventListener("keydown", onKeyDown);
              solarProductionData();
              return;
            }

            if (childHits[0].object.name === "add-icon") {
              const module = childHits[0].object;

              restoreParentMaterial(module.parent);
              const iconVisible = module.parent.children.find((el) => el.name === "deleteIcon");
              iconVisible.visible = true;
              module.parent.visible = true;
              module.visible = false;

              controls.enabled = true;
              inDrag = false;
              document.removeEventListener("keydown", onKeyDown);
              solarProductionData();
              return;
            }
          }
          controls.enabled = true;
          inDrag = false;
          document.removeEventListener("keydown", onKeyDown);
          return;
        }
      }

      const restoreIconHits = io.raycaster.intersectObjects(
        scene.children.filter((child) => child.name === "restoreIcon"),
        true,
      );

      function makeAllChildrenVisible(element) {
        element.visible = true;
        if (element.children && element.children.length > 0) {
          element.children.forEach((child) => {
            makeAllChildrenVisible(child);
          });
        }
      }

      if (restoreIconHits.length) {
        const iconMesh = restoreIconHits[0].object;
        const clickedGrid = iconMesh.userData.clickedGrid;

        clickedGrid.children.forEach((child) => {
          makeAllChildrenVisible(child);
        });

        scene.remove(iconMesh);
        delete clickedGrid.restoreIcon;

        controls.enabled = true;
        inDrag = false;
        document.removeEventListener("keydown", onKeyDown);
        return;
      }

      controls.enabled = false;

      let xlen;
      const ep = 0.0001;

      while (!io.buttons && !escapePressed) {
        p1.copy(ah.position);
        xlen = p0.distanceTo(p1);

        if (!editingPanelGrid) editingPanelGrid = createPanelGrid();
        editingPanelGrid.selectionMesh.scale.set(0.1, 0.1, 0.1);
        if (xlen > ep) {
          editingPanelGrid.position.copy(p0);
          editingPanelGrid.lookAt(v.copy(p1));
          editingPanelGrid.selectionMesh.scale.z = xlen;
          vx.copy(p1).sub(p0).normalize();
        }
        updateBoxFrame(editingPanelGrid);
        yield 0;
      }

      if (escapePressed) return;

      if (io.buttons !== 1) {
        abortAction();
        document.removeEventListener("keydown", onKeyDown);
        return;
      }

      while (io.buttons && !escapePressed) {
        yield 0;
      }

      while (!io.buttons && !escapePressed) {
        p2.copy(ah.position);

        let ylen = p1.distanceTo(p2);

        if (ylen > ep && xlen > ep) {
          vy.copy(p2).sub(p1);

          const d = vy.dot(vx);
          v.copy(vx).multiplyScalar(d);
          vy.sub(v);

          ylen = vy.length();
          vy.normalize();
          vz.crossVectors(vy, vx).normalize();

          if (editingPanelGrid) {
            editingPanelGrid.up.copy(vy).multiplyScalar(-1);

            const faceUpDot = vz.dot(camera.up);

            if (faceUpDot < 0) {
              vz.multiplyScalar(-1);
            }

            editingPanelGrid.lookAt(v.copy(editingPanelGrid.position).add(vz));

            editingPanelGrid.size.set(xlen, -ylen, 0.1);

            const selectionThickness = 0.2;

            const sscl = editingPanelGrid.selectionMesh.scale.set(
              faceUpDot < 0 ? -xlen : xlen,
              -ylen,
              selectionThickness,
            );
            editingPanelGrid.selectionSize.copy(editingPanelGrid.selectionMesh.scale);
            updateBoxFrame(editingPanelGrid);

            editingPanelGrid.disposeOccupancy();

            editingPanelGrid.recomputeOccupancy({
              targets: terrainGroup.children,
            });
            editingPanelGrid.redrawOccupancy(scene);
          }
        }
        yield 0;
      }

      if (escapePressed) return;

      if (io.buttons !== 1) {
        abortAction();
        document.removeEventListener("keydown", onKeyDown);
        return;
      } else {
        if (editingPanelGrid) {
          editingPanelGrid.selectionMesh.material = PanelGrid.deselectedStateMaterial;

          function* animateMeshPopIn({ object, delay, duration, easing }) {
            object.traverse(
              (e) => e.isMesh && e.originalMaterial && (e.material = e.originalMaterial),
            );
            if (!object.placedPosition) object.placedPosition = object.position.clone();
            if (!object.placedRotation) object.placedRotation = object.rotation.clone();
            if (!object.placedScale) object.placedScale = object.scale.clone();
            flow.tweenVector3({
              object,
              start: object.placedScale.clone().multiplyScalar(0.5),
              end: object.placedScale,
              value: "scale",
              delay,
              duration,
              easing,
            });
            flow.tweenVector3({
              object,
              value: "position",
              start: object.parent.worldToLocal(
                object.parent.localToWorld(object.position.clone()).add(new Vector3(0, 8, 0)),
              ),
              end: object.placedPosition,
              delay,
              duration,
              easing,
            });
            flow.tweenVector3({
              object,
              value: "rotation",
              start: new Vector3().copy(object.rotation).add(new Vector3(0, PI * 2, 0)),
              end: object.placedRotation,
              delay,
              duration,
              easing,
            });
            yield;
          }

          editor.document.doCommand({
            name: "addGrid",
            grid: editingPanelGrid,
            do: function () {
              scene.add(this.grid);

              for (let i = 1; i < this.grid.children.length; i++) {
                flow.start(animateMeshPopIn, {
                  object: this.grid.children[i],
                  delay: (500 * i) / this.grid.children.length,
                  duration: 500,
                  easing: flow.OutCubic,
                });
              }
              panelGrids.push(this.grid);
              solarProductionData();
            },
            undo: function () {
              const grid = panelGrids.pop();
              if (grid?.parent) {
                grid.parent.remove(grid);
              }
            },
          });
        }
      }

      while (io.buttons) {
        yield 0;
      }

      resetSelection();
      document.removeEventListener("keydown", onKeyDown);
    }

    const saveFile = async (dataUrl) => {
      const formData = new FormData();
      formData.append("type", "quote");

      try {
        const res = await fetch(dataUrl);
        const blob = await res.blob();

        const file = new File([blob], "model.png", { type: "image/png" });
        formData.append("file_upload", file);

        const response = await axios.post(BackendApis.uploadImages.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        await ApiAxiosClient.put(update3dQuote.url, {
          image: response?.data?.data[0]?.url,
          id: project.quote.id,
        });
        dispatch(
          ProjectActions.updateProject({
            ...project,
            quote: {
              ...project.quote,
              profiles: {
                ...project.quote.profiles,
                green: {
                  ...project.quote.profiles.green,
                  imgURLs: [response?.data?.data[0]?.url, ...project.quote.profiles.green.imgURLs],
                },
              },
            },
          }),
        );
        toast.success("Your Screenshot Saved Successfully!!");
      } catch (error) {
        console.log(error);
      }
    };

    function handleScreenShot() {
      let imgData;

      try {
        const strMime = "image/png";

        // Ensure the scene is rendered
        renderer.render(scene, camera);

        // Wait for the next animation frame

        const imgData = renderer.domElement.toDataURL(strMime);

        if (imgData) {
          void saveFile(imgData);
        } else {
          console.log("Failed to capture image data.");
        }

        // void saveFile(imgData);
      } catch (e) {
        console.log(e);
      }
    }

    const DEG2RAD = PI / 180;
    const RAD2DEG = 180 / Math.PI;

    const getPanelData = () => {
      const pd = [];
      panelGrids.forEach((pg) => {
        const panelData = pg.occupancy.results[0];
        console.log(panelData?.indicator, "panelData.indicator");
        if (panelData?.indicator) {
          const o = panelData.indicator;
          let tilt = RAD2DEG * o.parent.rotation.x;
          let azimuth = RAD2DEG * o.parent.rotation.y;

          // Normalize tilt to [0, 90]
          tilt = Math.abs(tilt % 360);
          if (tilt > 90) {
            tilt = 90; // Clamp tilt to a maximum of 90 degrees
          }

          // Normalize azimuth to [-180, 180]
          azimuth = ((azimuth + 180) % 360) - 180;

          console.log(pg.children, "3758+++");
          pd.push({
            type: panelData.object.name,
            moduleQuantity: pg.children
              .slice(1)
              .filter((ele) => ele.name === "supported" && !ele?.isNotVisible)?.length,
            orientation: o.parent.rotation.clone(),
            tilt,
            azimuth,
          });
        }
      });
      return pd;
    };
    threejsEl?.current?.addEventListener("contextmenu", function (event) {
      if (!panelLayoutActive) return;
      event.preventDefault();
    });

    threejsEl?.current?.addEventListener("pointerup", (e) => {
      if (!panelLayoutActive) return;
      if (e.target !== renderer.domElement) return;
      e.preventDefault();
      e.stopPropagation();
    });

    threejsEl?.current?.addEventListener("pointerdown", (e) => {
      if (!panelLayoutActive) return;
      if (e.target !== renderer.domElement) return;

      if (e.buttons !== 1) validHover = false;

      if (validHover) {
        if (!inDrag) flow.start(dragFlow);
        return true;
      }
    });

    const minZoom = 10;
    flow.start(function* () {
      // Make the controls target stay in front of camera for better feel...
      while (1) {
        if (controls.target.distanceTo(camera.position) < minZoom)
          controls.target.sub(camera.position).setLength(minZoom).add(camera.position);
        yield 0;
      }
    });

    const createDeleteIcon = async () => {
      const tex = await new THREE.TextureLoader().loadAsync(deleteIcon);
      tex.colorSpace = "srgb";
      return (deleteObject = new THREE.Sprite(
        new THREE.SpriteMaterial({
          map: tex,
        }),
      ));
    };

    deleteObject = await createDeleteIcon();

    const createPlusIcon = async () => {
      const tex = await new THREE.TextureLoader().loadAsync(plusIcon);
      tex.colorSpace = "srgb";
      return (plusObject = new THREE.Sprite(
        new THREE.SpriteMaterial({
          map: tex,
        }),
      ));
    };

    plusObject = await createPlusIcon();
    class AutoCablingCommand {
      constructor(panelGrids, calculatePanelCenter, addPointAtPosition, addLineBetweenPoints) {
        this.panelGrids = panelGrids;
        this.calculatePanelCenter = calculatePanelCenter;
        this.addPointAtPosition = addPointAtPosition;
        this.addLineBetweenPoints = addLineBetweenPoints;
        this.points = [];
        this.lines = [];
      }

      do(document) {
        // Clear any existing points and lines
        this.points = [];
        this.lines = [];

        this.panelGrids.forEach((panelGrid) => {
          editor.document.cables.push(panelGrid?.children?.slice(1)?.filter((ele) => ele.name));
        });

        showHelpersBetweenPoints([], [], [], true);
      }

      undo(document) {
        const linesToRemove = this.lines.flat();
        linesToRemove.forEach((line) => scene.remove(line));
        document.lines = document.lines.filter((line) => !linesToRemove.includes(line));

        const pointsToRemove = this.points.flat();
        pointsToRemove.forEach((point) => scene.remove(point));
        document.points = document.points.filter((point) => !pointsToRemove.includes(point));
      }

      redo(document) {
        // Add all points at once
        this.points.flat().forEach((point) => {
          scene.add(point);
          document.points.push(point);
        });

        // Add all lines at once
        this.lines.flat().forEach((line) => {
          scene.add(line);
          document.lines.push(line);
        });
      }
    }

    class Command {
      do(document) {}
      undo(document) {}
      redo(document) {}
    }

    class AddPointCommand extends Command {
      constructor(point) {
        super();
        this.point = point;
      }

      do(document) {
        document.manualPoints.push(this.point);
        scene.add(this.point);
      }

      undo(document) {
        scene.remove(this.point);
        document.manualPoints.pop();
      }

      redo(document) {
        this.do(document);
      }
    }

    class AddLineCommand extends Command {
      constructor(line) {
        super();
        this.line = line;
      }

      do(document) {
        document.manualLines.push(this.line);
        scene.add(this.line);
      }

      undo(document) {
        scene.remove(this.line);
        document.manualLines.pop();
      }

      redo(document) {
        this.do(document);
      }
    }

    class ClearPointsAndLinesCommand extends Command {
      constructor(points, lines) {
        super();
        this.points = points.slice();
        this.lines = lines.slice();
      }

      do(document) {
        this.points.forEach((point) => scene.remove(point));
        document.points = [];

        this.lines.forEach((line) => scene.remove(line));
        document.lines = [];

        // Remove relevant commands from history
        document.history = document.history.filter(
          (cmd) =>
            !(
              cmd instanceof AddPointCommand ||
              cmd instanceof AddLineCommand ||
              cmd instanceof AddMeasurementPointCommand ||
              cmd instanceof AddMeasurementLineCommand ||
              cmd instanceof AddMeasurementLineCommand ||
              cmd instanceof ClearPointsAndLinesCommand
            ),
        );

        // Reset cursor accordingly
        document.cursor = document.history.length;
      }

      undo(document) {
        // Restore points and lines
        this.points.forEach((point) => {
          scene.add(point);
          document.points.push(point);
        });

        this.lines.forEach((line) => {
          scene.add(line);
          document.lines.push(line);
        });

        // Optionally, you can restore the removed commands if you want undo to also restore the history
      }

      redo(document) {
        this.do(document);
      }
    }

    class ClearMeasurementPointsAndLinesCommand extends Command {
      constructor(points, lines, callTrue) {
        super();
        // this.points = points.slice();
        this.lines = lines.slice();
        this.callTrue = callTrue;
      }

      do(document) {
        // this.points.forEach((point) => scene.remove(point));
        // document.points = [];

        this.lines.forEach((line) => scene.remove(line));
        document.lines = [];

        // Remove relevant commands from history
        document.history = document.history.filter(
          (cmd) =>
            !(
              (this.callTrue && cmd instanceof AddMeasurementPointCommand) ||
              cmd instanceof AddMeasurementLineCommand ||
              cmd instanceof ClearMeasurementPointsAndLinesCommand
            ),
        );

        document.cursor = document.history.length;
      }

      undo(document) {
        // Restore points and lines
        this.points.forEach((point) => {
          scene.add(point);
          document.points.push(point);
        });

        this.lines.forEach((line) => {
          scene.add(line);
          document.lines.push(line);
        });

        // Optionally, you can restore the removed commands if you want undo to also restore the history
      }

      redo(document) {
        this.do(document);
      }
    }

    class AddMeasurementPointCommand extends Command {
      constructor(point) {
        super();
        this.point = point;
      }

      do(document) {
        scene.add(this.point);
        document.measurementPoints.push(this.point);
      }

      undo(document) {
        scene.remove(this.point);
        document.measurementPoints.pop();

        if (document.polygons[document.polygons.length - 1]) {
          document.polygons[document.polygons.length - 1].pop();
          if (document.polygons[document.polygons.length - 1].length === 0) {
            document.polygons.splice(document.polygons.length - 1, 1);
          }
        }
      }

      redo(document) {
        this.do(document);
      }
    }

    class AddMeasurementLineCommand extends Command {
      constructor(line) {
        super();
        this.line = line;
      }

      do(document) {
        document.measurementLines.push(this.line);
        scene.add(this.line);
      }

      undo(document) {
        document.measurementLines.pop();
        scene.remove(this.line);
      }

      redo(document) {
        this.do(document);
      }
    }

    class Document {
      constructor() {
        this.history = [];
        this.cursor = 0;
        this.points = [];
        this.lines = [];
        this.manualPoints = [];
        this.manualLines = [];
        this.measurementPoints = [];
        this.measurementLines = [];
        this.polygons = [];
        this.oldPolygons = [];
        this.cables = [];
      }

      doCommand(cmd) {
        this.history[this.cursor++] = cmd;
        cmd.do(this);
        this.history.length = Math.min(this.history.length, this.cursor);
      }

      redo() {
        if (this.cursor < this.history.length) {
          const cmd = this.history[this.cursor];
          if (cmd && typeof cmd.redo === "function") {
            cmd.redo(this);
          } else if (cmd) {
            cmd.do(this);
          }
          this.cursor++;
        }
      }

      undo() {
        if (this.cursor > 0) {
          this.cursor--;
          const cmd = this.history[this.cursor];
          cmd.undo(this);
        }
      }
    }

    // Modified Editor class
    class Editor {
      constructor() {
        this.document = new Document();
      }

      addPoint(point) {
        const cmd = new AddPointCommand(point);
        this.document.doCommand(cmd);
      }

      addLine(line) {
        const cmd = new AddLineCommand(line);
        this.document.doCommand(cmd);
      }

      addMeasurementPoint(point) {
        const cmd = new AddMeasurementPointCommand(point);
        this.document.doCommand(cmd);
      }

      addMeasurementLine(line) {
        const cmd = new AddMeasurementLineCommand(line);
        this.document.doCommand(cmd);
      }

      clearMeasurementPointsandLines(callTrue) {
        const cmd = new ClearMeasurementPointsAndLinesCommand(
          this.document.measurementPoints,
          this.document.measurementLines,
          callTrue,
        );
        this.document.doCommand(cmd);
      }

      clearPointsAndLines() {
        const cmd = new ClearPointsAndLinesCommand(
          this.document.manualPoints,
          this.document.manualLines,
        );
        this.document.doCommand(cmd);
      }

      autoCabling(panelGrids, calculatePanelCenter) {
        const cmd = new AutoCablingCommand(
          panelGrids,
          calculatePanelCenter,
          addPointAtPosition,
          addLineBetweenPoints,
          this.cables,
        );
        this.document.doCommand(cmd);
      }

      undo() {
        this.document.undo();
      }

      redo() {
        this.document.redo();
      }
    }
    const editor = new Editor();
    flow.start(hoverFlow);

    started = true;

    // ENV MAP PROBE TEST scene.add( new THREE.Mesh(new THREE.SphereGeometry(100),new THREE.MeshStandardMaterial({metalness:1,roughness:0}) ) )
    if (destroyed) {
      destroy();
    }
  };

  start();

  return { renderer, scene, camera, controls, io, flow, THREE, destroy };
}
