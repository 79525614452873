import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch, useNaivgateAbsolute } from "src/hooks";

import SolarHubSmall from "src/assets/application_step_1/SolarHubSmall.svg";
// @ts-expect-error
import starterExplanation from "src/assets/videos/starterExplanation.mp4";
import { AddressSelector, PopupSkeleton } from "src/shared/components";
import { PrimaryButton } from "src/shared/StyledComponents";
import { transformForURL } from "src/utils";

import { MiddlePath } from "./components";
import { useStyles } from "./styles";
import cssClasses from "./welcomeWithNoAddress.module.css";

import { PopupActions } from "src/redux";
import { POPUP_KEYS } from "src/redux/popups";
import { MapboxAddress } from "src/redux/project";
import { PopupSelectors, UserSelectors } from "src/redux/selectors";

export interface WelcomeNoAddressProps {
  onAddressSelect?: (addr: MapboxAddress) => void;
}

const WelcomePopupNoAddress: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNaivgateAbsolute();
  const containerRef: React.Ref<HTMLInputElement | null> = useRef(null);
  const installerDetails = useSelector(UserSelectors?.getInstaller);

  const props = useSelector(PopupSelectors.getWelcomeAddressPopup);
  const {
    data: { onAddressSelect },
  } = props;

  const classes = useCallback(useStyles, [])();

  const handleClose = useCallback(() => {
    dispatch(PopupActions.closePopup(POPUP_KEYS.welcome_address));
  }, [dispatch]);

  return (
    <PopupSkeleton handleClose={() => handleClose()} open={true} containerRef={containerRef}>
      <div className={cssClasses.dialogContent}>
        <div>
          <MiddlePath />
        </div>
        <AddressSelector
          showPartnerCityPopup={!onAddressSelect}
          onAddressSelect={(mapboxAddress: MapboxAddress) => {
            if (onAddressSelect) {
              onAddressSelect(mapboxAddress);
              handleClose();
              return;
            }
            const completeAddr = mapboxAddress.complete;
            const transformed = transformForURL(completeAddr);
            navigate(`application/default-${transformed}`);
            handleClose();
          }}
        />
        <div className={cssClasses.highlighterLine} />
        <div className={classes.areCustomerHappy}>
          <p className={classes.quesstion}>{t("Noch unsicher?")}</p>
          <p className={classes.answer}>
            {installerDetails?.videoTitle ||
              t(
                "Moment mal, wer ist SolarHub und was tun wir denn? Gerne stellen wir uns kurz vor:",
              )}
          </p>
        </div>
        {!installerDetails?.deactivateVideo && (
          <video
            src={installerDetails?.welcomeVideo || starterExplanation}
            width="400"
            height="290"
            controls
            style={{ marginBlock: "40px" }}
          />
        )}
        <div className={classes.stepContainer}>
          <div className={classes.firstPart}>
            <img src={SolarHubSmall} alt="SolarHubSmall" style={{ marginBlock: 0 }} />
          </div>
          <div className={classes.secoundPart}>
            <p className={classes.secoundPart_heading}>
              {installerDetails?.addressSelectorStep1Title || t("FIRST_STEP_HEADING")}
            </p>
            <p className={classes.secoundPart_title}>
              {installerDetails?.addressSelectorStep1Description || t("FIRST_STEP_DESCRIPTION")}
            </p>
          </div>
        </div>
        <div className={classes.stepContainer}>
          <div className={classes.firstPart}>
            <img src={SolarHubSmall} alt="SolarHubSmall" />
          </div>
          <div className={classes.secoundPart}>
            <p className={classes.secoundPart_heading}>
              {installerDetails?.addressSelectorStep2Title || t("SECOUND_STEP_HEADING")}
            </p>
            <p className={classes.secoundPart_title}>
              {installerDetails?.addressSelectorStep2Description || t("SECOUND_STEP_DESCRIPTION")}
            </p>
          </div>
        </div>
        <div className={classes.stepContainer}>
          <div className={classes.firstPart}>
            <img src={SolarHubSmall} alt="SolarHubSmall" />
          </div>
          <div className={classes.secoundPart}>
            <p className={classes.secoundPart_heading}>
              {installerDetails?.addressSelectorStep3Title || t("THIRD_STEP_HEADING")}
            </p>
            <p className={classes.secoundPart_title}>
              {installerDetails?.addressSelectorStep3Description || t("THIRD_STEP_DESCRIPTION")}
            </p>
          </div>
        </div>
        <PrimaryButton
          onClick={() => {
            containerRef?.current?.scroll({ top: 0, behavior: "smooth" });
          }}
          style={{
            width: "200px",
          }}
        >
          {`${t("ADDRESS INPUT")}`}
        </PrimaryButton>
      </div>
    </PopupSkeleton>
  );
};

export default WelcomePopupNoAddress;
