import React, { useMemo } from "react";
import { Image, Text, View } from "@react-pdf/renderer";

import PageHeading from "../../components/pageHeading/pageHeading";
import commanStyles from "../../comanStyles/commanStyles";
import HomeEnergy from "../../components/HomeEnergy/HomeEnergy";

import styles from "./SystemInfoStyles";
import Tree from "../../../assets/logos/Tree.png";
import Footer from "../../components/Footer/Footer";
import { getAverageCapacity } from "src/helpers";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { useSelector } from "react-redux";
import { useNumberFormat } from "src/hooks";

const SystemInfo = ({ monthlyChartScreenShot, supplyChartScreenShot, electrictySupplyChart }) => {
  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const solarPanelProduct = useSelector(ProjectSelectors.getSolarPanel);
  const {formatNumber} = useNumberFormat();
  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  let systemCapacity = (capacity * quantity) / 1000;
  if (solarPanelProduct?.item) {
    systemCapacity = (Number(solarPanelProduct?.item.capacity) * quantity) / 1000;
  }

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <PageHeading heading="Dein neues Energiesystem" />
      <View style={styles.mainContainer}>
        <Text style={commanStyles.stactic_heading}>Übersicht - deine Energiebilanz</Text>
        <HomeEnergy />
        <View style={{ marginTop: 50 }}>
          <View style={styles.monthlyChartScreenShot}>
            <Text style={{ ...commanStyles.static_text, paddingBottom: 15 }}>
              Monatliche Energiebilanz
            </Text>
            {monthlyChartScreenShot && <Image src={monthlyChartScreenShot} />}
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginTop: 20,
              paddingHorizontal: 20,
              justifyContent: "space-around",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <View style={styles.monthlyChartScreenShot2}>
              {supplyChartScreenShot && <Image src={supplyChartScreenShot} />}
              {/* <Text style={{ ...commanStyles.static_text,  textAlign: "center" }}>Eigenverbrauch</Text> */}
            </View>
            <View style={styles.monthlyChartScreenShot2}>
              {electrictySupplyChart && <Image src={electrictySupplyChart} />}
              {/* <Text style={{ ...commanStyles.static_text,  textAlign: "center" }}>Autarkie</Text> */}
            </View>
          </View>
          <View style={styles.flexRowTop}>
            <Image src={Tree} style={styles.img} />
            <Text style={styles.black_description_small}>
              Deine jährliche CO₂ - Ersparnis von {formatNumber(systemCapacity * 330)} kg CO₂e
            </Text>
          </View>
        </View>
      </View>
      <Footer pageNumber={3} />
    </View>
  );
};

export default SystemInfo;
