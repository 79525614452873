import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Text, View } from "@react-pdf/renderer";

import PageHeading from "../../components/pageHeading/pageHeading";
import Footer from "../../components/Footer/Footer";
import {
  getEnergy,
  getFinancialDetails,
  getInverter,
  getSolarPanel,
} from "../../../redux/project/selectors";
import { formatNumberByLang, useNumberFormat } from "../../../hooks/useNumberFormat";

import commanStyles from "../../comanStyles/commanStyles";
import styles from "./SystemRequirementsStyles";
import { ProductSelectors, ProjectSelectors } from "src/redux/selectors";
import { getAverageCapacity, getAverageMaxCapacity } from "src/helpers";
import { getNumber } from "src/utils";

const SystemRequirements = () => {
  const filteredSolarPanels = useSelector(ProductSelectors.getFilteredSolarPanels);
  const filteredBatteries = useSelector(ProductSelectors.getFilteredBatteries);
  const { formatNumber } = useNumberFormat();
  const { quantity } = useSelector(ProjectSelectors.getSolarPanel);
  const solarPanel = useSelector(getSolarPanel);
  const inverter = useSelector(getInverter);
  const energy = useSelector(getEnergy);
  const {
    costs: {
      componentCosts: { solarPanelCost },
      totalProjectCost,
    },
  } = useSelector(getFinancialDetails);

  const capacity = useMemo(() => {
    return getAverageCapacity(filteredSolarPanels);
  }, [filteredSolarPanels]);

  const maxCapacity = useMemo(() => {
    return getAverageMaxCapacity(filteredBatteries);
  }, [filteredBatteries]);

  const systemCapacity = (capacity * quantity) / 1000;

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <PageHeading />
      <View style={styles.mainContainer}>
        <View style={styles.backGround}>
          <View style={styles.sameContainer}>
            <Text style={styles.sameContainerText}>Bezeichnung</Text>
            <Text style={styles.sameContainerText}>Menge</Text>
            <Text style={styles.sameContainerText}>Preis, €</Text>
          </View>
        </View>
        <View style={styles.backGround2}>
          <View style={styles.sameContainer}>
            <Text style={styles.sameContainerText}>Solaranlage</Text>
          </View>
        </View>
        <View style={styles.backGround3}>
          <View style={styles.towText}>
            <Text style={styles.sameContainerTextSmall}>Photovoltaikmodule</Text>
            <Text style={styles.sameContainerTextSmallLight}>{solarPanel?.item?.name}</Text>
          </View>
          <Text style={styles.sameContainerText}>{solarPanel?.quantity} St.</Text>
        </View>
        <View style={commanStyles.divider} />
        <View style={styles.backGround3}>
          <View style={styles.towText}>
            <Text style={styles.sameContainerTextSmall}>Wechselrichter</Text>
            <Text style={styles.sameContainerTextSmallLight}>{inverter?.item?.name}</Text>
          </View>
          <Text style={styles.sameContainerText}>{inverter?.quantity} St.</Text>
        </View>
        <View style={commanStyles.divider} />
        <View style={styles.backGround3}>
          <Text style={styles.sameContainerTextGrey}>Unterkonstruktion</Text>
          <Text style={styles.sameContainerText}>1 St.</Text>
        </View>
        <View style={commanStyles.divider} />
        <View style={styles.backGround3}>
          <Text style={styles.sameContainerTextGrey}>Wechselrichter Zubehör</Text>
          <Text style={styles.sameContainerText}>1 psch.</Text>
        </View>
        <View style={commanStyles.divider} />
        <View style={styles.backGround3}>
          <Text style={styles.sameContainerTextGrey}>Gerüststellung</Text>
          <Text style={styles.sameContainerText}>1 psch.</Text>
        </View>
        <View style={commanStyles.divider} />
        <View style={styles.backGround3}>
          <View style={styles.towText}>
            <Text style={styles.sameContainerTextGrey}>Montage der</Text>
            <Text style={styles.sameContainerTextGrey}>Photovoltaikanlage</Text>
          </View>
          <Text style={styles.sameContainerText}>
            {/* {formatNumberByLang(Math.round(energy.solarPanelProduction).toFixed(0))} kWp */}
            {formatNumber(systemCapacity < 1 ? systemCapacity : Math.round(systemCapacity))} kWp
          </Text>
        </View>
        <View style={styles.backGround2}>
          <View style={styles.sameContainer}>
            <Text style={styles.sameContainerText}>Batteriespeichersystem</Text>
            <Text style={styles.sameContainerText}>
              {formatNumber(getNumber(maxCapacity) / 1000, { roundTill: 1 })} kWh
            </Text>
          </View>
        </View>
        {/* <View style={styles.backGround4}>
          <View style={styles.sameContainer}>
            <Text style={styles.sameContainerText}>Solaranlage</Text>
            <Text style={styles.sameContainerText}>
              {formatNumberByLang(solarPanelCost.toFixed(0))}€
            </Text>
          </View>
        </View> */}
        {/* <View style={styles.backGround2}>
                <View style={styles.sameContainer}>
                    <Text style={styles.sameContainerText}>MwSt. 0%</Text>
                    <Text style={styles.sameContainerText}>0€</Text>
                </View>
            </View> */}
        <View style={styles.backGround4}>
          <View style={styles.sameContainer}>
            <Text style={styles.sameContainerText}>Gesamtkosten brutto</Text>
            <Text style={styles.sameContainerText}>
              {formatNumberByLang(totalProjectCost.toFixed(0))}€
            </Text>
          </View>
        </View>
      </View>
      <Footer pageNumber={7} />
    </View>
  );
};

export default SystemRequirements;
