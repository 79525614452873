import axios from "axios";
import React, { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { useRef, useState } from "react";
import { blobToURL } from "./utils/Utils";
import PagingControl from "./components/PagingControl";
import { AddSigDialog } from "./components/AddSigDialog";
import { BigButton } from "./components/BigButton";
import DraggableSignature from "./components/DraggableSignature";
import DraggableText from "./components/DraggableText";
import dayjs from "dayjs";
import { useSaveMedia, useUpdateInstallerAPI } from "src/hooks/apis";
import { BackendApis } from "src/api";
import { useAppSelector } from "src/hooks";
import { getProject } from "src/redux/project/selectors";
import { sendResultMailToInstaller } from "src/api/backend-endpoints";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function downloadURI(uri: string, name: string) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const dataURLtoFile = (dataUrl: string, filename: string): File => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  if (!mime) {
    throw new Error("MIME type is not available");
  }

  return new File([u8arr], filename, { type: mime });
};

function PdfSignature() {
  const styles = useMemo(
    () => ({
      container: {
        maxWidth: 900,
        margin: "0 auto",
      },
      sigBlock: {
        display: "inline-block",
        border: "1px solid #000",
      },
      documentBlock: {
        maxWidth: 800,
        margin: "20px auto",
        marginTop: 8,
        border: "1px solid #999",
      },
      controls: {
        maxWidth: 800,
        margin: "0 auto",
        marginTop: 8,
      },
    }),
    [],
  );
  const { url, iid, matchedId } = React.useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    return {
      url: queryParams.get("url") || "",
      iid: queryParams.get("iid") || "",
      matchedId: queryParams.get("matchedId") || "",
    };
  }, []);
  const { fetchData: updateInstallerApi, response: updateResponse } = useUpdateInstallerAPI();
  const { error, fetchData, loading, response } = useSaveMedia();

  const [pdf, setPdf] = React.useState<any>(null);
  const [signatureURL, setSignatureURL] = useState(null);
  const [position, setPosition] = useState<any>(null);
  const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
  const [textInputVisible, setTextInputVisible] = useState<Boolean | String>(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState<any>(null);
  const [signed, setSigned] = useState(false);
  const [s3ResponseUrl, setS3ResponseUrl] = useState("");
  const documentRef = useRef<any>(null);

  const fetchPdf = useCallback(async () => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });
      const blob = new Blob([response.data]);
      const URL = await blobToURL(blob);
      setPdf(URL);
    } catch (error) {
      toast.error("Failed to fetch PDF");
    }
  }, [url]);

  const sendResultMail = async (img: string) => {
    const result = await axios.post(sendResultMailToInstaller.url, {
      iid: iid,
      img,
    });
  };

  const handleSubmit = useCallback(async () => {
    try {
      const file = dataURLtoFile(pdf, "signature.pdf");

      const formData = new FormData();
      formData.append("type", "installers");
      formData.append("file_upload", file);

      fetchData({
        ...BackendApis.uploadImages,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });
    } catch (err) {
      toast.error("Failed to submit the PDF");
    }
  }, [pdf, updateInstallerApi]);

  useEffect(() => {
    if (response) {
      setS3ResponseUrl(response?.data?.data?.[0]?.url as string);
    }
  }, [response]);

  useEffect(() => {
    if (s3ResponseUrl) {
      void updateInstallerApi({
        ...BackendApis.updateInstaller({
          id: matchedId,
        }),
        data: { customerSignature: s3ResponseUrl, offerAccepted: true },
      });
    }
  }, [s3ResponseUrl]);

  useEffect(() => {
    if (updateResponse) {
      const sendMail = async () => {
        await sendResultMail(s3ResponseUrl as string);
        downloadURI(pdf, "Signed offer.pdf");
        toast.success("Offer accepted successfully");
      };
      sendMail();
    }
  }, [updateResponse]);

  useEffect(() => {
    if (!url) {
      toast.error("No URL provided");
      return;
    }
    if (!iid) {
      toast.error("No installer ID provided");
      return;
    }
    if (!matchedId) {
      toast.error("No matched ID provided");
      return;
    }
    fetchPdf();
  }, [url]);

  return (
    <div>
      <div style={styles.container}>
        {signatureDialogVisible ? (
          <AddSigDialog
            onClose={() => setSignatureDialogVisible(false)}
            onConfirm={(url: any) => {
              setSignatureURL(url);
              setSignatureDialogVisible(false);
            }}
          />
        ) : null}

        {pdf ? (
          <div>
            <div style={styles.controls}>
              {!signatureURL ? (
                <BigButton
                  marginRight={8}
                  title={"Add signature"}
                  onClick={() => setSignatureDialogVisible(true)}
                />
              ) : null}

              <BigButton
                marginRight={8}
                title={"Reset"}
                onClick={() => {
                  setTextInputVisible(false);
                  setSignatureDialogVisible(false);
                  setSignatureURL(null);
                  setPdf(null);
                  setTotalPages(0);
                  setPageNum(0);
                  setPageDetails(null);
                  fetchPdf();
                }}
              />
              {pdf ? (
                <BigButton
                  marginRight={8}
                  inverted={true}
                  title={"Submit"}
                  disabled={!signed}
                  onClick={async () => {
                    handleSubmit();
                  }}
                />
              ) : null}
            </div>
            <div ref={documentRef} style={styles.documentBlock}>
              {textInputVisible ? (
                <DraggableText
                  initialText={textInputVisible === "date" ? dayjs().format("M/d/YYYY") : null}
                  onCancel={() => setTextInputVisible(false)}
                  onEnd={setPosition}
                  onSet={async (text: string) => {
                    const { originalHeight, originalWidth } = pageDetails;
                    const scale = originalWidth / documentRef.current.clientWidth;

                    const y =
                      documentRef.current.clientHeight -
                      (position.y + 12 * scale - position.offsetY - documentRef.current.offsetTop);
                    const x = position.x - 166 - position.offsetX - documentRef.current.offsetLeft;

                    // new XY in relation to actual document size
                    const newY = (y * originalHeight) / documentRef.current.clientHeight;
                    const newX = (x * originalWidth) / documentRef.current.clientWidth;

                    const pdfDoc = await PDFDocument.load(pdf);

                    const pages = pdfDoc.getPages();
                    const firstPage = pages[pageNum];

                    firstPage.drawText(text, {
                      x: newX,
                      y: newY,
                      size: 20 * scale,
                    });

                    const pdfBytes = await pdfDoc.save();
                    const blob = new Blob([new Uint8Array(pdfBytes)]);

                    const URL = await blobToURL(blob);
                    setPdf(URL);
                    setPosition(null);
                    setTextInputVisible(false);
                  }}
                />
              ) : null}
              {signatureURL ? (
                <DraggableSignature
                  url={signatureURL}
                  onCancel={() => {
                    setSignatureURL(null);
                  }}
                  onSet={async () => {
                    const { originalHeight, originalWidth } = pageDetails;
                    const scale = originalWidth / documentRef.current.clientWidth;

                    const y =
                      documentRef.current.clientHeight -
                      (position.y - position.offsetY + 64 - documentRef.current.offsetTop);
                    const x = position.x - 160 - position.offsetX - documentRef.current.offsetLeft;

                    // new XY in relation to actual document size
                    const newY = (y * originalHeight) / documentRef.current.clientHeight;
                    const newX = (x * originalWidth) / documentRef.current.clientWidth;

                    const pdfDoc = await PDFDocument.load(pdf);

                    const pages = pdfDoc.getPages();
                    const firstPage = pages[pageNum];

                    const pngImage = await pdfDoc.embedPng(signatureURL);
                    const pngDims = pngImage.scale(scale * 0.3);

                    firstPage.drawImage(pngImage, {
                      x: newX,
                      y: newY,
                      width: pngDims.width,
                      height: pngDims.height,
                    });

                    // if (autoDate) {
                    //   firstPage.drawText(`Signed ${dayjs().format("M/d/YYYY HH:mm:ss ZZ")}`, {
                    //     x: newX,
                    //     y: newY - 10,
                    //     size: 14 * scale,
                    //     color: rgb(0.074, 0.545, 0.262),
                    //   });
                    // }

                    const pdfBytes = await pdfDoc.save();
                    const blob = new Blob([new Uint8Array(pdfBytes)]);

                    const URL = await blobToURL(blob);
                    setPdf(URL);
                    setPosition(null);
                    setSignatureURL(null);
                    setSigned(true);
                  }}
                  onEnd={setPosition}
                />
              ) : null}
              {pdf && (
                <Document
                  file={pdf}
                  onLoadSuccess={(data) => {
                    setTotalPages(data.numPages);
                  }}
                >
                  <Page
                    pageNumber={pageNum + 1}
                    width={800}
                    height={1200}
                    onLoadSuccess={(data) => {
                      setPageDetails(data);
                    }}
                  />
                </Document>
              )}
            </div>
            <PagingControl pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default PdfSignature;
