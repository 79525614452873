import { RentingData } from "src/api";
import { InstallerDB, LastLogDB, UserFlagDBKeys, WebinarDB } from "src/db-types";
import { IRefCode, Icredit } from "src/db-types/user";

export type JoinedWebinar = Record<
  string,
  {
    isOffline: boolean;
  }
>;

export interface MainUserState {
  id: string;
  role: string;

  // taken during login
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  address: string;

  // derived
  displayName: string;
  photoURL: string;

  flag: { [key in UserFlagDBKeys]: boolean };
  lastLog: LastLogDB;
  webinars: {
    lastJoinedId: string;
    registeredWebinars: WebinarDB[];
  };
  joinedWebinars: JoinedWebinar;
  refCode: IRefCode[];
  credits: Icredit[];
  hubSpotContactId?: string;
}

export type LanguagesShortCodes = "de" | "en" | "es" | "fr";

export const enum UserAuthStatus {
  "loggedIn" = "logged-in",
  "loggedOut" = "logged-out",
  "initial" = "initial",
}

export interface UserState {
  installer: InstallerDB | null;
  user: MainUserState | null;
  isAuthStateLoading: boolean;
  language: LanguagesShortCodes;
  authStatus: UserAuthStatus;
  rantingData: RentingData | null;
  simplify?: boolean;
}
